import React from 'react';
import * as translations from './translations';
import animatedLoadingOoredoops from '../../assets/animated-loading-ooredoops.gif'
import { OperatorFactory } from './OperatorFactory';
import defaultFavicon from '../../assets/ffstars/favicon_ffstars_v2.png';
import defaultLongLogo from '../../assets/ffstars/long.png';
import defaultLogo from '../../assets/ffstars/favicon_ffstars_v1.png';
import defaultLoading from '../../assets/ffstars/ffstars-Bulgaria-icon_512x512.png';
import splLogo from '../../assets/ffstars/logo_spl.png';
import fieldWithBannersSP from '../../assets/images/fieldWithBanners-SP.svg';
import backgroundSP from '../../assets/images/backgroundSP.png';

export const getDomain = () => {
    const url = window.location.hostname;
    const domain = url.split('.')[0];
    return domain;
}

export const getDomainOperator = () => {
    const domain = getDomain();
    let operator;

    switch (domain) {
        case 'du-game':
            operator = getDUUAE();
            break;
        case 'om-game':
            operator = getOmantelOman();
            break;
        case 'oo-game':
            operator = getOoredooOman();
            break;
        case 'ooredoops-game':
            operator = getOoredooPs();
            break;
        case 'as-game':
            operator = getAsiaCell();
            break;
        case 'zi-game':
            operator = getZainIraq();
            break;
        case 'zainkw-game':
            operator = getZainKuwait();
            break;
        case 'zs-game':
            operator = getZainKSA();
            break;
        case 'sk-game':
            operator = getSTCKuwait();
            break;
        case 'et-game':
            operator = getEthiotel();
            break;
        case 'sp':
            operator = getSportLocker();
            break;
        case 'orange':
            operator = getOrange();
            break;
        case 'dev':
            operator = getOrange();
            break;
        case 'localhost':
            operator = getOrange();
            break;
        default:
            operator = getDefault();
            break;
    }
    return operator;
}

const getDUUAE = () => {
    return new OperatorFactory()
        .withLogin('du')
        .withWinnersURL('DU_UAE')
        .build();
}

const getOmantelOman = () => {
    return new OperatorFactory()
        .withLogin('om')
        .withWinnersURL('OMANTEL_OMAN')
        .build();
}

const getOoredooOman = () => {
    return new OperatorFactory()
        .withLogin('oo')
        .withWinnersURL('OOREDOO_OMAN')
        .build();
}

const getOoredooPs = () => {
    return new OperatorFactory()
        .withTitle('OredooPs ffeleven')
        .withLogin('ooredoops')
        .withLogo('./logo-ooredoops.png')
        .withLoading(animatedLoadingOoredoops)
        .withWinnersURL('OOREDOO_PALESTINE')
        .withGetStarted(<GetOoredooPsGetStarted />)
        .build();
}

const getAsiaCell = () => {
    return new OperatorFactory()
        .withTitle('Asiacell ffeleven')
        .withLogin('as')
        .withWinnersURL('ASIACELL_IRAQ')
        .withGetStarted(<GetAsiacellGetStarted />)
        .build();
}

const getZainIraq = () => {
    return new OperatorFactory()
        .withLanguage('ar')
        .withLogin('zi')
        .withWinnersURL('ZAIN_IRAQ')
        .withGetStarted(<GetZainIQGetStarted />)
        .withRules(<GetArabicRules />)
        .build();
}

const getZainKuwait = () => {
    return new OperatorFactory()
        .withTitle('Zain Kuwait ffeleven')
        .withFooter('./footer_logo_zain.png')
        .withFavicon('./logo-zain.png')
        .withTracking(true)
        .withLogin('zainkw')
        .withLogo('./logo-zain.png')
        .withLoading('logo-zain.png')
        .withWinnersURL('ZAIN_KUWAIT')
        .withGetStarted(<GetZainKWGetStarted />)
        .withUnsubscribe(true)
        .withSubscription(<div><GetEnglishSubscription /><GetArabicSubscription /></div>)
        .withRewards(<div><GetEnglishRewards /><GetArabicRewards /></div>)
        .build();
}

const getZainKSA = () => {
    return new OperatorFactory()
        .withLogin('zs')
        .withWinnersURL('THREEANET_KSA')
        .build();
}

const getSTCKuwait = () => {
    return new OperatorFactory()
        .withLogin('sk')
        .withWinners(false)
        .build();
}

const getEthiotel = () => {
    return new OperatorFactory()
        .withLogin('et')
        .withWinners(false)
        .withGetStarted(<GetDefaultGetStarted />)
        .withRules(<GetEnglishRules />)
        .build();
}

const getSportLocker = () => {
    const params = new URLSearchParams(window.location.search);
    return new OperatorFactory()
        .withTitle('Sport Locker ffstars')
        .withFooter(splLogo)
        .withBaseLogin('.ffstars.com')
        .withLogin('sp')
        .withRules(<GetEnglishRules />)
        .withGetStarted(<GetDefaultGetStarted />)
        .withBackground(backgroundSP)
        .withWinners(false)
        .withWhiteLabel(params.get('host'))
        .withTracking(true)
        .withFieldWithBanners(fieldWithBannersSP)
        .build();
}

const getOrange = () => {
    const params = new URLSearchParams(window.location.search);
    return new OperatorFactory()
        .withTitle('Login with orange')
        .withFooter(splLogo)
        .withBaseLogin('.ffstars.com')
        .withLogin('orange')
        .withRules(<GetEnglishRules />)
        .withGetStarted(<GetDefaultGetStarted />)
        .withBackground(backgroundSP)
        .withWinners(false)
        .withWhiteLabel(params.get('host'))
        .withTracking(true)
        .withFieldWithBanners(fieldWithBannersSP)
        .build();
}

const getDefault = () => {
    return new OperatorFactory()
        .withFavicon(defaultFavicon)
        .withFooter(defaultLongLogo)
        .withLogo(defaultLogo)
        .withLoading(defaultLoading)
        .withLogin('demo')
        .withWinners(false)
        .withRules(<GetEnglishRules />)
        .withGetStarted(<GetDefaultGetStarted />)
        .withTracking(false)
        .build();
}

export const GetArabicRules = (props) => {
    return (
        <div dir='rtl' style={{ ...styles.itemText, ...styles.justifyText, paddingBottom: '30px', boxSizing: 'border-box' }}>
            <div><b>حجم الفريق</b></div>
            <br />

            <div>يتكون فريق كل مدير من 15 لاعبًا:</div>
            <br />
            <div>1 حارس مرمى</div>
            <br />
            <div>3-5 مدافعين</div>
            <br />
            <div>2-5 لاعبي وسط</div>
            <br />
            <div>1-3 مهاجمين</div>

            <br />
            <div><b>لاعبو الفريق</b></div>
            <br />

            <div>يمكنك اختيار ما يصل إلى 3 لاعبين من كل فريق كرة قدم في الدوري المحدد.</div>

            <br />
            <div><b>الفريق الذي يبدأ</b></div>
            <br />

            <div>من بين 15 لاعبًا ، يجب اختيار 11 لاعبًا لبدء اللعبة.</div>
            <br />
            <div>يمكن لأي فريق أن يلعب في أي تشكيل ، بشرط أن يتضمن في أي وقت: حارس مرمى واحد على الأقل ، و 3 مدافعين ، ولاعبي خط وسط ، ومهاجم واحد.</div>
            <br />
            <div>سيتم تجميع جميع نقاط اللعبة بواسطة هؤلاء اللاعبين الأحد عشر ، ولكن إذا لم يشارك واحد أو أكثر في مباريات فريقهم ، فسيتم استبدالهم تلقائيًا بأحد اللاعبين البدلاء الأربعة المتبقين.</div>

            <br />
            <div><b>الكابتن وبديل الكابتن</b></div>
            <br />

            <div>يجب اختيار كابتن وبديل الكابتن من البداية. ستضاعف نقاط الكابتن.</div>
            <br />
            <div>إذا لم يلعب الكابتن في اللعبة ، فسيتم استبداله ببديل الكابتن.</div>
            <br />
            <div>إذا لم يلعب بديل الكابتن أيضًا في اللعبة ، فلن يحصل أي من اللاعبين على نقاط مزدوجة.</div>

            <br />
            <div><b>بدائل تلقائية</b></div>
            <br />

            <div>توفر البدائل الأمان في الأحداث غير المتوقعة مثل الإصابات. تحل الغواصات تلقائيًا محل اللاعبين المبتدئين الذين لا يلعبون في اللعبة.</div>
            <br />
            <div>اعتمادًا على ترتيب مقاعد البدلاء الذي يتم تحديده قبل المباراة ، تتم معالجة الاستبدالات التلقائية في نهاية اللعبة على النحو التالي:</div>
            <br />
            <div>• أن يكون في نفس الفريق كإحتياطي</div>
            <br />
            <div>• يلعب في نفس المركز كإحتياطي</div>
            <br />
            <div>• لديه نفس السعر أو أقل من اللعب المستبدل</div>
            <br />
            <div>
                يجب استيفاء جميع الشروط الثلاثة، وإلا فلن تدخل القاعدة حيز التنفيذ وسيظل اللاعب الأول في فريقك. إذا تم استيفاء الشروط، فسيقوم النظام باختيار اللاعب صاحب أعلى سعر والذي يلبي جميع الشروط الثلاثة. لا يوجد حد لعدد مرات تفعيل الاستبدال التلقائي. في حالة تكلف اثنين أو أكثر من المشتركين المحتملين نفس التكلفة ، سيختار النظام لاعبًا وفقًا للمعايير التالية وبالترتيب التالي:
            </div>
            <br />
            <div>• لشكل (اللاعب الحاصل على أكبر عدد من النقاط في المباريات القليلة الماضية).</div>
            <br />
            <div>• مجموع النقاط لهذا الموسم</div>
            <br />
            <div>• ترتيب ابجدي</div>
            <br />
            <div>
                إذا لم يبدأ اللاعب المباراة، لكنه دخل الملعب لاحقًا ، لا يتم الاستبدال التلقائي,
            </div>
            <br /><br />
            <div>
                إذا لم يلعب الكابتن في المباراة وتم استبداله بالتبديل التلقائي ، فسيكون اللاعب البديل قائدك الجديد. الشيء نفسه ينطبق على بديل الكابتن الخاص بك.
            </div>

            <br />
            <div><b>النقاط</b></div>
            <br />

            <div>
                سيتم منح اللاعبين نقاطًا بناءً على أدائهم في المباريات الحقيقية لفرقهم. سيتم منح النقاط للوقت العادي فقط.  لن يتم إضافة أي نقاط للوقت الإضافي وركلات الترجيح. يمكن العثور على تفاصيل النقاط في قسم احتساب النقاط
            </div>

            <br />
            <div><b>شباك نظيفة</b></div>
            <br />

            <div>تُمنح الشباك النظيفة لعدم استقبال أي هدف أثناء تواجد اللاعب على أرض
                الملعب ولعب 60 دقيقة على الأقل. إذا تم استبدال لاعب بعد الدقيقة 60 وتنازل فريقه عن هدف ، فلن تتأثر مكافأة الشباك النظيفة.
            </div>

            <br />
            <div><b>بطاقة حمراء</b></div>
            <br />

            <div>إذا تلقى اللاعب بطاقة حمراء ، فسيظل يُعاقب على الأهداف
                التي استقبلها فريقه في المباراة الحالية. إذا تلقى اللاعب بطاقتين صفراوين وبطاقة حمراء مقابلة ، فستتلقى سالب نقاط فقط للبطاقة
                الحمراء. إذا تلقى اللاعب بطاقة صفراء متبوعة ببطاقة حمراء مباشرة ، فستتلقى سالب نقاط لكل من البطاقة الصفراء والبطاقة الحمراء.
            </div>

            <br />
            <div><b>تمريرة حاسمة</b></div>
            <br />

            <div>• يتم منح التمريرات للاعب من الفريق
                الذي يقوم بالتمريرة الأخيرة قبل تسجيل الهدف. يتم منح التمريرة الحاسمة إذا لم يتم لعب التمريرة من قبل لاعب منافس من شأنه أن
                يغير بشكل كبير مسار الكرة ووضع اللعبة ككل. عند لمس لاعب خصم ، دون التأثير على الموقف ، يتم منح التمريرة المساعدة إلى الممر.
            </div>
            <br />
            <div>• إذا تم صد تسديدة على المرمى من قبل الخصم ، أو
                أنقذها حارس المرمى ، أو ارتطمت بالعارضة وتم تسجيل هدف من الارتداد ، يتم منح تمريرة حاسمة للاعب الذي سدد التسديدة الأخيرة.
            </div>
            <br />
            <div>• إذا أرغمت تسديدة اللاعب الخصم على تسجيل هدف خاص ، فسيتم منح تمريرة حاسمةللاعب الذي سدد الكرة.</div>
            <br />
            <div>• في حالة عقوبة أو ركلة حرة ، فإن اللاعب الذي يفوز بركلة
                الجزاء أو الركلة الحرة يتلقى مساعدة. إذا سجل هدف من قبل اللاعب الذي ربح ركلة جزاء أو ركلة حرة ، فلن يتم منح مساعدة.
            </div>

            <br />
            <div><b>إحصائيات</b></div>
            <br />

            <div>يتم حساب نقاط المساعدة الممنوحة في اللعبة باستخدام إحصائيات إضافية قد تختلف عن
                المواقع الأخرى. على سبيل المثال ، لن تعرض بعض المواقع الأخرى التمريرات الحاسمة عندما يربح اللاعب ركلة جزاء أو ركلة حرة.
            </div>
            <br />
            <div>في حالة التعادل بين فريقين أو أكثر، سيؤخذ في الاعتبار أي فريق تم إنشاؤه مسبقًا.</div>
        </div>
    );
}

export const GetEnglishRules = (props) => {
    return (
        <div style={{ ...styles.itemText, ...styles.justifyText, paddingBottom: '30px', boxSizing: 'border-box' }}>
            <div><b>
                Team size
            </b></div>
            <br />

            <div>
                Each manager's team includes 11 players:
            </div>
            <br />
            <div>
                1 Goalkeeper
            </div>
            <br />
            <div>
                3-5 Defenders
            </div>
            <br />
            <div>
                2-5 Midfielders
            </div>
            <br />
            <div>
                1-3 Strikers
            </div>

            <br />
            <div><b>
                Team Players
            </b></div>
            <br />

            <div>
                You can select up to 3 players from each football team in the selected league.
            </div>

            <br />
            <div><b>
                The starting 11
            </b></div>
            <br />

            <div>
                A team may play in any formation, provided that at any given time it includes: at
                least 1 goalkeeper, 3 defenders, 2 midfielders and 1 forward.
            </div>
            <br />
            <div>
                All points for the game will be accumulated by these 11 players, but if one or more
                do not take part in their team's matches, they can be automatically substituted.
            </div>

            <br />
            <div><b>
                Captain and Vice-Captain
            </b></div>
            <br />

            <div>
                A Captain and a Vice-Captain must be selected from the starting 11. The captain’s
                points will be doubled.
            </div>
            <br />
            <div>
                If the Captain does not play in the game, he will be replaced by the Vice-Captain.
            </div>
            <br />
            <div>
                If the Vice-Captain also does not play in the game, then none of the players will receive double points.
            </div>

            <br />
            <div><b>
                Automatic substitutes
            </b></div>
            <br />

            <div>
                The automatic substitution ensures that if one of your players does not play in the
                match, they will be automatically replaced if possible. In order for another player to
                enter, that player must:
            </div>
            <br />
            <div>
                1. be on the same team as the substitute
            </div>
            <br />
            <div>
                2. play in the same position as the substitute
            </div>
            <br />
            <div>
                3. has the same or lower price as the replaced player
            </div>
            <br />
            <div>
                All three conditions must be met, otherwise the rule will not take effect and the
                initial player will remain in your team. If the conditions are met, the system will
                select the player with the highest price who meets all three conditions. There is no
                limit to the number of times the automatic substitution can take effect. If two or
                more potential subs cost the same, the system will select a player according to the
                following parameters and in the following order:
            </div>
            <br />
            <div>
                - form (the player with the most points in the last few games).
            </div>
            <br />
            <div>
                - total points for the season
            </div>
            <br />
            <div>
                - alphabetical order
            </div>
            <br />
            <div>
                If a player does not start the match, but enters the pitch later, he won't be replaced.
            </div>
            <br /><br />
            <div>
                If your captain does not play the match and is replaced by automatic substitution,
                then the replacement player will be your new captain. The same goes for your vice-captain.
            </div>

            <br />
            <div><b>
                Points
            </b></div>
            <br />

            <div>
                Players will be awarded points based on their performance in their teams'
                real matches. Points will be awarded for regular time only. No points will
                be added for extra time and penalty shooutout. Details of the points can
                be found in the Score calculation section.
            </div>

            <br />
            <div><b>
                Clean sheet
            </b></div>
            <br />

            <div>
                A clean sheet is awarded for not conceding a goal while the player is on the pitch
                and has played at least 60 minutes. If a player is substituted after the 60th minute
                and their team concedes a goal, the Clean Sheet bonus will not be affected.
            </div>

            <br />
            <div><b>
                Red Card
            </b></div>
            <br />

            <div>
                If a player receives a red card, he will continue to be penalized for the goals
                conceded by his team in the current match. If a player receives two yellow cards
                and a corresponding red card, you will receive minus points only for the red card.
                If a player receives a yellow card followed by a direct red card, you will receive
                minus points for both the yellow card and the red card.
            </div>

            <br />
            <div><b>
                Assists
            </b></div>
            <br />

            <div>
                • Assists are awarded to the player from the team who makes the last pass before a
                goal is scored. An assist is awarded if the pass is not played by an opposing player
                that would significantly change the trajectory of the ball and the game situation as
                a whole. When touching an opposing player, without affecting the situation, an
                assist is awarded to the passer.
            </div>
            <br />
            <div>
                • If a shot on goal is blocked by an opponent, saved by the goalkeeper or hits the
                crossbar and a goal is scored from the rebound, an Assist is awarded to the player
                who took the last shot.
            </div>
            <br />
            <div>
                • If a player's shot forces the opponent to score an OWN GOAL, then an Assist is
                awarded to the player who took the shot.
            </div>
            <br />
            <div>
                • In the event of a PENALTY or FREE KICK, the player who wins the penalty or free
                kick receives an Assist. If a goal is scored by the player who won the penalty or free
                kick, NO Assist is awarded.
            </div>

            <br />
            <div><b>
                Statistics
            </b></div>
            <br />

            <div>
                Assist points awarded in the game are calculated using additional statistics that
                may differ from other sites. For example, some other sites will not show Assists
                when a player has won a penalty or free kick.
            </div>
            <br />
            <div>
                In the event of a tie between two or more teams, whichever team was created
                earlier will be taken into account.
            </div>
        </div>
    );
}

// Deprecated
// export const GetDefaultRules = (props) => {
//     return (
//         <div style={{...styles.itemText, ...styles.justifyText, paddingBottom: '30px', boxSizing: 'border-box'}}>
//                         <span>{translations.tKey('str_support_rules_safe_shift_intro')}</span>

//         <span>{translations.tKey('str_support_rules_safe_shift_applications')}</span>

//         <ol>
//             <li>{translations.tKey('str_support_rules_safe_shift_applications_1')}</li>
//             <li>{translations.tKey('str_support_rules_safe_shift_applications_2')}</li>
//             <li>{translations.tKey('str_support_rules_safe_shift_applications_3')}</li>
//         </ol>

//         <span>{translations.tKey('str_support_rules_safe_shift_applications_end')}</span>
//         <span>{translations.tKey('str_support_rules_safe_shift_conditions_intro')}</span>

//         <div style={styles.justifyLeftText}>
//             <br/>
//             <span>{translations.tKey('str_support_rules_safe_shift_conditions_1')}</span><br/>
//             <span>{translations.tKey('str_support_rules_safe_shift_conditions_2')}</span><br/>
//             <span>{translations.tKey('str_support_rules_safe_shift_conditions_3')}</span><br/><br/>
//         </div>

//         <span>{translations.tKey('str_support_rules_safe_shift_conditions_end')}</span><br/><br/>

//         <span>{translations.tKey('str_support_rules_safe_shift_conditions_captain')}</span>

//         </div>
//     )
// }

export const GetOoredooPsGetStarted = (props) => {
    return (
        <div style={{ ...styles.itemText, }}>
            <div dir='rtl'>
                <div>
                    <b>
                        ما هي الفوتبول فانتزي!
                    </b>
                </div>
                <br />

                <div>مرحبًا بك في عالم ألعاب فوتبول فانتزي الأسبوعية! لأولئك منكم الذين ليسوا على دراية بأساسيات الألعاب الفانتزي ، نحن هنا للمساعدة.</div>
                <br />
                <div>
                    عندما تبدأ لعبة فوتبول فانتزي ، فإنك تأخذ دور مدير فريق افتراضي من لاعبي كرة القدم من اختيارك. يمكن أن يتكون هذا الفريق من 11 لاعبًا ، ويمكن أن تستمر اللعبة نفسها لمدة أسبوع أو حتى موسم كامل. توفر كل لعبة للمديرين ميزانية محددة لبناء فريقهم. يحصل كل لاعب كرة قدم على نقاط خيالية بناءً على أدائه في المباريات الحقيقية. سيدخل أفضل 100 مدير يجمع أكبر عدد من النقاط في نهاية الألعاب الأسبوعية في السحب 400 دولار وسيفوزأحدهم بجائزة 400 دولار
                </div>
                <br />
                <div>
                    بعد اختيار فريق والدخول في لعبة معينة ، كل ما عليك فعله هو مشاهدة المباريات والاستمتاع وجمع النقاط. ألعاب كرة القدم الخيالية هي ألعاب مهارية يمكن أن تحقق فيها معرفة كرة القدم جوائز كبيرة. لمزيد من المعلومات حول التسجيل ، راجع قسم تسجيل الدرجات.
                </div>
                <br />
                <div>نأمل أن تنغمس ، مثلنا ، في تجربة لا تصدق تسمى فوتبول فانتازي!</div>
                <br />
                <div>نتطلع إلى سماع أسئلتك وتعليقاتك عبر البريد الإلكتروني:</div>
                <br />
                <a href='mailto:info@ffelelven.com'>info@ffelelven.com</a>
            </div>
        </div>
    );
}

export const GetAsiacellGetStarted = (props) => {
    return (
        <div style={{ ...styles.itemText, }}>
            <div dir='rtl'>
                <div>
                    <b>
                        ما هي الفوتبول فانتزي!
                    </b>
                </div>
                <br />

                <div>
                    مرحبًا بك في عالم ألعاب فوتبول فانتزي الأسبوعية! لأولئك منكم الذين ليسوا على دراية بأساسيات الألعاب الفانتزي ، نحن هنا للمساعدة.
                </div>
                <br />
                <div>
                    عندما تبدأ لعبة فوتبول فانتزي ، فإنك تأخذ دور مدير فريق افتراضي من لاعبي كرة القدم من اختيارك. يمكن أن يتكون هذا الفريق من 11 لاعبًا ، ويمكن أن تستمر اللعبة نفسها لمدة أسبوع أو حتى موسم كامل. توفر كل لعبة للمديرين ميزانية محددة لبناء فريقهم. يحصل كل لاعب كرة قدم على نقاط خيالية بناءً على أدائه في المباريات الحقيقية. سيدخل أفضل 100 مدير يجمع أكبر عدد من النقاط في نهاية الألعاب الأسبوعية في السحب على ثلاثة بطاقات شحن: 100,000 دينار, 50,000 دينار و 25,000 دينار
                </div>
                <br />
                <div>
                    بعد اختيار فريق والدخول في لعبة معينة ، كل ما عليك فعله هو مشاهدة المباريات والاستمتاع وجمع النقاط. ألعاب كرة القدم الخيالية هي ألعاب مهارية يمكن أن تحقق فيها معرفة كرة القدم جوائز كبيرة. لمزيد من المعلومات حول التسجيل ، راجع قسم تسجيل الدرجات.
                </div>
                <br />
                <div>نأمل أن تنغمس ، مثلنا ، في تجربة لا تصدق تسمى فوتبول فانتازي!</div>
                <br />
                <div>نتطلع إلى سماع أسئلتك وتعليقاتك عبر البريد الإلكتروني:</div>
                <br />
                <a href='mailto:info@ffelelven.com'>info@ffelelven.com</a>
            </div>
            <div dir='ltr'>
                <div><b>Fantasy football Eleven: Getting started</b></div>
                <br />

                <div>
                    Welcome to the world of weekly fantasy football games! For those of you who are not yet
                    familiar with the basics of fantasy games, we are here to help.
                </div>
                <br />
                <div>
                    When you start a fantasy football game, you take on the role of manager of a virtual team of
                    football players of your choice. This team can consist of 11 players, and the game itself can last
                    for a game week or even a whole season. Each game provides managers with a specific budget to
                    build their team. Each football player receives fantasy points based on their performance in the
                    real matches. The top 100 managers who collects the most points at the end of the weekly games
                    will enter the draw of 3 top up vouchers 100,000 IQD, 50,000 IQD and 25,000 IQD.
                </div>
                <div>
                    After choosing a team and entering a certain game, all you have to do is watch the matches, have
                    fun and accumulate points. Fantasy football games are games of skill in which football
                    knowledge can bring big prizes. For more information on scoring, see the Scoring section.
                </div>
                <br />
                <div>We look forward to hearing your questions and comments by email to: <a href='mailto:info@ffelelven.com'>info@ffeleven.com</a> .
                    We hope that you, like us, will immerse yourself in the incredible experience called fantasy football!</div>
            </div>
        </div>
    );
}

export const GetZainIQGetStarted = (props) => {
    return (
        <div style={{ ...styles.itemText, }}>
            <div dir='rtl'>
                <div>
                    <b>
                        ما هي الفوتبول فانتزي!
                    </b>
                </div>
                <br />

                <div>
                    مرحبًا بك في عالم ألعاب فوتبول فانتزي الأسبوعية! لأولئك منكم الذين ليسوا على دراية بأساسيات الألعاب الفانتزي ، نحن هنا للمساعدة.
                </div>
                <br />
                <div>
                    عندما تبدأ لعبة فوتبول فانتزي ، فإنك تأخذ دور مدير فريق افتراضي من لاعبي كرة القدم من اختيارك. يمكن أن يتكون هذا الفريق من 11 لاعبًا ، ويمكن أن تستمر اللعبة نفسها لمدة أسبوع أو حتى موسم كامل. توفر كل لعبة للمديرين ميزانية محددة لبناء فريقهم. يحصل كل لاعب كرة قدم على نقاط خيالية بناءً على أدائه في المباريات الحقيقية. سيدخل أفضل 100 مدير يجمع أكبر عدد من النقاط في نهاية الألعاب الأسبوعية في السحب على ثلاثة بطاقات شحن: 100,000 دينار, 50,000 دينار و 25,000 دينار
                </div>
                <br />
                <div>
                    بعد اختيار فريق والدخول في لعبة معينة ، كل ما عليك فعله هو مشاهدة المباريات والاستمتاع وجمع النقاط. ألعاب كرة القدم الخيالية هي ألعاب مهارية يمكن أن تحقق فيها معرفة كرة القدم جوائز كبيرة. لمزيد من المعلومات حول التسجيل ، راجع قسم تسجيل الدرجات.
                </div>
                <br />
                <div>نأمل أن تنغمس ، مثلنا ، في تجربة لا تصدق تسمى فوتبول فانتازي!</div>
                <br />
                <div>نتطلع إلى سماع أسئلتك وتعليقاتك عبر البريد الإلكتروني: </div>
                <br />
                <a href='mailto:info@ffelelven.com'>info@ffelelven.com</a>
            </div>
            <div dir='ltr'>
                <div><b>Fantasy football Eleven: Getting started</b></div>
                <br />

                <div>
                    Welcome to the world of weekly fantasy football games! For those of you who are not yet
                    familiar with the basics of fantasy games, we are here to help.
                </div>
                <br />
                <div>
                    When you start a fantasy football game, you take on the role of manager of a virtual team of
                    football players of your choice. This team can consist of 11 players, and the game itself can last
                    for a game week or even a whole season. Each game provides managers with a specific budget to
                    build their team. Each football player receives fantasy points based on their performance in the
                    real matches. The top 100 managers who collects the most points at the end of the weekly games
                    will enter the draw of 3 top up vouchers 100,000 IQD, 50,000 IQD and 25,000 IQD.
                </div>
                <div>
                    After choosing a team and entering a certain game, all you have to do is watch the matches, have
                    fun and accumulate points. Fantasy football games are games of skill in which football
                    knowledge can bring big prizes. For more information on scoring, see the Scoring section.
                </div>
                <br />
                <div>
                    We look forward to hearing your questions and comments by email to: <a href='mailto:info@ffelelven.com'>info@ffeleven.com</a> .
                    We hope that you, like us, will immerse yourself in the incredible experience called fantasy football!
                </div>
            </div>
        </div>
    );
}

export const GetZainKWGetStarted = (props) => {
    return (
        <div style={{ ...styles.itemText, }}>
            <div dir='rtl'>
                <div>
                    <b>
                        ما هي الفوتبول فانتزي!
                    </b>
                </div>
                <br />

                <div>
                    مرحبًا بك في عالم ألعاب فوتبول فانتزي! لأولئك منكم الذين ليسوا على دراية بأساسيات الألعاب الفانتزي ، نحن هنا للمساعدة.
                </div>
                <br />
                <div>
                    عندما تبدأ لعبة فوتبول فانتزي ، فإنك تأخذ دور مدير فريق افتراضي من لاعبي كرة القدم من اختيارك. يمكن أن يتكون هذا الفريق من 11 لاعبًا. توفر كل لعبة للمديرين ميزانية محددة لبناء فريقهم. يحصل كل لاعب كرة قدم على نقاط بناءً على أدائه في المباريات الحقيقية ستكون الجائزة 1000 دولار لأكثر مشترك سجل نقاط في بطولة الدوري الانجليزي فقط في أسبوع اللعبة المحدد. في حالة حصول أكثر من مشترك واحد على أكبر عدد من النقاط في أسبوع اللعبة المحدد ، فستكون الأولوية لمن اشترك أولاً في الخدمة ولم يفز بالجائزة الأسبوعية سابقًا. سيكون المشترك مؤهلاً للحصول على جوائز فقط في حالة استمرار اشتراكه في الخدمة ويتم تحصيل اشتراكه يوميًا بنجاح في أسبوع اللعبة المحدد.
                </div>
                <br />
                <div>
                    بعد اختيار فريق والدخول في لعبة معينة، كل ما عليك فعله هو مشاهدة المباريات والاستمتاع وجمع النقاط. ألعاب الفوتبول فانتزي هي ألعاب مهارية يمكن أن تحقق فيها معرفة كرة القدم جوائز كبيرة. لمزيد من المعلومات حول التسجيل ، راجع قسم تسجيل الدرجات.
                </div>
                <br />
                <div>
                    نأمل أن تنغمس ، مثلنا ، في تجربة لا تصدق تسمى فوتبول فانتازي!
                </div>
                <br />
                <div>
                    نتطلع إلى سماع أسئلتك وتعليقاتك عبر البريد الإلكتروني:
                </div>
                <br />
                <a href='mailto:info@ffelelven.com'>info@ffelelven.com</a>
            </div>
            <div dir='ltr'>
                <div><b>Fantasy football Eleven: Getting started</b></div>
                <br />
                <div>
                    Welcome to the world of fantasy football games! For those of you who are not yet
                    familiar with the basics of fantasy games, we are here to help.
                </div>
                <br />
                <div>
                    When you start a fantasy football game, you take on the role of manager of a virtual team of
                    football players of your choice. This team can consist of 11 players. Each game provides
                    managers with a specific budget to build their team. Each football player receives fantasy points
                    based on their performance in the real matches. The prize will be 1000$ for the top 1 scorer from
                    the played English premier league tournament only for the played game week. In case more than
                    1 subscriber scored the highest number of points in the given game week, then the priority will
                    be to the one who subscribed first and didn’t win the weekly prize previously. The subscriber
                    will be eligible for prizes only in case he is active and being daily charged successfully in the
                    given game week.
                </div>
                <div>
                    After choosing a team and entering a certain game, all you have to do is to watch the matches,
                    have fun and accumulate points. Fantasy football games are games of skill in which football
                    knowledge can bring big prizes. For more information on scoring, see the Scoring section.
                </div>
                <br />
                <div>
                    We look forward to hearing your questions and comments by email to: <a href='mailto:info@ffelelven.com'>info@ffeleven.com</a>. We
                    hope that you, like us, will immerse yourself in the incredible experience called fantasy football!
                </div>
            </div>
        </div>
    );
}

export const GetNoRewardsGetStarted = (props) => {
    return (
        <div style={{ ...styles.itemText, }}>
            <div dir='rtl'>
                <div>
                    <b>
                        ما هي الفوتبول فانتزي!
                    </b>
                </div>
                <br />

                <div>
                    مرحبًا بك في عالم الفوتبول فانتزي! لأولئك منكم الذين ليسوا على دراية بأساسيات الألعاب الفانتزي ، نحن هنا للمساعدة.
                </div>
                <br />
                <div>
                    عندما تبدأ لعبة فوتبول فانتزي ، فإنك تأخذ دور مدير فريق افتراضي من لاعبي كرة القدم من اختيارك. يمكن أن يتكون هذا الفريق من 11 لاعبًا. توفر كل لعبة للمديرين ميزانية محددة لبناء فريقهم. يحصل كل لاعب كرة قدم على نقاط خيالية بناءً على أدائه في المباريات الحقيقية.
                </div>
                <br />
                <div>
                    بعد اختيار فريق والدخول في لعبة معينة ، كل ما عليك فعله هو مشاهدة المباريات والاستمتاع وجمع النقاط. فوتبول فانتزي هي لعبة مهارية لمزيد من المعلومات حول التسجيل ، راجع قسم تسجيل الدرجات.
                </div>
                <br />
                <div>
                    نأمل أن تنغمس ، مثلنا ، في تجربة لا تصدق تسمى فوتبول فانتازي!
                </div>
                <br />
                <div>
                    نتطلع إلى سماع أسئلتك وتعليقاتك عبر البريد الإلكتروني:
                </div>
                <br />
                <a href='mailto:info@ffelelven.com'>info@ffelelven.com</a>
            </div>
            <div dir='ltr'>
                <div><b>Fantasy football Eleven: Getting started</b></div>
                <br />

                <div>
                    Welcome to the world of fantasy football games! For those of you who are not yet familiar with
                    the basics of fantasy games, we are here to help.
                </div>
                <br />
                <div>
                    When you start a fantasy football game, you take on the role of manager of a virtual team of
                    football players of your choice. This team can consist of 11 players. Each game provides
                    managers with a specific budget to build their team. Each football player receives fantasy points
                    based on their performance in the real matches.
                </div>
                <div>
                    After choosing a team and entering a certain game, all you have to do is watch the matches, have
                    fun and accumulate points. Fantasy football games are games of skill, for more information on
                    scoring, see the Scoring section.
                </div>
                <br />
                <div>
                    We look forward to hearing your questions and comments by email to: <a href='mailto:info@ffelelven.com'>info@ffeleven.com</a> .
                    We hope that you, like us, will immerse yourself in the incredible experience called fantasy football!
                </div>
            </div>
        </div>
    );
}

// export const GetDefaultGetStarted = (props) => {
//     return (
//         <div dir='ltr'>
//                     <div><b>Fantasy football Eleven: Getting started</b></div>
//                     <br/>

//                     <div>
//                         Welcome to the world of fantasy football games! For those of you who are not yet familiar with
//                         the basics of fantasy games, we are here to help.
//                     </div>
//                     <br/>
//                     <div>
//                         When you start a fantasy football game, you take on the role of manager of a virtual team of
//                         football players of your choice. This team can consist of 11 players. Each game provides
//                         managers with a specific budget to build their team. Each football player receives fantasy points
//                         based on their performance in the real matches.
//                     </div>
//                     <div>
//                         After choosing a team and entering a certain game, all you have to do is watch the matches, have
//                         fun and accumulate points. Fantasy football games are games of skill, for more information on
//                         scoring, see the Scoring section.
//                     </div>
//                     <br/>
//                     <div>
//                         We look forward to hearing your questions and comments by email to: <a href='mailto:info@ffelelven.com'>info@ffeleven.com</a> .
//                         We hope that you, like us, will immerse yourself in the incredible experience called fantasy football!
//                     </div>
//                 </div>
//     );
// }

export const GetDefaultGetStarted = (props) => {
    return (
        <div style={{ ...styles.itemText }}>
            <span>{translations.tKey('str_support_description_intro')}</span><br /><br />
            <span>{translations.tKey('str_support_description_fantasy_game')}</span><br /><br />
            <span>{translations.tKey('str_support_description_team_selection')}</span><br /><br />
            <span>{translations.tKey('str_support_description_contact_base')}
                <a href="https://www.ffstars.com" target="_blank">{translations.tKey('str_support_description_contact_url')}</a>
                {translations.tKey('str_support_description_contact_addition')}
                <a href="mailto: info@ffstars.com" target="_blank">{translations.tKey('str_support_description_contact_email')}</a>
                {translations.tKey('str_support_description_contact_end')}</span><br />
        </div>
    )
}

export const GetEnglishSubscription = () => {
    return (
        <div style={styles.detailText}><b>For this week, only GW17 is opened for English Premier League which includes the 1000$ prize</b></div>
    )
}

export const GetArabicSubscription = () => {
    return (
        <div style={styles.detailText} dir='rtl'><b>الجولة 17 من الدوري الانكليزي هي التي تحتوي على جائزة 1000 دولار لهذا الاسبوع فقط</b></div>
    )
}

export const GetEnglishRewards = () => {
    return (
        <div style={styles.detailText}><b>Awards will be given to winners of English Premier League Games</b></div>
    )
}

export const GetArabicRewards = () => {
    return (
        <div style={styles.detailText} dir='rtl'><b>الجوائز ستمنح للفائزين في العاب الدوري الانجليزي فقط</b></div>
    )
}

const operator = getDomainOperator();

const design = operator.design;

const styles = {
    itemText: {
        fontFamily: design.textFontFamily,
        fontSize: 14,
    },
    justifyText: {
        textAlign: 'justify'
    },
    detailText: {
        fontSize: 13,
        paddingLeft: 8,
        paddingRight: 8,
        marginBottom: 8,
    }
}
