import React from "react";
import { 
  View, 
  Text,
  StyleSheet, 
  TouchableOpacity,
  Image
} from "react-native";
import Background from '../Common/components/Background';
import LoadingScreen from '../Common/components/LoadingScreen';
import RoomView from '../Common/components/RoomView';
import rulesIcon from '../assets/exclamation.jpg';
import { connect } from 'react-redux';
import { joinRoom } from '../SearchRoom/actions/rooms';
import { setError } from '../Common/actions/errors';
import PopupDialog from '../Common/components/PopupDialog';

class FoundRooms extends React.Component {
  state = {
    showErrorPopup: false
  }

  joinRoom = () => {
    this.setState({hideButton: true});
    this.props.joinRoom(this.props.foundRoom.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.roomInfo !== this.props.roomInfo) {
      this.props.navigation.navigate('Team');
    }
    if (this.props.error !== '' && !this.state.showErrorPopup) {
      this.setState({showErrorPopup: true});
    }
  }

  render() {
  const { navigation, foundRoom:room } = this.props;
  if (!room) {
    navigation.navigate('ChooseBet'); 
    return <LoadingScreen />; 
  }
  return (
    <View style={styles.container}>
      <Background />
      <Text style={styles.subText}>Търсене на стая</Text>
      
      <PopupDialog isVisible={this.state.showErrorPopup} 
                  onDismiss={()=>this.setState({showErrorPopup: false, hideButton: false}) || this.props.setError('')} 
                  title="Грешка при влизане в стая">
        <View style={{width: '100%', justifyContent: 'center', alignItems: 'center'}}>
          <Text>{this.props.error}</Text>
        </View>
      </PopupDialog>

      <View style={{width: '90%', alignItems: 'center', justifyContent: 'space-between'}}>
        <RoomView room={room} isExpanded={true} />

        {!this.state.hideButton && <TouchableOpacity style={styles.playButton} onPress={this.joinRoom}>
            <Text style={styles.buttonText}>ИГРАЙ!</Text>
        </TouchableOpacity>}

        <TouchableOpacity style={[styles.playButton, {backgroundColor:'#ABC0D0'}]} 
          onPress={() => navigation.navigate('ChooseBet')}>
            <Text style={styles.buttonText}>ОТКАЖИ</Text>
        </TouchableOpacity>

        <TouchableOpacity style={styles.rules} onPress={()=>navigation.navigate('Rules')}>
          <Image source={rulesIcon} style={styles.image} />
          <Text style={styles.rulesText}>правила на играта</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    joinRoom: id => dispatch(joinRoom(id)),
    setError: error => dispatch(setError(error))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    foundRoom: state.room.foundRoom,
    roomInfo: state.room.roomInfo,
    error: state.errors.error
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(FoundRooms);



const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  betView: {
    backgroundColor: '#CCD4E1',
    paddingVertical: 5,
    paddingHorizontal: 30,
    width: '90%',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 15
  },
  betText: {
    fontWeight: 'bold',
    fontSize: 25,
    marginVertical: 5
  },
  logo: {
    width: '60%',
    height: 110,
    resizeMode: 'contain',
  },
  playButton: {
    marginTop: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    width: '70%',
    backgroundColor: '#72E86C',
    paddingVertical: 20,
    paddingHorizontal: 10
  },
  buttonText: {
    fontWeight: 'bold',
    fontSize: 22
  },
  subText: {
    zIndex: 200,
    fontWeight: 'bold',
    width: '100%',
    marginVertical: 12,
    marginTop: 70,
    color: 'white',
    fontSize: 25,
    textAlign: 'center'
  },
  emphasis: {
    marginVertical: 7,
    color: '#C5A150',
    fontSize: 22,
    textAlign: 'center',
    width: '70%'
  },
  mainText: {
    marginVertical: 7,
    color: 'white',
    fontSize: 36,
    textAlign: 'center'
  },
  rules: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginVertical: 20
  },
  rulesText: {  
    color: '#C3A050',
    fontSize: 18,
    margin: 10
  },
  image: {
    width: 30, 
    height: 30,
    resizeMode: 'contain'
  }
});