import React, {Component} from 'react'
import { View, Text, StyleSheet } from "react-native";
import { getDomainOperator } from '../../Common/utils/domainMapper';
import * as translations from "../../Common/utils/translations"

class PlayerInfoFixtures extends Component {
    state = {
        popupShown: false,
        selectedRoundId: null
    }

    showPopup = roundId => {
        if (this.state.popupShown) {
            this.hidePopup();
            return;
        }
        this.setState({
            popupShown: true,
            selectedRoundId: roundId
        });
    }

    hidePopup = () => {
        this.setState({
            popupShown: false,
            selectedRoundId: null
        });
    }

    getTextColor = (points) => {
        if (points >= 4 && points <= 5) {
            return { color: design.textColorPrimary }
        }
        return {}
    }

    getPlayerPointsColor = (points, isBackground) => {
        if (points <= 0) {
            return isBackground ? { backgroundColor: "#A50039" } : { color: '#A50039' };
        }
        if (points >= 1 && points <= 3) {
            return isBackground ? { backgroundColor: "#FF085D" } : { color: '#FF085D' };
        }
        if (points >= 4 && points <= 5) {
            return isBackground ? { backgroundColor: "#EBFF00" } : { color: '#EBFF00' };
        }
        if (points >= 6 && points <= 9) {
            return isBackground ? { backgroundColor: design.secondaryColor } : { color: design.secondaryColor };
        }
        return isBackground ? { backgroundColor: "#02894E" } : { color: '#02894E' };
    }

    render() {
        return (<View style={[styles.statsColumn, { width: "100%" }]}>

            <View style={styles.roundsContainer}>
                {this.props.player.fixtures.map((round, i) => (
                    <View style={styles.roundContainer}
                        key={"" + (round.id * i)}
                        onPress={() => this.showPopup(round.id)}>
                        <View style={styles.roundNumber}>
                            <Text style={styles.roundIndex}>{round.roundNumber}</Text>
                            <Text>{translations.tKey("str_game_week")}</Text>
                        </View>
                        {round.playerPoints !== undefined ?
                            <React.Fragment>
                                <View style={styles.score}>
                                    <Text style={[styles.bold, styles.teamWidth]}>{round.hostName}</Text>
                                    <Text style={styles.bold}>{round.hostScore} : {round.guestScore}</Text>
                                    <Text style={[styles.bold, styles.teamWidth]}>{round.guestName}</Text>
                                </View>
                                <View style={[styles.playerPoints, this.getPlayerPointsColor(round.playerPoints, true)]}>
                                    <Text style={[styles.pointsText, this.getTextColor(round.playerPoints)]}>{round.playerPoints}</Text>
                                </View>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <View style={styles.score}>
                                    <Text style={[styles.bold, styles.teamWidth]}>{round.hostName}</Text>
                                    <Text style={styles.bold}>-:-</Text>
                                    <Text style={[styles.bold, styles.teamWidth]}>{round.guestName}</Text>
                                </View>
                                <View style={styles.playerPoints}>
                                    <Text style={[styles.pointsText, { color: design.textColorPrimary }]}>{round.date}</Text>
                                </View>
                            </React.Fragment>
                        }
                    </View>
                ))}
            </View>
        </View>
        )
    }
}

export default PlayerInfoFixtures

const operator = getDomainOperator();

const design = operator.design;

const styles = StyleSheet.create({
    statsColumn: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
    },
    tableRow: {
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: "center",
        marginVertical: 10,
        width: "90%"
    },
    bigColumn: {
        width: "50%",
        display: 'flex',
        alignItems: "flex-start"
    },
    tableHeaderText: {
        fontSize: 16,
        fontWeight: "bold"
    },
    smallColumn: {
        width: "25%",
        display: 'flex',
        alignItems: "flex-start"
    },
    roundsContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    roundContainer: {
        flexDirection: 'row',
        borderRadius: 10,
        width: '90%',
        marginVertical: 3
    },
    roundNumber: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#EEEEEE',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        paddingVertical: 3
    },
    score: {
        flex: 3,
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        alignItems: 'center',
        paddingHorizontal: 16,
        marginHorizontal: 16
    },
    roundIndex: {
        fontWeight: 'bold',
        fontSize: 17
    },
    bold: {
        fontWeight: 'bold',
        fontSize: 12
    },
    teamWidth: {
        width: '20%'
    },
    playerPoints: {
        flex: 1,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white'
    },
    icon: {
        width: 20,
        height: 20,
        marginHorizontal: 10
    },
    pointsText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 12,
        textAlign: 'center'
    }
})
