import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity, ScrollView, ActivityIndicator } from 'react-native';
import { connect } from 'react-redux';
import { createStyles, maxWidth } from 'react-native-media-queries';
import { getScoring } from '../actions/enterTournament';
import * as translations from '../../Common/utils/translations'
import { getDomainOperator } from '../../Common/utils/domainMapper';

class EnterTournameScoring extends React.Component {
  componentDidMount() {
    this.props.getScoring();
  }

  render() {
    const { navigation, scoring, isLoading, isForSupport, scrollable } = this.props;

    const Wrapper = scrollable
      ? ScrollView
      : View;

    return (
      <View style={isForSupport ? styles.supportContainer : styles.container}>
        {!isForSupport && <TouchableOpacity style={styles.exitButton}
          onPress={() => navigation.goBack()}
        ><Text style={{ color: 'white', fontWeight: 'bold', fontSize: 15 }}>x</Text></TouchableOpacity>}
        {!isForSupport && <View style={styles.purpleHeader}>
          <View style={styles.headerContainer}>
            <Text style={styles.headerText}>{translations.tKey('str_scoring_dialog_title')}</Text>
          </View>
        </View>}
        <View style={isForSupport ? styles.mainContainerForSupport : styles.mainContainer}>
          {!scoring || isLoading ? <ActivityIndicator size={'large'} color={design.primaryColor} />
            :
            <Wrapper style={styles.mainContentDimentions} contentContainerStyle={styles.mainContent}>
              {scoring.map((item, index) => (
                <View style={styles.mainContentRow} key={index}>
                  <Text style={styles.mainContentText}>{item.name}</Text>
                  <View style={[styles.pointsBox, { backgroundColor: item.points > 0 ? design.secondaryColor : '#FF085D' }]}>
                    <Text style={{ color: item.points > 0 ? design.textColorPrimary : 'white' }}>{item.points}</Text>
                  </View>

                </View>
              ))}
            </Wrapper>}


        </View>
      </View>
    );
  }
};

EnterTournameScoring.propTypes = {
  scrollable: PropTypes.bool
}

EnterTournameScoring.defaultProps = {
  scrollable: true
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getScoring: () => dispatch(getScoring())

  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    scoring: state.enterTournament.scoring,
    isLoading: state.ui.isLoading
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnterTournameScoring);

const operator = getDomainOperator();

const design = operator.design;

const base = {
  supportContainer: {
    width: '100%',
    display: 'flex',
    marginBottom: 20,
    alignItems: 'center'
  },
  container: {
    width: '100%',
    display: 'flex',

    alignItems: 'center',
  },
  purpleHeader: {
    width: '100%',
    height: '10vh',
    backgroundColor: design.primaryColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%'
  },
  headerText: {
    fontSize: 20,
    fontWeight: 550,
    color: 'white'
  },
  mainContainer: {
    width: '90%',
    minHeight: '400px',
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px'
  },
  mainContainerForSupport: {
    width: '90%',
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px'
  },
  mainContentDimentions: {
    width: '100%',
    paddingRight: '10px'
  },
  mainContent: {
    paddingVertical: '20px'
  },
  exitButton: {
    backgroundColor: '#FF085D',
    width: 22,
    height: 22,
    position: 'absolute',
    paddingBottom: 5,
    top: 1,
    right: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999
  },
  mainContentRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '5px'
  },
  mainContentText: {
    width: '90%'
  },
  pointsBox: {
    width: '10%',
    paddingVertical: '5px',
    paddingHorizontal: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

const styles = createStyles(
  base,
  maxWidth(576, {}),
  maxWidth(376, {})
)

