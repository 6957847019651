import React from 'react';
import PropTypes from 'prop-types';

import { View, Text, Image, TouchableOpacity, Linking } from 'react-native';

import { createStyles } from 'react-native-media-queries';
import { BASE_URL } from '../../Common/config/config';
import * as translations from '../../Common/utils/translations';
import TournamentHighlights from '../../TournamentHighlights/screens/TournamentHighlights';
import { getDomainOperator } from '../../Common/utils/domainMapper';
import { getDateAndTime } from '../../Common/utils/ZonedTimeService';

class TournamentOverview extends React.Component {

  state = {};

  render() {
    const {fixtures, selected} = this.props;

    if (!fixtures) return null;

    return (
      <View style={{ ...styles.container, ...(this.props.maxHeight && { maxHeight: this.props.maxHeight, overflowY: 'scroll' })}}>
        {fixtures.map((t, index) => {
          const { date, time } = getDateAndTime(t.datetime);
          const topText = t.period ? t.period : date;
          const bottomText = t.result ? t.result : time;
          return (
            <div style={{ width: '100%' }}>
              <div
                key={index}
                onClick={() => {
                  if (this.props.onSelected) {
                    this.props.onSelected(t.id);
                  }
                }}
                style={{
                  ...styles.itemColumn,
                  backgroundColor: selected === t.id
                    ? design.secondaryColor
                    : 'white'
                }}
              >
                <TouchableOpacity
                  style={{
                    ...styles.itemColumn,
                    ...(selected === t.id && { backgroundColor: design.secondaryColor })
                  }}
                >
                  <View style={styles.row}>
                    <View style={styles.hostRow}>
                      <Text style={[styles.nameText, {textAlign: 'right'}]}>{t.host_team.name}</Text>
                      <Image style={styles.image} source={BASE_URL + t.host_team.logo.url}/>
                    </View>
                    <View style={styles.dateTimeColumn}>
                      <Text style={styles.dateText}>{topText}</Text>
                      <Text style={styles.timeText}>{bottomText}</Text>

                    </View>
                    <View style={styles.guestRow}>
                      <Image style={styles.image} source={BASE_URL + t.guest_team.logo.url}/>
                      <Text style={styles.nameText}>{t.guest_team.name}</Text>
                    </View>
                  </View>

                  {t.online_store_url && (
                    <TouchableOpacity
                      style={styles.ticketButton}
                      onPress={() => {
                        Linking.openURL(t.online_store_url.includes('http') ? t.online_store_url : `https://${t.online_store_url}`);
                      }}
                    >
                      <Text style={styles.ticketText}>{translations.tKey('str_buy_ticket')}</Text>
                    </TouchableOpacity>
                  )}
                </TouchableOpacity>


              </div>

              {(selected === t.id) && (
                <div style={styles.tournamentHighlightsContainer}>
                  <TournamentHighlights
                    match_id={selected}
                    onPointsPressed={() => {
                      const { onPointsPressed } = this.props;

                      typeof onPointsPressed === 'function'
                        && onPointsPressed();
                    }}
                  />
                </div>
              )}
            </div>
          );
        })
        }
      </View>

    );
  }
}

TournamentOverview.propTypes = {
  onPointsPressed: PropTypes.func,
  maxHeight: PropTypes.number
};

export default TournamentOverview;

const operator = getDomainOperator();

const design = operator.design;

const base = {

  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },

  tournamentHighlightsContainer: {
    margin: '30px 0',
    display: 'flex',
    justifyContent: 'center'
  },

  itemColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: '15px',
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: '10px'
  },
  image: {
    width: 20,
    height: 20,
    resizeMode: 'cover',
    marginHorizontal: '10px'
  },
  dateText: {
    backgroundColor: design.tertiaryColor,
    fontSize: 12,
    paddingLeft: '10px',
    paddingRight: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    fontWeight: 550
  },
  nameText: {
    fontSize: 14
  },
  timeText: {
    fontSize: 10,
    fontWeight: 550
  },
  ticketText: {
    fontSize: 10,
    fontWeight: 550
  },
  ticketButton: {
    border: `2px solid ${design.secondaryColor}`,
    paddingVertical: '2px',
    paddingHorizontal: '10px',
    borderRadius: '5px',
    textDecoration: 'underline',
    marginTop: '5px'
  },
  dateTimeColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  hostRow: {
    width: '40%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  guestRow: {
    width: '40%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  }
};

const styles = createStyles(base);

