import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, Image } from 'react-native';
import autoInIcon from '../../assets/transfer-in.png';
import autoOutIcon from '../../assets/transfer-out.png';
import banIcon from '../../assets/banned-player.svg.png';
import injuredIcon from '../../assets/injured-player.svg.png';
import { getDomainOperator } from "../utils/domainMapper";
import transferedIcon from '../../assets/transfered-player.svg.png';
import removeIcon from '../../assets/remove-player.svg.png';
import { createStyles, maxWidth } from 'react-native-media-queries';
import * as translations from '../../Common/utils/translations';
import { PlayerPointsDialog } from './PlayerPointsDialog';
import { formatPlayersName } from '../../Common/utils/utils';

const getNullPlayerPositionText = (positionId) => {
  switch (positionId) {
    case 1: {
      return translations.tKey('str_playground_add_gkp');
    }
    case 2: {
      return translations.tKey('str_playground_add_def');
    }
    case 3: {
      return translations.tKey('str_playground_add_mid');
    }
    case 4: {
      return translations.tKey('str_playground_add_fwd');
    }
    default: {
      return;
    }
  }
};

const PlayerView = props => {
  const { onPress } = props;

  let
    selectedStyle = null,
    selectedPlayerName = null,
    saleStyle = null,
    hidePosition = false,
    seasonalStyle = null,
    changePlayerDialogStyle = null;

  const [pointsDialogOpen, setPointsDialogOpen] = React.useState(false);

  if (props.isForSale) {
    saleStyle = { opacity: 0.6 };
  }

  if (props.selected) {
    selectedStyle = { opacity: props.isForSale ? 1.0 : 0.6 };
    selectedPlayerName = { backgroundColor: '#2053A4' };
  }

  if (props.reserves) {
    seasonalStyle = {
      marginTop: -75, height: 60
    };
  }

  if (props.hidePosition) {
    hidePosition = true;
  }

  if (props.changePlayerDialog) {
    changePlayerDialogStyle = {
      width: '22%',
      height: '70%'
    }
  }

  return (
    <div
      style={{
        paddingVertical: 3,
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 3,
        width: '20%',
        height: '70%',
        ...seasonalStyle,
        ...changePlayerDialogStyle
      }}>
      {props.showPoints && pointsDialogOpen && (
        <PlayerPointsDialog
          firstName={props.firstName}
          lastName={props.lastName}
          points={props.points}
          open={pointsDialogOpen}
          onClose={() => setPointsDialogOpen(false)}
          playerId={props.id}
        />
      )}

      <div
        onClick={() => {
          props.showPoints
            ? setPointsDialogOpen(true)
            : typeof (onPress) === 'function' && onPress();
        }}
        style={{
          width: '100%',
          height: '100%',
          ...((props.showPoints || typeof (onPress) === 'function') && styles.clickable)
        }}>

        <div style={{
          display: 'flex',
          flexFlow: 'column',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative'
        }}
        >

          <img
            style={{ ...(props.changePlayerDialog ? styles.changePlayerDialogTeamIcon : styles.teamIcon), ...saleStyle, ...selectedStyle, ...props.iconStyle, ...styles.top12 }}
            src={
              props.nullPlayer
                ? require('../../assets/tshirts/BlackPlusShirt.png')
                : props.iconUrl
            }
            alt="T-Shirt"
          />


          {props.canRemoveFromList && <Image source={removeIcon} style={styles.removeStyle} />}

          {props.isBanned && <Image source={banIcon} style={styles.iconStyle} />}
          {props.isInjured && <Image source={injuredIcon} style={styles.iconStyle} />}
          {props.isTransfered && <Image source={transferedIcon} style={styles.iconStyle} />}
          {props.isAutoOut &&
            <Image source={autoOutIcon} style={[styles.iconStyle, { width: 15, height: 15, top: 35 }]} />}
          {props.isAutoIn && <Image source={autoInIcon} style={[styles.iconStyle, { width: 15, height: 15, top: 35 }]} />}

          {props.isCaptain && (
            <span style={{ ...styles.captainIconStyle, ...styles.circle}}>{translations.tKey('str_captain_icon')}</span>
          )
          }

          {props.isViceCaptain && (
            <span style={{ ...styles.captainIconStyle, ...styles.circle}}>{translations.tKey('str_vice_captain_icon')}</span>
          )
          }
        </div>
      </div>

      <div
        style={{
          ...styles.playerInfoContainer,
          ...(props.showPoints && styles.clickable)
        }}
        onClick={() => props.showPoints && setPointsDialogOpen(true)}
      >
        {!props.nullPlayer && !hidePosition && (
          <View style={styles.chosenByContainer}>
            {!props.hidePercent && (
              <Text style={styles.chosenByText}>
                {`${props.chosenBy === '100.00' ? '100' : props.chosenBy}%`}
              </Text>
            )}
            {props?.isSeasonal && <Text style={styles.priceText}>
              {`${props.price.toFixed(1)}M`}
            </Text>}
            {!props?.isSeasonal && <Text style={styles.priceText}>
              {`${props.gw_price.toFixed(1)}M`}
            </Text>}


          </View>
        )}

        <div
          style={{
            ...styles.playerNameContainer,
            ...selectedPlayerName,
            ...(props.nullPlayer && {
              marginTop: 11
            }),
            ...(props.showPoints && styles.clickable)
          }}
          onClick={() => props.showPoints && setPointsDialogOpen(true)}
        >
          {props.nullPlayer ?
            <Text style={{ ...styles.playerName, ...styles.nullPlayerName }}>
              {getNullPlayerPositionText(props.positionId)}
            </Text>
            :
            <Text style={{ ...styles.playerName, ...props.playerStyle }}>
              {formatPlayersName(props, 'last')}
            </Text>
          }
        </div>

        {props.showPoints && (
          <div
            style={{ ...styles.playerMatchText, ...styles.clickable }}
            onClick={() => setPointsDialogOpen(true)}
          >
            <Text style={styles.matchRegularText}>
              {`${props.points.toFixed(2)}`}
            </Text>
          </div>
        )}

        {!props.nullPlayer && !props.showPoints ?
          <div style={styles.playerMatchText}>

            {!props.teams[0].name || !props.teams[0].name ? <Text style={[styles.matchRegularText]}>
              {`Отложен`}
            </Text> : <>
                <Text style={[styles.matchRegularText, !props.teams[0].player_in_team ? styles.matchDimmedText : {}]}>
                  {`${props.teams[0].name} - `}
                </Text>
                <Text style={[styles.matchRegularText, !props.teams[1].player_in_team ? styles.matchDimmedText : {}]}>
                  {`${props.teams[1].name}`}
                </Text>
              </>}
          </div>
          : null
        }
      </div>


      {((props.preferencePosition || props.preferencePosition === 0) && !localStorage.getItem('changeTeam') && !hidePosition) && (
        <p style={{ textAlign: 'center', marginTop: 8 }}>
          {!!props.preferencePosition ? props.preferencePosition : 'ВРТ'}
        </p>
      )}
    </div>
  );
};

PlayerView.propTypes = {
  onPress: PropTypes.func.isRequired,

  firstName: PropTypes.string,
  lastName: PropTypes.string,

  preferencePosition: PropTypes.number
};

export default PlayerView;

const operator = getDomainOperator();

const design = operator.design;

const base = {
  circle: {
    height: '20px',
    width: '20px',
    backgroundColor: '#000',
    borderRadius: '100%',
  },
  playerInfoContainer: {
    width: 'calc(100% - 10px)',
    boxSizing: 'border-box',
    margin: '0 5px'
  },
  imageContainer: {
    position: 'relative',
    textAlign: 'center',
    color: 'white'
  },
  absoluteCenter: {
    position: 'absolute',
    top: '50%,',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  clickable: {
    cursor: 'pointer'
  },
  playerContainer: {
    paddingVertical: 3,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 3,
    width: '20%',
    height: '70%'
  },
  playerName: {
    textAlign: 'center',
    fontWeight: 550,
    fontSize: 12,
    color: 'white',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  nullPlayerName: {
    backgroundColor: '#02894E',
    color: 'white',
    opacity: 0.5
  },
  playerNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: '#02894E',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    alignItems: 'center',
    justifyContent: 'center'
  },
  playerTeamContainer: {
    marginTop: 3,
    backgroundColor: '#02894E',

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    alignItems: 'center',
    justifyContent: 'center'
  },
  playerTeam: {
    fontWeight: 'bold',
    fontSize: 15,
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center'
  },
  teamIcon: {
    maxWidth: '100%',
    maxHeight: '100%',
    marginTop: '15px'
  },
  changePlayerDialogTeamIcon: {
    maxWidth: 'auto',
    maxHeight: '80px',
    marginTop: '15px'
  },
  top12: {
    top: 12
  },
  iconStyle: {
    position: 'absolute',
    width: 20,
    height: 20,
    top: 20,
    right: 10,
    zIndex: 1
  },
  removeStyle: {
    position: 'absolute',
    width: 25,
    height: 25,
    top: 10,
    right: 0,
    zIndex: 1
  },
  saleStyle: {
    position: 'absolute',
    width: 30,
    height: 30,
    top: 80,
    left: 70,
    zIndex: 1
  },
  buyStyle: {
    position: 'absolute',
    width: 40,
    height: 30,
    top: 20,
    right: 37,
    zIndex: 1
  },
  captainIconStyle: {
    position: 'absolute',
    width: 20,
    height: 20,
    top: 0,
    right: 10,
    zIndex: 1,
    color: '#FFF',
    fontSize: 10,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Sans-Serif',
    fontWeight: '700',
  },
  matchDimmedText: {
    fontSize: 10,
    opacity: 0.3,
    fontWeight: 550
  },
  matchRegularText: {
    fontSize: 10,
    fontWeight: 550
  },
  playerMatchText: {
    display: 'flex',
    backgroundColor: design.secondaryColor,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  },
  chosenByContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%'
  },
  chosenByText: {
    height: '11px',
    width: '50%',
    backgroundColor: design.primaryColor,
    color: 'white',
    fontSize: 10,
    textAlign: 'center'
  },
  priceText: {
    height: '11px',
    width: '50%',
    backgroundColor: 'white',
    fontSize: 10,
    textAlign: 'center',
    fontWeight: 550
  }
};

const styles = createStyles(
  base,
  maxWidth(576, {
    chosenByText: {
      width: '50%', backgroundColor: design.primaryColor, color: 'white', fontSize: 8, textAlign: 'center'
    },
    priceText: {
      width: '50%', backgroundColor: 'white', fontSize: 8, textAlign: 'center', fontWeight: 550
    },
    playerName: {
      textAlign: 'center',
      fontWeight: 550,
      fontSize: 10,
      color: 'white',
      width: '14vw',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    matchDimmedText: {
      fontSize: 8,
      opacity: 0.3,
      fontWeight: 550
    },
    matchRegularText: {
      fontSize: 8,
      fontWeight: 550
    },
    chosenByContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%'
    },
    playerMatchText: {
      backgroundColor: design.secondaryColor,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row'
    },
    plusAdd: {
      fontSize: 25,
      fontWeight: 550,
      color: 'white'
      // position: "absolute",
      // top: "-2px",
      // left: "20%"
    }
  }),
  maxWidth(376, {
    chosenByText: {
      width: '50%', backgroundColor: design.primaryColor, color: 'white', fontSize: 8, textAlign: 'center'
    },
    priceText: {
      width: '50%', backgroundColor: 'white', fontSize: 8, textAlign: 'center', fontWeight: 550
    },
    playerContainer: {
      paddingVertical: 3,
      justifyContent: 'center',
      alignItems: 'center',
      marginHorizontal: 3,
      width: '20%',
      height: '70%'
    },
    playerName: {
      textAlign: 'center',
      fontWeight: 550,
      fontSize: 10,
      color: 'white',
      width: '14vw',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    matchDimmedText: {
      fontSize: 8,
      opacity: 0.3,
      fontWeight: 550
    },
    matchRegularText: {
      fontSize: 8,
      fontWeight: 550
    },
    chosenByContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%'
    },
    playerMatchText: {
      backgroundColor: design.secondaryColor,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row'
    },
    plusAdd: {
      fontSize: 25,
      fontWeight: 550,
      color: 'white'
      // position: "absolute",
      // top: "-2px",
      // left: "18%"
    }
  })
);
