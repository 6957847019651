import React from "react";
import { StyleSheet, Text, View, Image } from "react-native";
import PopupDialog from "../../Common/components/PopupDialog";
import { connect } from 'react-redux';
import { getUserStatsForRound } from '../actions/players';
import { getDomainOperator } from "../../Common/utils/domainMapper";

class PlayerProfilePopup extends React.Component {

  getColor = points => {
    if (points < 0) {
      return { backgroundColor: "#A43B32" };
    }
    if (points > 0) {
      return { backgroundColor: "#73E86D" };
    }

    return { backgroundColor: "#F6C146" };
  };

  componentDidUpdate(prevProps) {
    if ((prevProps.roundId !== this.props.roundId || 
        prevProps.playerId !== this.props.playerId)  && 
      this.props.playerId && 
      this.props.roundId) {      
     this.props.getUserStatsForRound(this.props.playerId, this.props.roundId, this.props.forPoints);
    }
  }

  render() {
    if (!this.props.playerProfile) return null;
    return (
      <PopupDialog
        isVisible={this.props.isVisible}
        onDismiss={this.props.onDismiss}>
        <View style={styles.headerContainer}>
          <Image source={{uri:this.props.playerProfile.image}} style={styles.playerImage} />
          <View style={styles.playerInfoContainer}>
            <Text style={styles.name}>{this.props.playerProfile.name}</Text>
            <Text style={{marginVertical: 10}}>
              {"" + this.props.playerProfile.position +  "/" + this.props.playerProfile.team}
            </Text>
            {this.props.playerProfile.hostName && 
            <View style={styles.score}>
              <Text>{this.props.playerProfile.hostName}</Text>
              <Image source={{uri:this.props.playerProfile.hostLogo}} style={styles.icon} />
              <Text>
                {"" + this.props.playerProfile.hostScore + "-" + this.props.playerProfile.guestScore}
              </Text>
              <Image source={{uri:this.props.playerProfile.guestLogo}} style={styles.icon} />
              <Text>{this.props.playerProfile.guestName}</Text>
            </View>}
            {this.props.playerProfile.playerPoints !== undefined ?
            <View style={[styles.playerPoints,
                this.getColor(this.props.playerProfile.playerPoints)]}>
              <Text style={styles.pointsText}>{""+this.props.playerProfile.playerPoints + "т."}</Text>
            </View> : null}
          </View>
        </View>
        <View style={styles.statsContainer}>
            {this.props.playerProfile.breakDown && 
            this.props.playerProfile.breakDown.map((stat,i) => (
                <View style={styles.row} key={i}>
                    <Text>{stat.criteria}</Text>
                    <Text style={{fontWeight: 'bold'}}>{"" + stat.points + " т."}</Text>
                </View>
            ))}
        </View>
      </PopupDialog>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getUserStatsForRound: (playerId, roundId, forPoints) => dispatch(getUserStatsForRound(playerId, roundId, forPoints)),
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
      playerProfile: state.teamPlayerInfo.playerStatInfo,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerProfilePopup);

const operator = getDomainOperator();

const design = operator.design;

const styles = StyleSheet.create({
  headerContainer: {
    width: '100%',
    flexDirection: 'row',
    marginTop: 10
  },
  playerImage: {
    height: 80,
    width: 60,
    marginRight: 20,
    borderRadius: 20,
  },
  name: {
    color: design.textColorPrimary,
    fontSize: 12,
    fontWeight: "bold"
  },
  score: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      backgroundColor:'white',
      alignItems: 'center'      
  },
  bold: {
    fontWeight: "bold",
    fontSize: 12
  },
  playerPoints: {
    width: 30,
    height: 30,
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white"
  },
  icon: {
    width: 20,
    height: 20,
    marginHorizontal: 10
  },
  pointsText: {
    color: design.textColorPrimary,
    fontSize: 12,
    textAlign: "center"
  },
  statsContainer: {
      width: '100%',
      paddingHorizontal: 5,
      paddingVertical: 3,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      marginVertical: 10,
      backgroundColor: '#E5EAF4'
  },
  row: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottomWidth: 1,
      borderColor: '#D5DAE5',
      paddingVertical: 5
  }
});
