import React from "react";
import { View, Text, Image, TouchableOpacity, ScrollView, ActivityIndicator, Linking } from "react-native";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { createStyles, maxWidth } from 'react-native-media-queries';
import { getPlayerInfo } from "../actions/enterTournament"
import buyShirt from "../../assets/images/buy_shirt.svg";
import { connect } from 'react-redux';
import { BASE_URL } from '../../Common/config/config';
import * as translations from "../../Common/utils/translations"

import PlayerInfoFixtures  from './PlayerInfoFixtures'
import { getDomainOperator } from "../../Common/utils/domainMapper";

const GOALKEPER = 1;
const DEFENDER = 2;
const MIDFIELDER = 3;
const ATTACKER = 4;

const activeTabInfo = {
    1: (props) => <PlayerInfoFixtures {...props} />,
}

class PlayerInfoDialog extends React.Component {

    state = {
        activeTab: 1
    }

    isSeasonal = null;

    componentDidMount() {
        this.props.getPlayerInfo(this.props.navigation.getParam("id", "0"))
        this.isSeasonal = (this.props.navigation.getParam("isSeasonal") == 'true');
    }   

    getPositionLabel = (positionId) => {
        const labelStyle = {
            fontWeight: "bold",
            fontSize: 12,
            paddingVertical: 4,
            paddingHorizontal: 5,
            marginRight: 5
        }
        var text = "";
        switch (positionId) {
            case GOALKEPER: {
                labelStyle.backgroundColor = "#EBFF00";
                text = translations.tKey("str_position_gk");
                break;
            }
            case DEFENDER: {
                labelStyle.backgroundColor = "#01FF87";
                text = translations.tKey("str_position_def");
                break;
            }
            case MIDFIELDER: {
                labelStyle.backgroundColor = "#00F0FF";
                text = translations.tKey("str_position_mid");
                break;
            }
            case ATTACKER: {
                labelStyle.backgroundColor = "#FF085D";
                labelStyle.color = "white";
                text = translations.tKey("str_position_fwd");
                break;
            }
            default: {
                break;
            }
        }
        return <Text style={labelStyle}>{text}</Text>
    }

    render() {
        const { player, navigation, isLoading } = this.props;
        if (!player || isLoading) return <ActivityIndicator size={"large"} color={design.primaryColor} style={{ marginVertical: 40 }} />
        return (
            <ScrollView style={styles.scrollView} contentContainerStyle={styles.container}>
                <View style={styles.header}>
                    <TouchableOpacity style={styles.exitButton}
                        onPress={() => navigation.goBack()}>
                        <Text style={styles.exitButtonText}>x</Text></TouchableOpacity>
                    <Image style={styles.playerShirt} source={BASE_URL + player.iconUrl} />
                    <View style={styles.headerColumn}>
                        <Text style={styles.playerName}>{player.name}</Text>
                        <View style={styles.headerRow}>
                            {this.getPositionLabel(player.position_id)}
                            <Text style={{ color: 'white' }}>{player.team}</Text>
                        </View>
                        {player.merchandiseLink && <TouchableOpacity style={styles.buyShirtButton} onPress={Linking.openURL(player.merchandiseLink)}>
                            <Image style={styles.buyShirtImage} source={buyShirt}></Image>
                            <Text style={{ color: "white", fontWeight: "bold" }}>{translations.tKey("str_player_options_buy_shirt")}</Text>
                        </TouchableOpacity>}
                    </View>
                </View>
                <View style={styles.statsRow}>
                    <View style={styles.statsColumn}>
                        <Text style={styles.statsKey}>{translations.tKey("str_player_info_form")}</Text>
                        <Text style={styles.statsValue}>{player.form.toFixed(1)}</Text>
                    </View>
                    <View style={styles.statsColumn}>
                        <Text style={styles.statsKey}>{translations.tKey("str_player_info_total_pts")}</Text>
                        <Text style={styles.statsValue}>{player.totalPoints}</Text>
                    </View>
                    <View style={styles.statsColumn}>
                        <Text style={styles.statsKey}>{translations.tKey("str_player_info_seasonal_price")}</Text>                         
                        {this.isSeasonal && 
                        <Text style={styles.statsValue}>{`${player.initial_price.toFixed(1)}M`}</Text>
                        } 
                        {!this.isSeasonal &&
                        <Text style={styles.statsValue}>{`${player.palms_price.toFixed(1)}M`}</Text>
                        }
                    </View>
                    <View style={styles.statsColumn}>
                        <Text style={styles.statsKey}>{translations.tKey("str_player_info_gw_price")}</Text>
                        <Text style={styles.statsValue}>{`${player.gw_price.toFixed(1)}M`}</Text>
                    </View>
                </View>
                <Tabs
                    value={this.state.activeTab}
                    style={{ background: '#EFEFEF' }}
                    indicatorColor="#EFEFEF"
                    textColor="primary"
                    aria-label="disabled tabs example"
                >
                    <Tab style={this.state.activeTab == 1 ? styles['.MuiTab-textColorPrimary.Mui-selected'] : null} label={translations.tKey("str_player_info_fixtures")} />
                </Tabs>
                {activeTabInfo[this.state.activeTab]({player})}
            </ScrollView>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getPlayerInfo: id => dispatch(getPlayerInfo(id))
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        player: state.enterTournament.playerInfo,
        isLoading: state.ui.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerInfoDialog);

const operator = getDomainOperator();

const design = operator.design;

const base = {
    scrollView: {
        flex: 1,
        width: '100%',
    },
    container: {
        width: "100%",
        display: "flex",
        alignItems: "center"
    },
    header: {
        backgroundColor: design.primaryColor,
        width: "100%",
        padding: 20,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start"
    },
    '.MuiTab-textColorPrimary.Mui-selected': {
        backgroundColor: "#963CFF",
        color: '#FFFFFF'
    },
    exitButton: {
        backgroundColor: "#FF085D",
        width: 22,
        height: 22,
        position: "absolute",
        paddingBottom: 5,
        top: 1,
        right: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",
        zIndex: 9999,
    },
    exitButtonText: {
        color: "white",
        fontWeight: 'bold',
        fontSize: 15
    },
    playerShirt: {
        width: 84,
        height: 91,
        resizeMode: "stretch"
    },
    headerColumn: {
        display: 'flex',
        alignItems: "flex-start",
        justifyContent: 'center',
        marginLeft: 20
    },
    playerName: {
        fontSize: 20,
        fontWeight: 550,
        color: "white",
        marginBottom: 5
    },
    headerRow: {
        display: 'flex',
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 5
    },
    buyShirtButton: {
        borderRadius: 3,
        border: "1px solid white",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: 'center',
        padding: 5
    },
    buyShirtImage: {
        width: 13.5,
        height: 11,
        marginRight: 5
    },
    statsRow: {
        display: "flex",
        flexDirection: 'row',
        width: "90%",
        alignItems: "center",
        justifyContent: "space-between",
        marginVertical: 30
    },
    statsColumn: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center'
    },
    statsKey: {
        fontSize: 12,
        opacity: 0.5
    },
    statsValue: {
        fontSize: 16,
        fontWeight: "bold"
    }
}

const styles = createStyles(
    base,
    maxWidth(576, {

    }),
    maxWidth(376, {

    })
)