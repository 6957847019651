import React from 'react';
import { 
    Text, 
    View, 
    Image, 
    TouchableOpacity, 
} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import Background from '../../Common/components/Background';
import HeaderWithBack from '../../Common/components/HeaderWithBack';
import LoadingScreen from '../../Common/components/LoadingScreen';
import RefreshContainer from '../../Common/components/RefreshContainer';
import goalIcon from '../../assets/goal.svg.png';
import ownGoalIcon from '../../assets/own-goal.svg.png';
import penaltyIcon from '../../assets/penalty.svg.png';
import substituteIcon from '../../assets/substitute.svg.png';
import mvpIcon from '../../assets/player-of-the-match.svg.png';
import halfTimeIcon from '../../assets/first-second-half.svg.png';
import savedPenaltyIcon from '../../assets/saved-penalty.svg.png';
import missedPenaltyIcon from '../../assets/missed-penalty.svg.png';
import TabBar from '../../Common/components/TabBar';
import TopBar from '../../Common/components/TopBar';


import { connect } from 'react-redux';
import { getLivescore } from '../actions/results'; 
import { getDomainOperator } from '../../Common/utils/domainMapper';

class LiveScore extends React.Component {
    static navigationOptions = {
      title: 'Резултати на живо',
    };

    state = {
        activeTab: 0,
        refreshing: false
    }


    refreshScore() {
        const matchId = this.props.navigation.getParam('scoreId');
        this.props.getLivescore(matchId);
    }

    componentDidMount() {
        this.refreshScore();
    }

    componentDidUpdate(prevProps) {
        if (this.state.refreshing && prevProps.livescore !== this.props.livescore) {
            this.setState({refreshing: false});
        }
    }


    setActiveTab = index => this.setState({ activeTab: index });

    isGoal = stat =>  stat.icon === goalIcon || stat.icon === ownGoalIcon || stat.icon === penaltyIcon;
    isOwnGoal = stat =>  stat.icon === ownGoalIcon;
    isSubstitute = stat =>  stat.icon === substituteIcon;
    isSavedPenalty = stat => stat.icon === savedPenaltyIcon;
    isMissedPenalty = stat => stat.icon === missedPenaltyIcon;
    
    renderStat(stat){
        return (
            <View style={styles.chronoRow} key={stat.statId*Math.random()}>
                <View style={styles.statContainer}>
                    <View style={[styles.statInfo,                                                 
                                    !stat.isHost ? styles.right : null,
                                    this.isGoal(stat) ? styles.goal : null,
                                    this.isOwnGoal(stat) ? styles.ownGoal : null,
                                    this.isSavedPenalty(stat) ? styles.savedPenalty : null,
                                    this.isMissedPenalty(stat) ? styles.missedPenalty : null]}>
                        <View style={{width: '100%', justifyContent: 'space-between'}}>
                            <View style={{flexDirection: 'row'}}>
                                <Text style={styles.minute}>{stat.minute}`</Text>
                                <Text style={[styles.playerName, {marginVertical: 0}]}>{stat.playerName}</Text>                            
                            </View>
                            {this.isGoal(stat) && stat.assistantName &&
                            <Text style={styles.assistant}>Ас: {stat.assistantName}</Text>}

                            {this.isSavedPenalty(stat) &&
                            <Text style={styles.assistant}>Сп. дузпа</Text>}

                            {this.isMissedPenalty(stat) &&
                            <Text style={styles.assistant}>Изп. дузпа</Text>}

                            {this.isSubstitute(stat) && stat.assistantName &&
                            <Text style={styles.substitute}>{stat.assistantName}</Text>}
                        </View>                                    
                    </View>
                </View>
                <Image source={stat.icon} style={styles.statIcon} />
            </View>
        )
    }

    _onRefresh = () => {
        this.setState({refreshing: true});
        this.refreshScore();        
    }

    render() {
        if (!this.props.livescore) return <LoadingScreen />;
        if (!this.props.roomInfo) {
            this.props.navigation.navigate('Team');
            return null;
        }
        return (
        <React.Fragment>
        <TopBar navigation={this.props.navigation} />
        <HeaderWithBack title={"Резултати на живо"} navigation={this.props.navigation} />

        <RefreshContainer 
            refreshing={this.state.refreshing} 
            onRefresh={this._onRefresh}
            containerStyle={{alignItems: 'center'}}>
                <Background livescore={true} style={{height: '130%'}}/>
                <View style={this.props.livescore.status_id === 3 ? {borderRadius: 20} : null}>
                    <Text style={[styles.headerText, 
                                this.props.livescore.status_id === 1 ? styles.dateStatus : null,
                                this.props.livescore.status_id === 3 ? styles.restStatus : null]}>
                        {isNaN(+this.props.livescore.status) ? 
                        this.props.livescore.status : 
                        this.props.livescore.status + "'"}
                    </Text>
                </View>
                <View style={styles.scoreContainer}>
                    <Text style={styles.teamName}>{this.props.livescore.score.hostName}</Text>
                    <Image source={{uri:this.props.livescore.score.hostLogo}} style={styles.teamLogo} />

                    <Text style={styles.score}>
                        {this.props.livescore.score.hostScore + ":" +  this.props.livescore.score.guestScore}
                    </Text>

                    <Image source={{uri:this.props.livescore.score.guestLogo}} style={styles.teamLogo} />
                    <Text style={styles.teamName}>{this.props.livescore.score.guestName}</Text>
                </View>
                <View style={styles.tabs}>
                    <TouchableOpacity onPress={() => this.setActiveTab(0)}>
                        <Text style={[styles.tabText, this.state.activeTab === 0 ? styles.activeTab : null]}>LIVESCORE</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => this.setActiveTab(1)}>
                        <Text style={[styles.tabText, this.state.activeTab === 1 ? styles.activeTab : null]}>СЪСТАВИ</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => this.setActiveTab(2)}>
                        <Text style={[styles.tabText, this.state.activeTab === 2 ? styles.activeTab : null]}>БОНУСИ</Text>
                    </TouchableOpacity>
                </View>
                {this.state.activeTab === 0 && <View style={styles.chronoContainer}>
                    {this.props.livescore.livescore.mvpName && <View style={styles.chronoRow}>
                        <View style={styles.statContainer}>
                            <View style={[styles.statInfo, 
                                            styles.mvp, 
                                            !this.props.livescore.livescore.mvpIsHost ? styles.right : null]}>
                                <Text>{this.props.livescore.livescore.mvpName}</Text>
                            </View>
                        </View>
                        <Image source={mvpIcon} style={styles.statIcon} />
                    </View>}

                    {this.props.livescore.status_id >= 2 && 
                    <View style={styles.firstSecondHalfTitle}>
                        <Text  style={styles.firstSecondHalfTitleText}>Първо Полувреме</Text>
                        <Image source={halfTimeIcon} style={styles.statIcon} />
                    </View>}

                    {this.props.livescore.livescore.firstHalfStats.map(stat => this.renderStat(stat))}

                    {this.props.livescore.status_id >= 4 && 
                    <View style={styles.firstSecondHalfTitle}>
                        <Text  style={styles.firstSecondHalfTitleText}>Второ Полувреме</Text>
                        <Image source={halfTimeIcon} style={styles.statIcon} />
                    </View>}

                    {this.props.livescore.livescore.secondHalfStats.map(stat => this.renderStat(stat))}
                </View>}

                {this.state.activeTab === 1 && 
                this.props.livescore.teams &&
                <View style={styles.teamsContainer}>
                    <View style={styles.teamSubView}>
                        {this.props.livescore.teams.hostTeam && this.props.livescore.teams.hostTeam.map((player,i) => (
                            <Text key={i} style={styles.playerName}>{player}</Text>
                        ))}
                    </View>
                    <View style={styles.teamSubView}>
                        {this.props.livescore.teams.guestTeam && this.props.livescore.teams.guestTeam.map((player,i) => (
                            <Text style={[styles.playerName, styles.guestTeamText]} key={i}>{player}</Text>
                        ))}
                    </View>
                </View>
                }

                {this.state.activeTab === 2 && 
                this.props.livescore.playersWithBonuses &&
                <React.Fragment>
                    <View style={{width: '100%'}}>
                        <Text style={styles.bonusText}>Бонуси</Text>
                        <View style={styles.teamsContainer}>
                            <View style={styles.teamSubView}>
                                {this.props.livescore.playersWithBonuses.hostTeam && this.props.livescore.playersWithBonuses.hostTeam.map((player,i) => (
                                    <Text key={i} style={styles.playerName}>
                                        {player.name}
                                        <Text style={styles.playerBonus}> ({player.bonus})</Text>
                                    </Text>
                                ))}
                            </View>
                            <View style={styles.teamSubView}>
                                {this.props.livescore.playersWithBonuses.guestTeam && this.props.livescore.playersWithBonuses.guestTeam.map((player,i) => (
                                    <Text style={[styles.playerName, styles.guestTeamText]} key={i}>
                                        {player.name}
                                        <Text style={styles.playerBonus}> ({player.bonus})</Text>
                                    </Text>
                                ))}
                            </View>
                        </View>
                    </View>
                    {this.props.livescore.playersWithBonusSystem && 
                    <View style={{width: '100%'}}>
                        <Text style={styles.bonusText}>Бонус система</Text>
                        <View style={styles.teamsContainer}>
                            <View style={styles.teamSubView}>
                                {this.props.livescore.playersWithBonusSystem.hostTeam && this.props.livescore.playersWithBonusSystem.hostTeam.map((player,i) => (
                                    <Text key={i} style={styles.playerName}>
                                        {player.name}
                                        <Text style={styles.playerBonus}> ({player.bonus})</Text>
                                    </Text>
                                ))}
                            </View>
                            <View style={styles.teamSubView}>
                                {this.props.livescore.playersWithBonusSystem.guestTeam && this.props.livescore.playersWithBonusSystem.guestTeam.map((player,i) => (
                                    <Text style={[styles.playerName, styles.guestTeamText]} key={i}>
                                        {player.name}
                                        <Text style={styles.playerBonus}> ({player.bonus})</Text>
                                    </Text>
                                ))}
                            </View>
                        </View>
                    </View>}
                </React.Fragment>
                }
            </RefreshContainer>
            <TabBar navigation={this.props.navigation} />
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getLivescore: matchId => dispatch(getLivescore(matchId)),
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    livescore: state.results.livescore,
    roomInfo: state.room.roomInfo
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveScore);

const operator = getDomainOperator();

const design = operator.design;

const styles = EStyleSheet.create({
  headerText: {
      width: '100%',
      fontWeight: 'bold',
      color: 'white',
      textAlign: 'center',
      fontSize: 28,
      marginTop: 110
  },
  dateStatus: {
     fontSize: 22, 
  },
  scoreContainer: {
    width: '95%',
    borderRadius: 15,
    backgroundColor: '#CCD4E1',
    paddingVertical: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20
  },
  restStatus: {
      backgroundColor: '#DBAC38',
      width: 50,
      color: design.textColorPrimary,
      fontWeight:'bold'
  },
  teamName: {
    fontSize: 17,
    width: 50
  },
  teamLogo: {
    width: 40,
    height: 40,
    marginHorizontal: 5
  },
  score: {
    fontWeight: 'bold',
    fontSize: 18,
    marginHorizontal: 20
  },
  tabs: {
      width: '95%',
      flexDirection: 'row',
      marginVertical: 25,
      alignItems: 'center',
      justifyContent: 'space-around'
  },
  tabText: {
      color: 'white',
      fontSize: 17
  },
  activeTab: {
      color: '#D39C49',
  },
  gondAd: {
      width: '95%',
      borderRadius: 15
  }, 
  adImage: {
      width: '100%',
      borderRadius: 15,
      height: 60
  },
  chronoContainer: {      
      width: '95%',
      marginTop: 50
  },
  chronoRow: {      
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      marginVertical: 15
  },
  statContainer: {
      width: '48%',
      alignItems: 'center',
      justifyContent: 'flex-end'
  },
  statInfo: {
      width: '30rem',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 20,
      padding: 7,
      backgroundColor: '#CCD4E1'
  },
  mvp: {
      backgroundColor: '#E4A647',
  },
  statIcon: {
      width: 20,
      height: 20,
      resizeMode: 'contain'
  },
  right: {
      left: '105%'
  },
  firstSecondHalfTitle: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      marginVertical: 10
  },
  firstSecondHalfTitleText: {
      color: 'white',
      fontSize: 18,
      marginVertical: 10
  },
  minute: {
      fontWeight: 'bold',
      marginHorizontal:5,
      fontSize: '2rem'
  },
  substitute:
  {
      width: '100%', 
      textAlign: 'left', 
      fontSize: '1.8rem',
      marginLeft: 5
  },
  goal: {
      backgroundColor: '#72E86C'
  }, 
  ownGoal: {
      backgroundColor: '#B6352E'
  },
  savedPenalty: {
    backgroundColor: '#4588E9'
  },
  missedPenalty: {
    backgroundColor: '#B5352E'
  },
  assistant: {
      marginLeft:5,
      fontStyle:'italic',
      textAlign: 'left',
      width: '100%',
      fontSize: '1.8rem',
  },
  teamsContainer: {
      width: '95%',
      marginTop: 20,
      marginBottom: 50,
      backgroundColor: 'white',
      padding: 5,
      borderRadius: 15,
      flexDirection: 'row'
  },
  teamSubView: {
      width: '50%',
      borderLeftWidth: 1,
      borderColor: '#F0F0F1'
  },
  playerName: {
      fontSize: '3rem',
      marginVertical: 5
  },
  guestTeamText: {
      textAlign: 'right'
  },
  bonusText: {
      width: '100%',
      textAlign: 'center',
      fontSize: 20,
      color: '#C0C8D6',
      marginTop: 30,
      fontWeight: 'bold'
  },
  playerBonus: {
      fontWeight: 'bold'
  }
});