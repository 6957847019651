import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isMobile } from 'react-device-detect';
import { createStyles, maxWidth } from 'react-native-media-queries';
import { SchemeDialog } from '../../Team/components/SchemeDialog';
import {
  TouchableOpacity,
  Image,
  View,
  Text
} from 'react-native';

import PlayerView from './PlayerView';

import { changeScheme } from '../../Team/actions/team'
import transferPlayer from "../../assets/transfers.png";
import * as translations from '../utils/translations';
import { getDomainOperator } from '../utils/domainMapper';

class Playground extends Component {

  changeScheme = (schemeId, teamId) => {
    return this.props.changeScheme(schemeId, teamId);
  }

  render() {
    return (
      <React.Fragment>
        {this.props.isSeasonal && !this.props.schemesDisabled && <View style={styles.dropDownContainer}>
          <SchemeDialog
            disabled={this.props.schemesDisabled}
            userTeam={this.props.userTeam}
            onClose={({ key }) => this.changeScheme(key, this.props.userTeam.teamId)}
            schemes={this.props.userTeam?.allSchemes}
          />
        </View>}
        {this.props.schemesDisabled && this.props.isSeasonal &&
          <TouchableOpacity onPress={() => {    
            this.props.refresh();
         }}
           style={[styles.refreshButtonStyle]}>
             <View style={styles.refreshContainer}>
               <View style={styles.buttonImageContainer}>
               <Image style={[styles.buttonImage]} source={transferPlayer} />
               </View>
               <Text style={styles.buttonText}>{translations.tKey("str_refresh")}</Text>     
             </View>
         </TouchableOpacity>
        }

        {!this.props.isSeasonal && !(localStorage.getItem('addNewTeamDash') || localStorage.getItem('addNewTeam') || localStorage.getItem('addNewTeamHome') || localStorage.getItem('autoFill') || localStorage.getItem('changeTeam') || localStorage.getItem('playerPressed')) &&
          <TouchableOpacity onPress={() => {    
            this.props.refresh();
         }}
           style={[styles.refreshButtonStyle]}>
             <View style={styles.refreshContainer}>
               <View style={styles.buttonImageContainer}>
               <Image style={[styles.buttonImage]} source={transferPlayer} />
               </View>
               <Text style={styles.buttonText}>{translations.tKey("str_refresh")}</Text>
             </View>
         </TouchableOpacity>
        }
        
        
        <View style={[styles.playground, this.props.containerStyle]}>
          <div style={styles.field} />

          {this.props.groups &&
            <View style={styles.playgroundPlayers}>
              {this.props.groups.map((group, index) => {
                return (

                  <View style={styles.groupContainer} key={group.groupId}>
                    {group.players.map((player, i) => {
                      if (player !== null) {
                        return (
                          <PlayerView
                            key={player.playerId || player.id}
                            onPress={() => this.props.onSelectPlayer(player, false)}
                            selected={this.props.selectedPlayers && this.props.selectedPlayers.some(p => p.playerId === player.playerId)}
                            tripleCaptainActivated={this.props.tripleCaptainActivated}
                            doubleViceCaptainActivated={this.props.doubleViceCaptainActivated}
                            showPrice={this.props.showPrice}
                            hidePercent={this.props.hidePercent}
                            showPoints={this.props.showPoints}
                            isReady={this.props.isReady}
                            isSeasonal={this.props.isSeasonal}
                            {...player}
                          />
                        );
                      }
                      if (!this.props.hideNulls) {
                        return (
                          <PlayerView
                            key={Math.random() * i}
                            positionId={group.groupId}
                            onPress={() => this.props.onAddPlayer(i, group)}
                            nullPlayer={true}
                          />
                        );
                      }
                      return null;
                    })}

                  </View>);
              })}
            </View>}
        </View>

        {(this.props.isSeasonal && this.props.reserves?.length > 0) && (
          <React.Fragment>
            <View style={[styles.reserves, this.props.fullTeamActivated ? { backgroundColor: '#2053A4' } : null]}>
              {this.props.reserves.sort((x, y) => x.preferencePosition > y.preferencePosition ? 1 : -1).map(({ preferencePosition, ...player }) => (
                <PlayerView
                  key={player.playerId || player.id}
                  showPrice={this.props.showPrice}
                  isReady={this.props.isReady}
                  firstName={player.firstName}
                  lastName={player.lastName}
                  showPoints={this.props.showPoints}
                  isSeasonal={true}
                  reserves={true}
                  selected={this.props.selectedPlayers && this.props.selectedPlayers.some(p => p.playerId === player.playerId)}
                  tripleCaptainActivated={this.props.tripleCaptainActivated}
                  doubleViceCaptainActivated={this.props.doubleViceCaptainActivated}
                  onPress={() => this.props.onSelectReserve({ preferencePosition, ...player }, this.props.reserves)}
                  preferencePosition={preferencePosition}
                  {...player}
                />
              )
              )}
            </View>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeScheme: (schemeId, teamId) => dispatch(changeScheme(schemeId, teamId)),
  }
}

export default connect(null, mapDispatchToProps)(Playground);

Playground.propTypes = {
  isSeasonal: PropTypes.bool
};

Playground.defaultProps = {
  isSeasonal: false
};

const operator = getDomainOperator();

const design = operator.design;

const base = {
  dropDownContainer: {
    alignItems: 'center',
    marginHorizontal: 20,
    marginVertical: 16
  },
  playground: {
    flex: 1,
    width: '100%',
    alignItems: 'center'
  },
  playgroundPlayers: {
    // marginTop: '10%',
    // marginBottom: '11%',
    width: '100%'
  },
  playgroundImg: {
    position: 'absolute',
    left: 0,
    width: '100%',
    resizeMode: 'stretch',
    height: '100%',
    zIndex: 0
  },
  noPlayers: {
    backgroundColor: 'black',
    width: '100%',
    position: 'absolute',
    top: '47%',
    zIndex: 3,
    paddingVertical: 20,
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.84
  },
  noPlayersText: {
    zIndex: 30,
    color: '#E4A647',
    fontSize: 19
  },
  adsContainer: {
    height: 100,
    width: '90%',
    flexDirection: 'row',
    position: 'absolute',
    top: 60,
    left: 20,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  adImageContainer: {
    width: 120
  },
  adImage: {
    width: 120,
    height: 80,
    resizeMode: 'contain'
  },
  groupContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    height: 90,
    marginBottom: 15
  },
  reserves: {
    height: '130px',
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderColor: '#81C37C',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#41713B'
  },
  banners: {
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: '10%',
    position: 'absolute',
    top: isMobile ? '-5%' : '2%'
  },
  field: {
    backgroundImage: `url(${operator.fieldWithBanners})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: '-1',
    paddingTop: '45px',
    backgroundColor: design.primaryColor
  },
  refreshButtonStyle: {
    // paddingHorizontal: 10,
    // paddingVertical: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 3,
    backgroundColor: design.primaryColor,
    marginTop: 10,
    marginBottom: 30
  },
  refreshContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'center',
    border: "2px solid rgba(255, 255, 255)",
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  buttonImageContainer: {
    //border: "1px solid rgba(255, 255, 255, .2)",
    display: 'flex',
    alignItems: "center",
    justifyContent: 'center',
    padding: 5,
    marginRight: 10
  },
  buttonText: {
      fontWeight: 550,
      fontSize: 14,
      color: "white"
  },
  buttonImage: {
      width: "13px",
      height: "13px"
  }
};

const styles = createStyles(
  base,
  maxWidth(576, {
    field: {
      backgroundImage: `url(${operator.fieldWithBanners})`,
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      width: '100%',
      height: '100%',


      position: 'absolute',
      zIndex: '-1',
      paddingTop: '50px',
      backgroundColor: design.primaryColor
    },
    banners: {
      width: '90%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginLeft: '5%',
      position: 'absolute',
      top: -30
    },
    groupContainer: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'center',
      width: '100%',
      marginBottom: 10
    },
    playgroundPlayers: {
      marginTop: '10%',
      width: '100%',
      paddingBottom: '20px'
    }
  }),
  maxWidth(376, {
    field: {
      backgroundImage: `url(${operator.fieldWithBanners})`,
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      width: '100%',
      height: '100%',
      position: 'absolute',
      zIndex: '-1',
      backgroundColor: design.primaryColor,
      paddingTop: 0
    },
    banners: {
      width: '90%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginLeft: '5%',
      position: 'absolute'
    },
    groupContainer: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'center',
      width: '100%',
      height: 75
    },
    playgroundPlayers: {

      width: '100%',
      paddingBottom: '35px'
    }
  })
);

