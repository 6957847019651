import React, { Component } from 'react';
import { View, Text, Image, TouchableOpacity, ActivityIndicator, TextInput } from 'react-native';
import LoadingScreen from '../../Common/components/LoadingScreen';
import { connect } from 'react-redux';
import { createStyles, maxWidth } from 'react-native-media-queries';
import { BASE_URL } from '../../Common/config/config';
import infoIcon from '../../assets/images/info_purple.svg';
import warning from '../../assets/images/warning_white.svg';
import { formatPlayersName } from '../../Common/utils/utils';
import clock from '../../assets/images/clock_white.svg';
import IosArrowDown from 'react-ionicons/lib/IosArrowDown';
import { getTournamentFixtures } from '../../TournamentDetails/actions/tournamentDetails';
import TournamentFixtures from '../../TournamentDetails/components/TournamentFixtures';
import Playground from '../../Common/components/Playground';
import Timer from '../../Common/components/Timer';
import { autoFillTeam, setTeam, resetTeam, setGroup, editTeam, setRemainingBudget, setTotalBudget } from '../../Team/actions/team';
import { setPlayerCaptain, setPlayerViceCaptain } from '../../Team/actions/players';
import DropDownDialog from '../../HomeTournaments/components/DropDownDialog';
import { PlayerOptionsDialog } from '../components/PlayerOptionsDialog';
import { showConfirmPopup } from '../../ConfirmPopup/actions/confirmPopup';
import {
  enterSubTournament,
  clearTournamentEntered,
  getTournamentForTransfer,
  clearTournamentForTransfer,
  clearTeamEditted,
  restoreState,
  setTeamErrors,
  startLoading,
  stopLoading
} from '../actions/enterTournament';

import * as utils from '../../Common/utils/utils';

import * as translations from '../../Common/utils/translations';
import { logTrace } from '../../Common/utils/logger';
import InformationDialog from '../../HomeTournaments/components/InformationDialog';
import WildCardPopup from "../../Transfers/components/WildCardPopup"
import { useWildCard } from "../../Team/actions/team"
import { isArray } from 'underscore';
import { getDomainOperator } from '../../Common/utils/domainMapper';

const GOALKEPER = 1;
const DEFENDER = 2;
const MIDFIELDER = 3;
const ATTACKER = 4;
const GKP_MIN = 1;
const DEF_MIN = 3;
const MID_MIN = 2;
const FWD_MIN = 1;

class EnterSubTournament extends Component {

  state = {
    totalBudget: 0,
    playersSelected: 0,
    removedPlayersTotalAmount: 0,
    team: [],
    teamId: null,
    capitanFilterOpen: false,
    viceFilterOpen: false,
    selectedPlayer: null,
    prizePoolSeasonalGame: 20000,
    showInfoPalmsBonusDialog: false,
    showWildCardInfoDialog: false,
    infoPalmsBonusDialogContent: translations.tKey('str_demo_description'),
    infoWildcard: translations.tKey('str_demo_description'),
    cost: 0,
    availableChanges: 0,
    wildCardPopupOpen: false
  };

  calculateTotalBudget = (team, tournament) => {
    if (!tournament.is_seasonal_game) {
      return this.setTeamTotalBudget(tournament.team_budget);
    }

    const players = JSON.parse(localStorage.getItem('removedPlayer'))
    let removedPlayersAmount = 0;
    if (players && players.length) {
      players.forEach(player => removedPlayersAmount += player.price)
    }

    let budget = 0;

    budget = team.remainingBudget;

    team.groups.forEach(group => {
      group.players.forEach(player => {
        if (player) {
          budget += player.price;
        }
      });
    });

    return this.setTeamTotalBudget(Math.round((budget + Number.EPSILON) * 100) / 100);
  };

  setTeamRemainingBudget = (budget) => {
    this.props.setRemainingBudget(budget)
  }

  setTeamTotalBudget = (budget) => {
    this.props.setTotalBudget(budget)
  }

  calculateRemainingBudget = (team, tournament) => {
    if (tournament.is_seasonal_game && team.isReady) {
      const players = JSON.parse(localStorage.getItem('removedPlayer'))
      let removedPlayersAmount = 0;

      if (players && players.length) {
        players.forEach(player => removedPlayersAmount += player.price)
      }

      let budget = Math.round((team.remainingBudget + removedPlayersAmount + Number.EPSILON) * 100) / 100
      return this.setTeamRemainingBudget(budget);
    }

    let budget = tournament.team_budget

    team.groups.forEach(group => {
      group.players.forEach(player => {
        if (player) {
          if (tournament.is_seasonal_game) {
            budget -= player.price;
          } else {
            budget -= player.gw_price;
          }
        }
      });
    });

    return this.setTeamRemainingBudget(budget);
  };

  calculateRemainingPlayers = (team, tournament) => {
    var playersSelected = 0;
    team.forEach(group => {
      group.players.forEach(player => {
        if (player) {
          playersSelected += 1;
        }
      });
    });
    this.setState({ playersSelected });
  };

  calculateTransfersAndPenaltyPoints = (oldTeam, newTeam) => {

    if (this.props.userTeam.isWildCardActivated) {
      this.setState({ availableChanges: 0, cost: 0 });
      return;
    }

    let oldPlayers = [];
    let newPlayers = [];
    let changedPlayers = [];

    oldTeam.forEach(group => {
      group.players.forEach(player => {
        oldPlayers.push(player);
      });
    });

    newTeam.groups.forEach(group => {
      group.players.forEach(player => {
        newPlayers.push(player);
      });
    });

    changedPlayers = newPlayers.filter(oldPlayer => !oldPlayers.find(newPlayer => oldPlayer?.playerId === newPlayer?.playerId));
    let cost = 0;
    let availableChanges = newTeam.transfers_available;

    changedPlayers.forEach((player) => {
      if (availableChanges <= 0) {
        cost -= 4;
      }
      if (availableChanges >= 1) {
        availableChanges--;
      }
    });

    this.setState({ availableChanges: availableChanges, cost: cost });

  }

  componentDidMount() {

    localStorage.removeItem('autoFill');

    let tournament = this.props.homeInfo && this.props.homeInfo.find(tournament => tournament.id == this.props.navigation.getParam('id', '0'));
    if (!tournament) {
      tournament = this.props.tournamentForTransfer;
    }

    const name = this.props.userTeam && this.props.userTeam.name ? this.props.userTeam.name : localStorage.getItem('username') ? localStorage.getItem('username') : '';
    this.setState({ teamName: name });

    if (!tournament) {
      if (localStorage.getItem('removedPlayer')) {
        localStorage.removeItem('removedPlayer');
      }

      this.props.getTournamentForTransfer(this.props.navigation.getParam('id', '0'));
      this.props.getTournamentFixtures(this.props.navigation.getParam('id', '0'));
      return;
    } else {
      this.props.getTournamentFixtures(tournament.id);
      if (!this.props.userTeam) {
        this.setClearTeamState();
      } else {
        if (localStorage.getItem('addNewTeam') || localStorage.getItem("addNewTeamHome")) {
          this.setClearTeamState();
          localStorage.removeItem('addNewTeam');
        } else {
          var isAuto = false;
          this.props.userTeam.groups.forEach(group => {
            group.players.forEach(player => {
              if (player) isAuto = true;

              if (player && player.isCaptain) {
                this.setState({ capitan: player });
              }
              if (player && player.isViceCaptain) {
                this.setState({ vice: player });
              }
            });
          });
          let team = this.props.userTeam.groups;
          if (tournament.titulars_limit === team.reduce((accumulator, current) => {
            return accumulator.concat(current.players.filter(t => t != null));
          }, []).length) {
            team = team.map(group => {
              return { ...group, players: group.players.filter(player => player != null) };
            });
          }
          this.setState({ team, teamAutoGenerated: isAuto, isTeamReady: this.isTeamReady(this.props.userTeam) });
        }

        this.calculateTotalBudget(this.props.userTeam, tournament);
        // this.calculateRemainingBudget(this.props.userTeam, tournament);
        this.calculateRemainingPlayers(this.props.userTeam.groups, tournament);
        if (this.props.userTeam.is_seasonal_game) {
          this.calculateTransfersAndPenaltyPoints(JSON.parse(localStorage.getItem('teamBeforeTransfersDone')), this.props.userTeam);
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.props.userTeam) != JSON.stringify(nextProps.userTeam)) {

      let tournament = this.props.homeInfo && this.props.homeInfo.find(tournament => tournament.id == this.props.navigation.getParam('id', '0'));
      let titularLimit = tournament?.titulars_limit;

      if (tournament?.is_seasonal_game) {
        titularLimit += 4;
      }

      if (!tournament) {
        tournament = this.props.tournamentForTransfer;
      }
      if (!tournament) {
        tournament = nextProps.tournamentForTransfer;
      }
      if (!tournament) return;
      let team = nextProps.userTeam.groups;

      if (titularLimit == team.reduce((accumulator, current) => {
        return accumulator.concat(current.players.filter(t => t != null));
      }, []).length) {
        team = team.map(group => {

          return { ...group, players: group.players.filter(player => player != null) };
        });
        team.forEach(group => {
          group.players.forEach(player => {
            if (player && player.isCaptain) {
              this.setState({ capitan: player });
            }
            if (player && player.isViceCaptain) {
              this.setState({ vice: player });
            }
          });
        });
      } else {
        team.forEach((t, index) => {
          switch (index) {
            case 0: {
              while (t.players.length < tournament.goalkeepers_limit) {
                t.players.push(null);
              }
              break;
            }
            case 1: {
              while (t.players.length < tournament.defenders_limit) {
                t.players.push(null);
              }
              break;
            }
            case 2: {
              while (t.players.length < tournament.midfielders_limit) {
                t.players.push(null);
              }
              break;
            }
            case 3: {
              while (t.players.length < tournament.attackers_limit) {
                t.players.push(null);
              }
              break;
            }
          }
        });
      }
      this.setState({ team, isTeamReady: this.isTeamReady(nextProps.userTeam) });

      this.calculateTotalBudget(nextProps.userTeam, tournament);
      if (!tournament.is_seasonal_game) {
        this.calculateRemainingBudget(nextProps.userTeam, tournament);
      }
      this.calculateRemainingPlayers(nextProps.userTeam.groups, tournament);
    }
    if ((!this.props.tournamentEntered && nextProps.tournamentEntered) || (!this.props.teamEditted && nextProps.teamEditted)) {
      this.props.clearTournamentEntered();
      this.props.clearTeamEditted();

      if (!nextProps.teamEditted) {
        localStorage.setItem('addNewTeamDash', true);
      } else {
        localStorage.setItem('editTeamFromGame', JSON.stringify(nextProps.userTeam));
      }

      localStorage.removeItem("addNewTeamHome");
      this.props.navigation.navigate('Dashboard',
        this.props.fixtures?.isSeasonal
          ? null
          : { subId: this.props.navigation.getParam('subId') }
      )
    }
    if (this.props.tournamentForTransfer != nextProps.tournamentForTransfer) {
      if (!this.props.userTeam) {
        this.setClearTeamState();
      } else {
        if (this.props.userTeam.groups && this.props.userTeam.groups.filter(g => g.length == 0).length == 0) {
          this.setClearTeamState();
        }
        if (localStorage.getItem('addNewTeam') || localStorage.getItem("addNewTeamHome")) {
          this.setClearTeamState();
          localStorage.removeItem('addNewTeam');
        }
      }

      this.prepareForChangeTeam(nextProps.tournamentForTransfer);
    }
  }

  prepareForChangeTeam = (tournamentForTransfer) => {
    if (localStorage.getItem('changeTeam')) {
      let tournament = this.props.homeInfo && this.props.homeInfo.find(tournament => tournament.id == this.props.navigation.getParam('id', '0'));
      if (!tournament) {
        tournament = tournamentForTransfer;
      }
      if (!tournament) return;

      var isAuto = false;
      this.props.userTeam.groups.forEach(group => {
        group.players.forEach(player => {
          if (player) isAuto = true;
          if (player && player.isCaptain) {
            this.setState({ capitan: player });
          }
          if (player && player.isViceCaptain) {
            this.setState({ vice: player });
          }
        });
      });
      let team = this.props.userTeam.groups;

      if (tournament.is_seasonal_game && localStorage.getItem('changeTeam')) {
        team.forEach(group => {
          this.props.userTeam.reserves.forEach(player => {
            if (group.groupId == player.positionId) {
              group.players.push(player);
            }
          });

          group.players.forEach((player, index) => {
            if (player && player.isCaptain) {
              this.setState({ capitan: player });
            }
            if (player && player.isViceCaptain) {
              this.setState({ vice: player });
            }

            group.players[index] = {
              ...player,
              // isCaptain: false,
              // isViceCaptain: false
            }
          })

        });
      }

      team.forEach((t, index) => {
        switch (index) {
          case 0: {
            while (t.players.length < tournament.goalkeepers_limit) {
              t.players.push(null);
            }
            break;
          }
          case 1: {
            while (t.players.length < tournament.defenders_limit) {
              t.players.push(null);
            }
            break;
          }
          case 2: {
            while (t.players.length < tournament.midfielders_limit) {
              t.players.push(null);
            }
            break;
          }
          case 3: {
            while (t.players.length < tournament.attackers_limit) {
              t.players.push(null);
            }
            break;
          }
        }
      });
      if (tournament.titulars_limit == team.reduce((accumulator, current) => {
        return accumulator.concat(current.players.filter(t => t != null));
      }, []).length) {
        team = team.map(group => {
          return { ...group, players: group.players.filter(player => player != null) };
        });
      }
      this.setState({ team, teamAutoGenerated: isAuto, isTeamReady: this.isTeamReady(team), cost: 0, availableChanges: this.props.userTeam.transfers_available });
      this.props.setTeam({
        ...this.props.userTeam,
        groups: team
      });

      this.calculateTotalBudget(this.props.userTeam, tournament);
      this.calculateRemainingBudget(this.props.userTeam, tournament);
      this.calculateRemainingPlayers(this.props.userTeam.groups, tournament);
      localStorage.setItem('teamBeforeTransfersDone', JSON.stringify(team));
    }
  };

  autoFillTeam = () => {
    localStorage.setItem('autoFill', true);
    logTrace('Redux team before autofill:', this.props.userTeam);

    let tournament = this.props.homeInfo?.find(tournament => tournament.id == this.props.navigation.getParam('id', '0'));
    if (!tournament) {
      tournament = this.props.tournamentForTransfer;
    }
    if (!tournament) {
      tournament = this.props.homeInfo && this.props.homeInfo.find(tournament => tournament.name == localStorage.getItem("SeasonalGame"));
    }
    if (!tournament) return null;
    const subgame = tournament.subgames.find(subgame => subgame.id == this.props.navigation.getParam('subId', '0'));
    if (!subgame) return null;
    this.props.autoFillTeam({ sub_tournament_id: subgame.id });
    this.setState({ teamAutoGenerated: true, vice: null, capitan: null });

    logTrace('Redux team after autofill:', this.props.userTeam);
    localStorage.removeItem('addNewTeamHome');
  };

  onUseWildCard = () => {
    this.props.useWildCard(this.props.userTeam.teamId);

    this.setState({ availableChanges: 0, cost: 0 });
  }

  setClearTeamState = () => {
    let tournament = this.props.homeInfo && this.props.homeInfo.find(tournament => tournament.id == this.props.navigation.getParam('id', '0'));
    if (!tournament) {
      tournament = this.props.tournamentForTransfer;
    }
    if (!tournament) {
      tournament = this.props.homeInfo && this.props.homeInfo.find(tournament => tournament.name == localStorage.getItem("SeasonalGame"));
    }
    if (!tournament) return;
    var team = [];
    for (var i = 1; i < 5; i++) {
      team.push({
        groupId: i,
        players: []
      });
    }

    team.forEach((t, index) => {
      switch (index) {
        case 0: {
          while (t.players.length < tournament.goalkeepers_limit) {
            t.players.push(null);
          }
          break;
        }
        case 1: {
          while (t.players.length < tournament.defenders_limit) {
            t.players.push(null);
          }
          break;
        }
        case 2: {
          while (t.players.length < tournament.midfielders_limit) {
            t.players.push(null);
          }
          break;
        }
        case 3: {
          while (t.players.length < tournament.attackers_limit) {
            t.players.push(null);
          }
          break;
        }

      }
    });
    this.props.setTeam({ groups: team }, false);
    this.setState({ team, remainingBudget: tournament.team_budget, teamAutoGenerated: false, capitan: null, vice: null });
  };

  calculateProgressRemainingBudget = (tournament) => {
    var percent = (this.props.userTeam.remainingBudget / tournament.team_budget) * 100;
    return {
      width: `${percent}%`,
      height: '100%',
      backgroundColor: '#00F0FD',
    };
  };

  calculateProgressPlayersSelected = (tournament) => {
    let titularLimit = tournament.titulars_limit;

    if (tournament.is_seasonal_game) {
      titularLimit += 4;
    }

    var percent = (this.state.playersSelected / (+titularLimit)) * 100;
    return {
      width: `${percent}%`,
      height: '100%',
      backgroundColor: '#00F0FD',
    };
  };

  showPlayerPopup = (player, isReserve) => {
    this.props.userTeam.groups.forEach(group => {
      group.players.forEach(p => {

        if (p && p.playerId == player.playerId) {
          player.groupId = group.groupId;
        }
      });
    });
    setTimeout(() => this.setState({ selectedPlayer: player, isSelectedReserve: isReserve }), 300);

  };

  hidePlayerPopup = () => {
    this.setState({ selectedPlayer: null });
  };

  addPlayerToTeam = (index, group) => {
    sessionStorage.setItem('currentGroup', JSON.stringify(group));
    let tournament = this.props.homeInfo.find(tournament => tournament.id == this.props.navigation.getParam('id', '0'));
    if (!tournament) {
      tournament = this.props.tournamentForTransfer;
    }
    if (!tournament) return null;
    const subgame = tournament.subgames.find(subgame => subgame.id == this.props.navigation.getParam('subId', '0'));
    if (!subgame) return null;
    this.props.navigation.navigate('SelectPlayers', { id: tournament.id, subId: subgame.id });
  };

  transferPlayer = (player) => {
    const group = this.props.userTeam.groups.find(t => t.players.filter(p => !(!p)).find(p => player.playerId == p.playerId));

    sessionStorage.setItem('currentGroup', JSON.stringify(group));
    sessionStorage.setItem('transfer', JSON.stringify(player));
    let tournament = this.props?.homeInfo?.find(tournament => tournament.id == this.props.navigation.getParam('id', '0'));

    if (!tournament) {
      tournament = this.props.tournamentForTransfer;
    }
    if (!tournament) return null;
    const subgame = tournament.subgames.find(subgame => subgame.id == this.props.navigation.getParam('subId', '0'));
    if (!subgame) return null;

    const price = tournament.is_seasonal_game ? player.price : player.gw_price

    this.setTeamRemainingBudget(this.props.userTeam.remainingBudget + price);
    this.props.navigation.navigate('SelectPlayers', { id: tournament.id, subId: subgame.id });
  };

  openCapitanFilter = () => {
    if(!this.state.playersSelected || this.state.playersSelected == 0) {
      this.props.showConfirmPopup(translations.tKey("str_enter_game_no_players_captain_title"), translations.tKey("str_enter_game_no_players_captain_description"), null);
    } else {
      this.setState({ capitanFilterOpen: true, viceFilterOpen: false });
    }
  };

  closeCapitanFilter = (player) => {

    this.setState({
      capitanFilterOpen: false
    });

    if (player) {
      const { team } = this.state;
      this.setState({
        vice: player.isViceCaptain ? null : this.state.vice,
        team: team.map(chunk => {
          return {
            groupId: chunk.groupId,
            players: chunk.players.map(p => {
              if (!p) return null;
              return {
                ...p,
                isViceCaptain: p.playerId == player.playerId ? false : p.isViceCaptain,
                isCaptain: p.playerId == player.playerId
              };
            })
          }
        }),
        capitan: player
      });
      this.setCaptain(player);
    }
  };

  openViceFilter = () => {
    if(!this.state.playersSelected || this.state.playersSelected == 0) {
      this.props.showConfirmPopup(translations.tKey("str_enter_game_no_players_captain_title"), translations.tKey("str_enter_game_no_players_vice"), null);

    } else {
      this.setState({ capitanFilterOpen: false, viceFilterOpen: true });
    }
  };

  closeViceFilter = (player) => {
    this.setState({ viceFilterOpen: false });

      if (player) {
        const { team } = this.state;
        this.setState({
          capitan: player.isCaptain ? null : this.state.capitan,
          team: team.map(chunk => {
            return {
              groupId: chunk.groupId,
              players: chunk.players.map(p => {
                if (!p) return null;
                return {
                  ...p,
                  isViceCaptain:  p.playerId == player.playerId,
                  isCaptain: p.playerId == player.playerId ? false : p.isCaptain
                };
              })
            }
          }),
          vice: player,
        });
        this.setViceCaptain(player);
      }
  };

  setCaptain = (player) => {
    this.setState({
      capitan: player,
      selectedPlayer: null
    });
    this.props.setPlayerCaptain(player.playerId);
  };

  setViceCaptain = (player) => {
    this.setState({
      vice: player,
      selectedPlayer: null
    });
    this.props.setPlayerViceCaptain(player.playerId);
  };

  removePlayer = (player) => {
    let removedPlayers = JSON.parse(localStorage.getItem('removedPlayer'));

    if (isArray(removedPlayers)) {
      removedPlayers.push(player)
    }
    else {
      removedPlayers = [player]
    }

    localStorage.setItem('removedPlayer', JSON.stringify(removedPlayers));
    let group = this.props.userTeam.groups.find(t => t.groupId == player.groupId);
    const playerIndex = group.players.findIndex(u => u && u.playerId == player.playerId);
    if (playerIndex >= 0) {
      group.players[playerIndex] = null;
      this.props.setGroup(group);
    }
    if (player.isCaptain) {
      this.setState({ capitan: null });
    } else if (player.isViceCaptain) {
      this.setState({ vice: null });
    }
    this.setTeamRemainingBudget(this.props.userTeam.remainingBudget + player.price)
  };

  isTeamReady = (team, homeInfoFromStorage) => {
    logTrace("Starting team validation")

    let errorName = false;
    let errorTeam = false;
    let errorCaptain = false;
    let errorVice = false;

    const homeInfo = homeInfoFromStorage ? homeInfoFromStorage.data : this.props.homeInfo;
    const tournamentId = this.props.navigation.getParam('id', '0');
    let tournament = homeInfo && homeInfo.find(tournament => tournament.id == tournamentId);

    if (!tournament) {
      tournament = this.props.tournamentForTransfer;
    }

    if (!tournament) {
      return false;
    }

    if(!this.state.teamName || this.state.teamName === "") {
      errorName = true;
    }

    if (tournament.active_captains) {
      errorCaptain = true;
    }

    if (tournament.active_vice) {
      errorVice = true;
    }
    team.groups.map(group => {
      group.players.map(player => {
        if (player && player.isCaptain) {
          errorCaptain = false;
        }
        if (player && player.isViceCaptain) {
          errorVice = false;
        }
      });
    });
    var isReady = true;

    let titularLimits = tournament.titulars_limit;

    logTrace("Titular limit", titularLimits);

    // If the game is seasonal and the user is currently creating the
    // team add 4 more players that will be turned into the reserves
    if (tournament.is_seasonal_game) {
      titularLimits += 4;
    }

    logTrace("Titular limit", titularLimits);

    if (+titularLimits >= 11) {
      // check each group and make sure it has at least set amount of players
      team.groups && team.groups.forEach(group => {
        switch (group.groupId) {
          case GOALKEPER: {
            isReady = group.players.filter(player => !(!player)).length >= GKP_MIN;
            if (!isReady) {
              errorTeam = true;
            }
            break;
          }
          case DEFENDER: {
            isReady = group.players.filter(player => !(!player)).length >= DEF_MIN;
            if (!isReady) {
              errorTeam = true;
            }
            break;
          }
          case MIDFIELDER: {
            isReady = group.players.filter(player => !(!player)).length >= MID_MIN;
            if (!isReady) {
              errorTeam = true;
            }
            break;
          }
          case ATTACKER: {
            isReady = group.players.filter(player => !(!player)).length >= FWD_MIN;
            if (!isReady) {
              errorTeam = true;
            }
            break;
          }
        }
      });
    } else {
      if (!(team.groups && team.groups.flatMap(group => group.players).filter(player => !(!player)).length == +titularLimits)) {
        errorTeam = true;
      }
    }

    if (errorName || errorTeam || errorVice || errorCaptain) {
      this.props.setTeamErrors(errorName, errorTeam, errorCaptain, errorVice);
      return false;
    }
    // check if all the players == +tournament.players_per_team_limit
    return team.groups && team.groups.flatMap(group => group.players).filter(player => !(!player)).length == +titularLimits;
  };

  enterSubTournament = (token, homeInfo, teamFromStorage) => {
    if (this.props.userTeam.remainingBudget < 0) {
      this.props.showConfirmPopup(translations.tKey("str_enter_sub_tournament_error"), translations.tKey("str_enter_sub_tournament_error_save_budget"), null);
      return;
    }
    if (this.state.timeEnded && !this.props.userTeam.is_seasonal_game) {
      this.props.showConfirmPopup(translations.tKey("str_enter_sub_tournament_error"), translations.tKey("str_enter_sub_tournament_error_game_started"), null);
    }
    if (!this.isTeamReady(teamFromStorage ? teamFromStorage : this.props.userTeam, homeInfo)) {
      const { errorName, errorCaptain, errorTeam, errorVice } = this.props;
      let errorMessage = translations.tKey("str_enter_sub_tournament_error_enter_game") + "\n" + (errorName ?  translations.tKey("str_enter_sub_tournament_error_name") : "") + "\n" + (errorTeam ? translations.tKey("str_enter_sub_tournament_error_player_count") : '') + "\n" + (errorCaptain ? translations.tKey("str_enter_sub_tournament_error_pick_captain") : '') + "\n" + (errorVice ? translations.tKey("str_enter_sub_tournament_error_pick_vice_captain") : '');
      this.props.showConfirmPopup(translations.tKey("str_error"), errorMessage, null);
      return;
    }
    if (token) {
      if (this.isTeamReady(this.props.userTeam)) {
        this.props.clearTournamentForTransfer();
        let team = this.props.userTeam;

        if (!!this.state.teamId) {
          team.teamId = this.state.teamId;
        }

        team = {
          ...team, groups: team.groups.map(group => {
            return { ...group, players: group.players.filter(player => !(!player)) };
          })
        };
        if (localStorage.getItem('changeTeam')) {
          let dashboard = JSON.parse(localStorage.getItem('Dashboard'));
          if (!team.teamId) {
            team.teamId = dashboard.team.user_team_id;
          }
          
          this.props.editTeam({
            ...team,
            subTournamentId: this.props.navigation.getParam('subId', '0')
          });
        } else {
          this.props.enterSubTournament(team, this.props.navigation.getParam('subId', '0'), this.props.navigation.getParam('teamId', null), this.state.teamName);
        }
      } else {
        const { errorName, errorCaptain, errorTeam, errorVice } = this.props;
        let errorMessage = translations.tKey("str_enter_sub_tournament_error_enter_game") + "\n" + (errorName ?  translations.tKey("str_enter_sub_tournament_error_name") : "") + "\n" + (errorTeam ? translations.tKey("str_enter_sub_tournament_error_player_count") : '') + "\n" + (errorCaptain ? translations.tKey("str_enter_sub_tournament_error_pick_captain") : '') + "\n" + (errorVice ? translations.tKey("str_enter_sub_tournament_error_pick_vice_captain") : '');
        this.props.showConfirmPopup(translations.tKey("str_error"), errorMessage, null);
        }
    } else {
      this.setState({ showInfoPalmsBonusDialog: true });
      // localStorage.setItem('userTeam', JSON.stringify(this.props.userTeam));
      // localStorage.setItem('homeInfo', JSON.stringify(this.props.homeInfoPages));
      // localStorage.setItem('tournameForTransfer', JSON.stringify(this.props.tournamentForTransfer));
      //   id: this.props.navigation.getParam('id'),
      //   subId: this.props.navigation.getParam('subId'),
      //   team: this.props.userTeam
      // }));
    }
  };

  closeInfoDialog = () => {
    this.setState({ showInfoPalmsBonusDialog: false });
    localStorage.setItem('userTeam', JSON.stringify(this.props.userTeam));
    localStorage.setItem('homeInfo', JSON.stringify(this.props.homeInfoPages));
    localStorage.setItem('tournameForTransfer', JSON.stringify(this.props.tournamentForTransfer));
  };

  closeWildCardInfoDialog = () => {
    this.setState({ showWildCardInfoDialog: false });
  };

  renderWildcardBtn = () => {

    let wildCardBtn = <div style={styles.wildCardButton}
      onClick={() => this.setState({ wildCardPopupOpen: true })}>
      <TouchableOpacity>
        <Text style={styles.activeButtonText}>{translations.tKey('str_enter_game_wildcard')}</Text>
      </TouchableOpacity>
    </div>

    if (this.props.userTeam.palms_team_wildcard == 0 && !this.props.userTeam.isWildCardActivated) {
      wildCardBtn = <div style={{ ...styles.inactiveButton, width: '100%' }}>
        <View>
          <Text style={styles.inactiveButtonText}>{translations.tKey('str_enter_game_wildcard_activated')}</Text>
        </View>
      </div>
    }

    if (this.props.userTeam.isWildCardActivated) {
      wildCardBtn = <div style={{ ...styles.enterButton, width: '100%' }}>
        <View>
          <Text style={styles.inactiveButtonText}>{translations.tKey('str_enter_game_wildcard_active')}</Text>
        </View>
      </div>
    }

    return wildCardBtn;
  }


  async redirectIfStuck() {
    setTimeout(() => {
      if(!this.props.isLoading && !this.props.userTeam && !this.props.userTeam?.totalBudget && !this.props.userTeam?.remainingBudget){
        this.props.navigation.navigate('Home');
      } }, 5000);
  }

  render() {
    let isSeasonalStarted = true;
    this.redirectIfStuck();
    if (this.props.isLoading || (!this.props.isLoading && !this.props.userTeam && !this.props.userTeam?.totalBudget && !this.props.userTeam?.remainingBudget)) {
      return <LoadingScreen />;
    }

    if (this.state.team.length == 0) {
      if (localStorage.getItem('setClearTeamState')) {
        this.setClearTeamState();
      }

      return null;
    } else {
      if (localStorage.getItem('setClearTeamState')) {
        localStorage.removeItem('setClearTeamState');
      }
    }

    let tournament = this.props.homeInfo && this.props.homeInfo.find(tournament => tournament.id == this.props.navigation.getParam('id', '0'));
    if (!tournament) {
      tournament = this.props.tournamentForTransfer;
    }
    if (!tournament) return null;
    const subgame = tournament.subgames.find(subgame => subgame.id == this.props.navigation.getParam('subId', '0'));
    if (!subgame) return null;

    let currentTime = new Date();
    // Official Start of the Seasonal Game (Round 8):
    let startDate = new Date('2020-10-02 17:45:00');

    // Check if Seasonal Game is started:
    if (currentTime > startDate) {
      isSeasonalStarted = true;
    }

    return (
      <div style={styles.container}>
        <InformationDialog
          title={translations.tKey('str_demo_title')}
          body={this.state.infoPalmsBonusDialogContent}
          open={this.state.showInfoPalmsBonusDialog}
          onClose={this.closeInfoDialog}
        />

        <InformationDialog
          title={translations.tKey('str_demo_title')}
          body={this.state.infoWildcard}
          open={this.state.showWildCardInfoDialog}
          onClose={this.closeWildCardInfoDialog}
        />

        {this.state.wildCardPopupOpen &&
          <WildCardPopup
            onDismiss={() => this.setState({ wildCardPopupOpen: false })}
            onConfirm={() => {
              this.setState({ wildCardPopupOpen: false });
              this.onUseWildCard();
            }}
          />
        }

        <PlayerOptionsDialog
          setAsCapitan={this.closeCapitanFilter}
          setAsVice={this.closeViceFilter}
          removePlayer={this.removePlayer}
          capitanActive={tournament.active_captains}
          viceActive={tournament.active_vice}
          playerInfo={(player) => this.props.navigation.navigate('PlayerInfoDialog', { id: player.playerId, isSeasonal: tournament.is_seasonal_game })}
          onTransferPlayer={this.transferPlayer}
          player={this.state.selectedPlayer}
          open={this.state.selectedPlayer != null}
          onClose={this.hidePlayerPopup}
          hideSetAsCaptain={this.props.fixtures?.isSeasonal ? true : false}
          hideSetAsVise={this.props.fixtures?.isSeasonal ? true : false}
          mode={'EnterSubTournament'}
          isSeasonal={tournament.is_seasonal_game}
        />

        {this.props.userTeam &&
          <DropDownDialog
            style={styles.rowComponent}
            title={translations.tKey('str_enter_game_choose_captain')}
            selected={this.state.capitan}
            items={this.props.userTeam.groups.flatMap(group => {
              return group.players.map(t => t).filter(player => {
                return !(!player);
              }).map(t => {
                return { ...t, name: formatPlayersName(t, 'firstFormatted') + ', ' + t.team };
              });
            })}
            open={this.state.capitanFilterOpen}
            onClose={this.closeCapitanFilter}
          />}
        {this.props.userTeam && <DropDownDialog
          style={styles.rowComponent}
          title={translations.tKey('str_enter_game_choose_vice')}
          selected={this.state.vice}
          items={this.props.userTeam.groups.flatMap(group => {
            return group.players.map(t => t).filter(player => {
              return !(!player);
            }).map(t => {
              return { ...t, name: formatPlayersName(t, 'firstFormatted') + ', ' + t.team };
            });
          })}
          open={this.state.viceFilterOpen}
          onClose={this.closeViceFilter}
        />}

        <View style={[styles.flagNameRow, styles.rowComponent]}>
          <Image style={styles.image} source={BASE_URL + tournament.flag.url} />
          <Text style={styles.tournamentName}>{tournament.name}</Text>
        </View>

        <View style={[styles.nameInput, styles.rowComponent]}>
          {!localStorage.getItem('changeTeam') ?
            <TextInput style={{ fontSize: 24, border: "1px solid black" }} placeholder="Team Name" defaultValue={this.state.teamName} onChangeText={(teamName) => {
              this.setState({ teamName: teamName })
            }} ></TextInput> :
            <Text style={{ fontSize: 24, height: 30, textAlign: "center" }}>{this.state.teamName}</Text>
          }
        </View>

        <View style={[styles.stickyTop, styles.rowComponent]}>
          <View style={styles.progressBar}>
            <View style={this.calculateProgressRemainingBudget(tournament)} />
            <Text style={styles.progressBarLeftText}>{translations.tKey('str_enter_game_remaining_budget')}</Text>
            <View style={styles.progressBarRightTextContainer}>
              <Text style={[styles.entriesText, styles.whiteText]}>{this.props.userTeam?.remainingBudget?.toFixed(1)}</Text>
              <Text
                style={[styles.entriesTotalText, styles.whiteText]}>{` / ${this.props.userTeam?.totalBudget?.toFixed(1)}M`}</Text>
            </View>
          </View>

          <View style={styles.progressBar}>
            <View style={this.calculateProgressPlayersSelected(tournament)} />
            <Text style={styles.progressBarLeftText}>{translations.tKey('str_enter_game_players_selected')}<div style={{fontSize: 10}}>({translations.tKey('str_enter_game_max_players_same_team')}{tournament.players_per_team_limit})</div></Text>
            <View style={styles.progressBarRightTextContainer}>
              <Text style={[styles.entriesText, styles.whiteText]}>{this.state.playersSelected}</Text>
              <Text style={[styles.entriesTotalText, styles.whiteText]}>{` / ${!!tournament.is_seasonal_game ? tournament.titulars_limit + 4 : tournament.titulars_limit}`}</Text>
            </View>
          </View>
        </View>
      
        <View style={[styles.buttonsRow, styles.rowComponent]}>
          <div style={styles.activeButton}
            onClick={() => this.autoFillTeam()}>
            <TouchableOpacity>
              <Text style={styles.activeButtonText}>{translations.tKey('str_enter_game_autocomplete')}</Text>
            </TouchableOpacity>
          </div>

          <div
            style={
              this.state.teamAutoGenerated
                ? styles.activeButton
                : styles.inactiveButton
            }
            onClick={() => {
              logTrace('TeamId', this.props.userTeam.teamId);
              logTrace('State before clear:', this.state);
              logTrace('Redux team before clear:', this.props.userTeam);

              this.setState({
                teamAutoGenerated: false,
                teamId: this.props.userTeam.teamId
              });

              this.props.resetTeam();
              this.setClearTeamState();

              setTimeout(() => {
                logTrace('State after clear:', this.state);
                logTrace('Redux team after clear:', this.props.userTeam);
              }, 500);
            }}
          >
            <TouchableOpacity disabled={!this.state.teamAutoGenerated}>
              <Text
                style={
                  this.state.teamAutoGenerated
                    ? styles.activeButtonText
                    : styles.inactiveButtonText
                }
              >
                {translations.tKey('str_enter_game_clear_team')}
              </Text>
            </TouchableOpacity>
          </div>
        </View>

        {this.props.isLoading ? (
          <ActivityIndicator size={'large'} color={design.primaryColor} style={{ marginVertical: 20 }} />
        ) : (
            <View style={{ width: '100%' }}>

              <Playground
                hideNulls={this.isTeamReady(this.props.userTeam)}
                onSelectPlayer={this.showPlayerPopup}
                onSelectReserve={(player) => {
                  console.log(player);
                }}
                showPrice={false}
                hidePercent={true}
                onAddPlayer={this.addPlayerToTeam}
                groups={this.state.team}
                isSeasonal={!!this.props.fixtures?.isSeasonal}
                backgroundStyle={{ backgroundColor: design.primaryColor }}
              />
            </View>
        )}

        <View style={styles.purpleContainer}>
          <View style={{...styles.buttonsRow, paddingHorizontal: "0.75rem"}}>
            {tournament.active_captains &&
              <div style={!tournament.active_vice ? styles.pickerButtonWide : styles.pickerButton}
                onClick={() => {
                  this.openCapitanFilter();
                }}>
                <TouchableOpacity>
                  <View style={styles.pickerButtonContainer}>
                    <View style={styles.pickerButtonLeftRow}>
                      <View style={styles.pickerButtonPurpleBadge}>
                        <Text style={styles.pickerButtonPurpleBadgeText}>C</Text>
                      </View>

                      <Text
                        style={styles.pickerButtonText}>{
                          this.state.capitan
                            ? formatPlayersName(this.state.capitan, 'firstFormatted')
                            : translations.tKey('str_enter_game_set_captain')}
                      </Text>
                    </View>
                    <IosArrowDown fontSize="1em" color="#FF4183" style={{ marginRight: '3%' }} />
                  </View>
                </TouchableOpacity>
              </div>}

            {tournament.active_vice &&
              <div style={!tournament.active_captains ? styles.pickerButtonWide : styles.pickerButton}
                onClick={() => {
                  this.openViceFilter();
                }}>
                <TouchableOpacity>
                  <View style={styles.pickerButtonContainer}>
                    <View style={styles.pickerButtonLeftRow}>
                      <View style={styles.pickerButtonPurpleBadge}>
                        <Text style={styles.pickerButtonPurpleBadgeText}>VC</Text>
                      </View>
                      <Text
                        style={styles.pickerButtonText}>{
                          this.state.vice
                            ? formatPlayersName(this.state.vice, 'firstFormatted')
                            : translations.tKey('str_enter_game_set_vice')}
                      </Text>
                    </View>
                    <IosArrowDown fontSize="1em" color="#FF4183" style={{ marginRight: '3%' }} />
                  </View>
                </TouchableOpacity>
              </div>}
          </View>
          <View style={styles.deadlineContainer}>
            <Text style={styles.deadlineText}>{translations.tKey('str_enter_game_deadline')}</Text>
            <View style={styles.timer}>
              <Image style={styles.clockImage} source={clock} />
              <Timer style={styles.timerText} time={tournament.time_left} onTimerEnded={() => {
                this.setState({ timeEnded: true });
              }} />
            </View>

          </View>

        </View>

        <div style={{ ...styles.enterButtonDiv, ...styles.stickyBottom}}
          onClick={() => {

            this.enterSubTournament(this.props.token)
          }}>
          <TouchableOpacity
            style={[styles.enterButton]}>
            <Text
              style={styles.enterButtonText}>{localStorage.getItem('changeTeam') ? translations.tKey('str_save_changes') : subgame.entry_fee ?
                `${translations.tKey('str_action_play')} (${subgame.entry_fee.toFixed(2)}${translations.tKey('str_currency')})`
                : translations.tKey('str_action_play_free')}
            </Text>
          </TouchableOpacity>
        </div>


        <View style={styles.rulesContainer}>
          <Image style={styles.rulesWarningImage} source={warning}></Image>
          <div style={{ color: 'white' }}>{translations.tKey('str_enter_game_this_game')}
            {tournament.stacking_penalty_active || tournament.safety_net_active ? ` ${translations.tKey('str_enter_game_rules')}` : ` ${translations.tKey('str_enter_game_rule')}`}
            {tournament.stacking_penalty_active ? <a style={{ color: 'white' }}
              href={'#'}
              onClick={() => this.props.navigation.navigate('EnterTournamentDetails', {
                id: tournament.id,
                subId: subgame.id,
                tabId: 2
              })}>
              {` ${translations.tKey('str_rules_stacking_penalty')}`}
            </a>
              : ''}
            {tournament.stacking_penalty_active && tournament.safety_net_active ? translations.tKey('str_enter_game_and') : ''}
            {tournament.safety_net_active ?
              <a style={{ color: 'white' }}
                href={'#'}
                onClick={() => this.props.navigation.navigate('EnterTournamentDetails', {
                  id: tournament.id,
                  subId: subgame.id,
                  tabId: 2
                })}>
                {` ${translations.tKey('str_rules_safety_net')}`}
              </a>
              : ''}
            {tournament.stacking_penalty_active || tournament.safety_net_active ? `, ${translations.tKey('str_enter_game_as_with')} ` : ' '}
            {translations.tKey('str_enter_game_standard_rules')}
          </div>
        </View>
        {this.props.fixtures && <Text style={styles.fixturesHeading}>{translations.tKey('str_details_fixtures')}</Text>}
        {(this.props.fixtures?.round) && <Text style={{ ...styles.fixturesText }}>
          Кръг {this.props.fixtures?.round}
        </Text>}
        {this.props.fixtures &&
          <View style={{ marginBottom: 40 }}><TournamentFixtures fixtures={this.props.fixtures.fixtures} /></View>}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getTournamentFixtures: id => dispatch(getTournamentFixtures(id)),
    autoFillTeam: (data) => dispatch(autoFillTeam(data)),
    setTeam: (team, isAuto) => dispatch(setTeam(team, isAuto)),
    resetTeam: () => dispatch(resetTeam()),
    setPlayerCaptain: (playerId) => dispatch(setPlayerCaptain(playerId)),
    setPlayerViceCaptain: (playerId) => dispatch(setPlayerViceCaptain(playerId)),
    setGroup: (group) => dispatch(setGroup(group)),
    setRemainingBudget: (budget) => dispatch(setRemainingBudget(budget)),
    setTotalBudget: (budget) => dispatch(setTotalBudget(budget)),
    enterSubTournament: (team, subId, teamId, teamName) => dispatch(enterSubTournament(team, subId, teamId, teamName)),
    clearTournamentEntered: () => dispatch(clearTournamentEntered()),
    getTournamentForTransfer: id => dispatch(getTournamentForTransfer(id)),
    clearTournamentForTransfer: () => dispatch(clearTournamentForTransfer()),
    editTeam: team => dispatch(editTeam(team)),
    clearTeamEditted: () => dispatch(clearTeamEditted()),
    showConfirmPopup: (title, message, confirmAction) => dispatch(showConfirmPopup(title, message, confirmAction)),
    restoreState: (team, homeInfo, transfer) => dispatch(restoreState(team, homeInfo, transfer)),
    useWildCard: (teamId) => dispatch(useWildCard(teamId)),
    setTeamErrors: (errorName, errorTeam, errorCaptain, errorVice) => dispatch(setTeamErrors(errorName, errorTeam, errorCaptain, errorVice)),
    showLoading: () => dispatch(startLoading()),
    hideLoading: () => dispatch(stopLoading())
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    homeInfo: state.homeTournaments.tournamentsInfo.tournaments,
    homeInfoPages: state.homeTournaments.tournamentsInfoPages,
    fixtures: state.tournamentDetails.fixtures,
    userTeam: state.userTeam.userTeam,
    isLoading: state.ui.isLoading,
    tournamentEntered: state.enterTournament.tournamentEntered,
    tournamentForTransfer: state.enterTournament.tournament,
    teamEditted: state.enterTournament.teamEditted,
    token: state.auth.token,
    errorName: state.enterTournament.errorName,
    errorTeam: state.enterTournament.errorTeam,
    errorCaptain: state.enterTournament.errorCaptain,
    errorVice: state.enterTournament.errorVice,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterSubTournament);

const operator = getDomainOperator();

const design = operator.design;

const base = {
  stickyTop: {
    backgroundColor: 'white',
    position: '-webkit-sticky', /* Safari */
    position: 'sticky',
    top: 0,
    zIndex: '1',
  },
  stickyBottom: {
    backgroundColor: 'white',
    position: '-webkit-sticky', /* Safari */
    position: 'sticky',
    bottom: 0,
    zIndex: '1',
  },
  container: {
    display: 'flex',
    flex: 1,
    width: '100%',
    minHeight: '0vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowComponent: {
    width: '100%',
    paddingHorizontal: '5%',
  },
  disabledButton: {
    opacity: 0.8
  },
  image: {
    width: 50,
    height: 50,
    resizeMode: 'cover',
    borderRadius: 25,
    border: `2px solid ${design.tertiaryColor}`
  },
  flagNameRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '90%',
    marginTop: '20px'
  },
  tournamentName: {
    fontSize: 16,
    fontWeight: 550,
    marginLeft: '10px'
  },
  detailsRow: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px'
  },
  detailsColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  detailsGrayText: {
    fontSize: 14,
    opacity: 0.4
  },
  detailsGreenText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#02894E'
  },
  entriesRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  entriesText: {
    fontSize: 13,
    fontWeight: 'bold'
  },
  entriesTotalText: {
    fontSize: 16,
    opacity: 0.4,
    fontWeight: 'bold'
  },
  grayButtonContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: '20px'
  },
  grayButton: {
    width: '48%',
    paddingTop: '7px',
    paddingBottom: '7px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#EFEFEF',
    borderRadius: 4
  },
  grayOpacity: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#EFEFEF'
  },
  grayButtonText: {
    fontWeight: 550,
    marginLeft: 5
  },
  grayButtonImage: {
    width: 12,
    height: 12,
    resizeMode: 'cover'
  },
  progressBar: {
    width: '100%',
    height: '50px',
    backgroundColor: '#EFEFEF',
    marginBottom: '10px'
  },
  whiteText: {
    fontWeight: 800,
  },
  progressBarLeftText: {
    width: '50%',
    fontWeight: 550,
    fontSize: 16,
    textAlign: 'left',
    position: 'absolute',
    left: 10,
    top: 14
  },
  progressBarRightTextContainer: {
    display: 'flex',
    width: '50%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 10,
    top: 14
  },
  buttonsRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: '10px'
  },
  activeButton: {
    width: '48%',
    paddingTop: '15px',
    paddingBottom: '15px',
    backgroundColor: design.secondaryColor,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointer: 'cursor'
  },
  wildCardButton: {
    width: '100%',
    paddingTop: '15px',
    paddingBottom: '15px',
    backgroundColor: design.secondaryColor,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointer: 'cursor'
  },
  activeButtonText: {
    fontSize: 14,
    fontWeight: 550,
    color: design.textColorPrimary,
  },
  inactiveButtonText: {
    fontSize: 14,
    fontWeight: 550,
    opacity: 0.2
  },
  inactiveButton: {
    width: '48%',
    paddingTop: '15px',
    paddingBottom: '15px',
    backgroundColor: design.textColorDisabled,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  pickerButton: {
    width: '48%',
    backgroundColor: 'white',
  },
  pickerButtonWide: {
    width: '100%',
    backgroundColor: 'white',
  },
  pickerButtonContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${design.primaryColor}`,
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  pickerButtonLeftRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '84%',
    marginLeft: '3%',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  pickerButtonPurpleBadge: {
    backgroundColor: design.primaryColor,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: '4px',
    paddingHorizontal: '5px',
    marginRight: '5px'
  },
  pickerButtonPurpleBadgeText: {
    color: 'white',
    fontSize: 8
  },
  pickerButtonText: {
    fontSize: 14,
    fontWeight: 550
  },
  fixturesHeading: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: '10px',
    marginTop: '40px'
  },
  purpleContainer: {
    backgroundColor: design.primaryColor,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '30px',
    paddingBottom: '20px',
  },
  transfersLeftRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  transfersContainer: {
    display: 'flex',
    width: '48%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #CCCCCC',
    borderRadius: '5px',
    paddingTop: '15px',
    paddingBottom: '15px'
  },
  enterButton: {
    width: '80%',
    backgroundColor: design.secondaryColor,
    paddingTop: '10px',
    paddingBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px'
  },
  enterButtonDiv: {
    marginTop: '20px',
    marginBottom: '20px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  enterButtonText: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  timer: {

    padding: '5px',
    paddingLeft: '7px',
    paddingRight: '7px',
    width: '27%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'row'
  },
  deadlineContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '70%',
    marginTop: '20px'
  },
  deadlineText: {
    fontSize: 14,
    fontWeight: 550,
    color: 'white'
  },
  timerText: {
    fontSize: 16,
    fontWeight: 550,
    color: 'white'
  },
  clockImage: {
    marginTop: '1px',
    width: 13,
    height: 13,
    resizeMode: 'cover',
    marginRight: '4px'
  },
  rulesContainer: {
    width: '94%',
    marginHorizontal: '3%',
    backgroundColor: '#FF085D',
    paddingHorizontal: '5%',
    paddingVertical: '30px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  rulesWarningImage: {
    width: 32,
    height: 29,
    resizeMode: 'contain',
    marginRight: '15px'
  },
  backHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    paddingVertical: '20px'
  },
  backImage: {
    width: 21,
    height: 14,
    resizeMode: 'contain',
    marginRight: '15px'
  },
  backText: {
    fontSize: 20,
    fontWeight: 550
  },
  freeTransfers: {
    fontWeight: 'bold',
    marginLeft: 3
  },
  cost: {
    fontWeight: 'bold',
    marginLeft: 3
  },
  costRed: {
    color: '#FF2771'
  },
  nameInput: {
    margin: 16,
  }
};

const styles = createStyles(
  base,
  maxWidth(576, {
    pickerButtonText: {
      fontSize: 12,
      fontWeight: 550
    }
  }),
  maxWidth(376, {
    pickerButtonText: {
      fontSize: 10,
      fontWeight: 550
    },
    entriesText: {
      fontSize: 13,
      fontWeight: 'bold'
    },
    entriesTotalText: {
      fontSize: 13,
      opacity: 0.4,
      fontWeight: 'bold'
    }
  })
);
