import {
  SET_TEAM_PLAYER_SHORT_INFO,
  SET_CAPTAIN,
  SET_VICE_CAPTAIN,
  SET_TEAM_PLAYER_FULL_INFO,
  SET_TEAM_PLAYER_STAT_FOR_ROUND
} from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';
import { showConfirmPopup } from "../../ConfirmPopup/actions/confirmPopup"
import * as translations from '../../Common/utils/translations'

export const getUserStatsForRound = (playerId, roundId, forPoints) => {
  return dispatch => {
    dispatch(authGetToken())
      .catch(err => {
        console.log('No auth token exists!');
      })
      .then(token => {
        const typeOfPlayer = forPoints ? 'teamplayer' : 'player';
        return fetch(BASE_URL + '/users/team/' + typeOfPlayer + '/' + playerId + '/stats/round/' + roundId, {
          credentials: 'include',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          }
        });
      })
      .catch(err => console.log(err))
      .then(res => res.json())
      .then(res => {
        if (res.has_error) {
          dispatch(showConfirmPopup(translations.tKey("str_error"), res.message, null));
        } else {
          if (!res.data.image) {
            res.data.image = BASE_URL + "/uploads/nologo.png";
          } else {
            res.data.image = BASE_URL + res.data.image;
          }
          res.data.guestLogo = BASE_URL + res.data.guestLogo;
          res.data.hostLogo = BASE_URL + res.data.hostLogo;

          dispatch(setPlayerStatForRound(res.data));
        }
      });
  };
}

export const setPlayerStatForRound = playerInfo => {
  return {
    type: SET_TEAM_PLAYER_STAT_FOR_ROUND,
    playerInfo
  };
};

export const getUserFullProfile = playerId => {
  return dispatch => {
    dispatch(authGetToken())
      .catch(err => {
        console.log('No auth token exists!');
      })
      .then(token => {
        return fetch(BASE_URL + '/users/team/player/' + playerId + '/profile', {
          credentials: 'include',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          }
        });
      })
      .catch(err => console.log(err))
      .then(res => res.json())
      .then(res => {
        if (res.has_error) {
          dispatch(showConfirmPopup(translations.tKey("str_error"), res.message, null));
        } else {
          if (!res.data.image) {
            res.data.image = BASE_URL + "/uploads/nologo.png";
          } else {
            res.data.image = BASE_URL + res.data.image;
          }
          if (Array.isArray(res.data.rounds)) {
            res.data.rounds.forEach(round => {
              round.guestLogo = BASE_URL + round.guestLogo;
              round.hostLogo = BASE_URL + round.hostLogo;
            });
          }
          dispatch(setPlayerFullInfo(res.data))
        }
      });
  };
}

export const setPlayerFullInfo = playerInfo => {
  return {
    type: SET_TEAM_PLAYER_FULL_INFO,
    playerInfo
  };
};


export const getPlayerInfoWithChanges = playerId => {
  return dispatch => {
    dispatch(authGetToken())
      .catch(err => {
        console.log('No auth token exists!');
      })
      .then(token => {
        return fetch(BASE_URL + '/users/team/player/' + playerId + '/info', {
          credentials: 'include',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          }
        });
      })
      .catch(err => console.log(err))
      .then(res => res.json())
      .then(res => {
        if (res.has_error) {
          dispatch(showConfirmPopup(translations.tKey("str_error"), res.message, null));
        } else {
          res.data.playerId = playerId;
          if (!res.data.image) {
            res.data.image = BASE_URL + "/uploads/nologo.png";
          } else {
            res.data.image = BASE_URL + res.data.image;
          }
          if (Array.isArray(res.data.possibleChanges)) {
            res.data.possibleChanges.forEach(change => {
              change.iconUrl = BASE_URL + change.iconUrl;
              change.playerId = change.id;
            });
          }
          if (Array.isArray(res.data.previousMatches)) {
            res.data.previousMatches.forEach(match => {
              match.teamLogo = BASE_URL + match.teamLogo;
            });
          }

          if (Array.isArray(res.data.nextMatches)) {
            res.data.nextMatches.forEach(match => {
              match.teamLogo = BASE_URL + match.teamLogo;
            })
          }
          dispatch(setPlayerShortInfo(res.data));
        }
      });
  };
}

export const setPlayerShortInfo = playerInfo => {
  return {
    type: SET_TEAM_PLAYER_SHORT_INFO,
    playerInfo
  };
};


export const setCaptain = (id, playerId, teamId) => {
  return dispatch => {
    dispatch(authGetToken())
      .catch(err => {
        console.log('No auth token exists!');
      })
      .then(token => {
        return fetch(BASE_URL + '/users/team/player/' + id + '/captain', {
          credentials: 'include',
          method: 'PUT',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          },
          body: JSON.stringify({
            user_team_id: teamId
          })
        });
      })
      .catch(err => console.log(err))
      .then(res => res.json())
      .then(res => {
        if (res.has_error) {
          dispatch(showConfirmPopup(translations.tKey("str_error"), res.message, null));
        } else {
          dispatch(setPlayerCaptain(playerId));
        }
        //TODO: dispatch(getTeamForTransfers());
      });
  };
}

export const setPlayerCaptain = playerId => {
  return {
    type: SET_CAPTAIN,
    playerId
  };
};


export const setViceCaptain = (id, playerId, teamId) => {
  return dispatch => {
    dispatch(authGetToken())
      .catch(err => {
        console.log('No auth token exists!');
      })
      .then(token => {
        return fetch(BASE_URL + '/users/team/player/' + id + '/vice', {
          credentials: 'include',
          method: 'PUT',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          },
          body: JSON.stringify({
            user_team_id: teamId
          })
        });
      })
      .catch(err => console.log(err))
      .then(res => res.json())
      .then(res => {
        if (res.has_error) {
          dispatch(showConfirmPopup(translations.tKey("str_error"), res.message, null));
        } else {
          dispatch(setPlayerViceCaptain(playerId));
        }
        //TODO: dispatch(getTeamForTransfers());
      });
  };
}

export const setPlayerViceCaptain = playerId => {
  return {
    type: SET_VICE_CAPTAIN,
    playerId
  };
};