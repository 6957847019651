import React from "react";
import { View, StyleSheet } from "react-native";
import LoadingScreen from '../Common/components/LoadingScreen';
import { connect } from 'react-redux';

const WelcomeScreen = ({ navigation, token, hasRooms }) => {
  if (!token) return <LoadingScreen />;
  navigation.navigate("Home")
  if (hasRooms) {
    navigation.navigate("Home");
    return null;
  }

  return (
    <View style={styles.container}>
     
    </View>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    token: state.auth.token,
    hasRooms: state.auth.hasRooms
  }
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    width: '60%',
    height: 110,
    resizeMode: 'contain'
  },
  playButton: {
    marginTop: 30,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    width: '70%',
    backgroundColor: '#72E86C',
    paddingVertical: 20,
    paddingHorizontal: 10
  },
  buttonText: {
    fontWeight: 'bold',
    fontSize: 22
  },
  subText: {
    marginVertical: 7,
    color: '#A3A5A9',
    fontSize: 22,
    textAlign: 'center'
  },
  emphasis: {
    marginVertical: 7,
    color: '#C5A150',
    fontSize: 22,
    textAlign: 'center',
    width: '70%'
  },
  mainText: {
    marginVertical: 7,
    color: 'white',
    fontSize: 36,
    textAlign: 'center'
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeScreen);
