export class Operator{
    constructor(
        title,
        language,
        footer,
        favicon,
        tracking,
        design,
        login,
        baseLogin,
        fullLogin,
        logo,
        loading,
        winners,
        winnersURL,
        rules,
        getStarted,
        unsubscribe,
        background,
        fieldWithBanners,
        whiteLabel,
        subscription,
        rewards,
        ){
        this.title = title;
        this.language = language;
        this.footer = footer;
        this.favicon = favicon;
        this.tracking = tracking;
        this.design = design;
        this.login = login;
        this.baseLogin = baseLogin;
        this.fullLogin = fullLogin;
        this.logo = logo;
        this.loading = loading;
        this.winners = winners;
        this.winnersURL = winnersURL;
        this.rules = rules;
        this.getStarted = getStarted;
        this.unsubscribe = unsubscribe;
        this.background = background;
        this.fieldWithBanners = fieldWithBanners;
        this.whiteLabel = whiteLabel;
        this.subscription = subscription;
        this.rewards = rewards;
    }
}