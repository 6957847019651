import { format } from 'date-fns';

export const getDateInCurrentTimezone = (date) => {
    const bgZonedDate = new Date(date);

    const utcDate = bgZonedDate.toISOString();

    return new Date(utcDate);
}

export const getTimeLeftInSeconds = (date) => {
    const dateInCurrentTimezone = getDateInCurrentTimezone(date);
    const now = new Date();

    const timeLeft = (dateInCurrentTimezone.getTime() - now.getTime()) / 1000;

    return timeLeft
}

export const getDateAndTime = (datetime) => {
    const dateInCurrentTimezone = getDateInCurrentTimezone(datetime);
    const date = format(dateInCurrentTimezone, 'dd MMM');
    const time = format(dateInCurrentTimezone, 'kk:mm');
    return { date, time };
}