import React, { Component } from "react";
import { StyleSheet, View, TouchableOpacity, Text } from 'react-native';
import { connect } from 'react-redux';
import { loginOrRegister } from '../../Welcome/actions/auth';
import { showConfirmPopup } from "../../ConfirmPopup/actions/confirmPopup";
import * as translations from '../../Common/utils/translations';
import * as utils from '../utils/utils';
import { getDomainOperator } from "../utils/domainMapper";

const buttonStyle = {
    width: '33.333%',
    border: "0.5px solid rgba(255,255,255, 0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}

class Header extends Component {

    state = {
        showDemoDialog: false,
    }

    componentDidMount() {
        if (!this.props.token) {
            this.loginInternal();
        }

        // Retrieve the current location and set the selected button
        const path = window.location.pathname.split('/')[1];
        switch (path) {
            case ('home'):
                this.state.selectedId != 1 && this.changeStateTo(1);
                break;
            case ('dashboard'):
                this.state.selectedId != 2 && this.changeStateTo(2);
                break;
            case ('support'):
                this.state.selectedId != 3 && this.changeStateTo(3);
                break;
            default:
                break;
        }
    }

    componentWillReceiveProps(nextProps, nextState) {
        if (nextProps.token && !this.props.token && localStorage.getItem("DashboardPending")) {
            const subIdObj = JSON.parse(localStorage.getItem("DashboardPending"));
            localStorage.removeItem("DashboardPending");
            if (subIdObj.subId) {
                this.props.navigation.navigate("Dashboard", { subId: subIdObj.subId });
            } else {
                this.props.navigation.navigate("Dashboard");
            }
        }
    }

    loginInternal = () => {
        let params = new URLSearchParams(window.location.search);

        const [publicId, host, lang, api] = utils.getHostParams(params);

        this.props.loginOrRegister(publicId, host, lang, api);
    }

    changeStateTo = (id) => {
        switch (id) {
            case 1: {
                localStorage.removeItem("Dashboard");
                this.props.navigation.navigate("Home");
                break;
            }
            case 2: {
                localStorage.removeItem("Dashboard");
                localStorage.removeItem("addNewTeamHome");
                if (this.props.token) {
                    this.props.navigation.navigate("Dashboard")
                } else {
                    localStorage.setItem("DashboardPending", true);
                }
                break;
            }
            case 3: {
                localStorage.removeItem("Dashboard");
                localStorage.removeItem("addNewTeamHome");
                this.props.navigation.navigate("Support")
                break;
            }
        }
        localStorage.setItem("headerId", id)
        this.setState({ selectedId: id })
    }

    render() {
        const buttonId = localStorage.getItem("headerId")
        return (
          <View>
            <View style={styles.container}>
                <div style={buttonStyle} onClick={() => this.changeStateTo(1)}>
                    <TouchableOpacity style={styles.buttonOpacity}>
                        <Text style={buttonId == 1 ? styles.buttonTextSelected : styles.buttonText}>{translations.tKey('str_header_button_home')}</Text>
                    </TouchableOpacity>
                </div>
                <div style={buttonStyle} onClick={() => this.changeStateTo(2)}>
                    <TouchableOpacity style={styles.buttonOpacity}>
                        <Text style={buttonId == 2 ? styles.buttonTextSelected : styles.buttonText}>{translations.tKey('str_header_button_my_games')}</Text>
                    </TouchableOpacity>
                </div>
                <div style={buttonStyle} onClick={() => this.changeStateTo(3)}>
                    <TouchableOpacity style={styles.buttonOpacity}>
                        <Text style={buttonId == 3 ? styles.buttonTextSelected : styles.buttonText}>{translations.tKey('str_header_button_more')}</Text>
                    </TouchableOpacity>
                </div>
            </View>
            {
                React.cloneElement(this.props.element, {
                    navigation: this.props.navigation
                })
            }
        </View>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        loginOrRegister: (publicId, host, lang, api) => {
            dispatch(loginOrRegister({ session_id: publicId, host: host, lang: lang, api: api }))
        },
        showConfirmPopup: (title, message, confirmAction) => dispatch(showConfirmPopup(title, message, confirmAction))
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        token: state.auth.token
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

const operator = getDomainOperator();

const design = operator.design;

const styles = StyleSheet.create({
    container: {
        width: '100%',
        backgroundColor: design.primaryColor,
        display: 'flex',
        flexDirection: "row",
        alignItems: "center",
        justifyContent: 'center',
        zIndex: 3
    },
    buttonOpacity: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: "row",
        alignItems: "center",
        justifyContent: 'center',
        cursor: "pointer",
        paddingVertical: 20
    },
    buttonTextSelected: {
        color: design.secondaryColor,
        fontSize: 14,
        fontWeight: 550
    },
    buttonText: {
        color: design.textColorDisabled,
        fontSize: 14,
        fontWeight: 550
    }
});