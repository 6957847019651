import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import { Text, ActivityIndicator } from 'react-native';
import { createStyles } from 'react-native-media-queries';
import TournamentFixtures from '../../TournamentDetails/components/TournamentFixtures';
import { connect } from 'react-redux';
import {
  getTournamentFixtures,
  clearFixtures
} from '../../TournamentDetails/actions/tournamentDetails';
import { getDomainOperator } from '../../Common/utils/domainMapper';

const operator = getDomainOperator();

const design = operator.design;

const base = {
  title: {
    backgroundColor: design.primaryColor,
    height: '64px',
    width: '90%',
    maxWidth: '400px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: 9999
  },
  titleText: {
    color: 'white',
    fontSize: 20,
    fontWeight: 550
  },
  exitButton: {
    backgroundColor: '#FF085D',
    width: 22,
    height: 22,
    position: 'absolute',
    paddingBottom: 5,
    top: 1,
    right: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999,
    cursor: 'pointer'
  },
  exitButtonText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 15
  }
};

class FixturesDialog extends React.Component {

  render() {

    return (
      <Dialog
        onClose={() => {
          this.props.clearFixtures();
          this.props.onClose();
        }}
        aria-labelledby="dialog-title"
        open={this.props.open}

        PaperProps={{
          style: {
            width: '90%',
            maxWidth: '400px',
            overflow: 'hidden'
          }
        }}

        style={{
          maxHeight: 500
        }}
      >
        <div style={styles.title}>
          <div
            style={styles.exitButton}
            onClick={() => {
              this.props.clearFixtures();
              this.props.onClose();
            }}
          >
            <Text style={styles.exitButtonText}>x</Text>
          </div>

          <Text style={styles.titleText}>{this.props.title ? this.props.title : ''}</Text>
        </div>
        
        <div
          style={{
            marginTop: 64,
            minWidth: '200px',
            minHeight: '100px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          {!this.props.fixtures 
            ?  (
              <ActivityIndicator size={'large'} color={design.primaryColor}/>
            ) : (
              <TournamentFixtures fixtures={this.props.fixtures.fixtures} maxHeight={380} /> 
            )
          }
        </div>
      </Dialog>
    );
  }
}


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getTournamentFixtures: id => dispatch(getTournamentFixtures(id)),
    clearFixtures: () => dispatch(clearFixtures())
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    fixtures: state.tournamentDetails.fixtures
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FixturesDialog);

const styles = createStyles(base);