import React from 'react';
import { Text, TouchableOpacity, ActivityIndicator } from 'react-native';
import { connect } from 'react-redux';
import {
  getTournamentRules
} from '../../TournamentDetails/actions/tournamentDetails';
import { getSubTournamentDetails } from '../actions/enterTournament';
import { createStyles, maxWidth } from 'react-native-media-queries';
import TournamentRules from '../../TournamentDetails/components/TournamentRules';
import SubtournamentOverview from './SubtournamentOverview';
import * as translations from '../../Common/utils/translations';
import { getDomainOperator } from '../../Common/utils/domainMapper';

export const OVERVIEW = 1;
export const RULES = 2;

class EnterTournamentDetails extends React.Component {

  state = {
    selectedSubgame: null,
    selectedId: 1
  };

  componentDidMount() {
    const state = JSON.parse(localStorage.getItem('TournamentDetails'));
    if (state) {
      this.setState(state);
      localStorage.removeItem('TournamentDetails');
    } else {
      if (this.props.navigation.getParam('tabId') > 0) {
        this.setState({selectedId: +this.props.navigation.getParam('tabId')});
        this.props.getTournamentRules(this.props.navigation.getParam('id', '0'));
      } else {
        this.props.getSubTournamentDetails(this.props.navigation.getParam('subId', '0'));
      }
    }
  }

  getButtonComponent = (text, id) => {

    return <TouchableOpacity
      onPress={() => {
        switch (id) {
          case OVERVIEW: {
            this.props.getSubTournamentDetails(this.props.navigation.getParam('subId', '0'));
            break;
          }
          case RULES: {
            this.props.getTournamentRules(this.props.navigation.getParam('id', '0'), true);
            break;
          }
          default: {
            break;
          }
        }
        this.setState({selectedId: id});
      }}
      style={[id === this.state.selectedId ? styles.selectedTab : styles.nonSelectedTab, styles.tabDimentions]}>
      <Text style={id === this.state.selectedId ? styles.selectedTabText : styles.nonSelectedTabText}>{text}</Text>
    </TouchableOpacity>;
  };

  getContentComponent = () => {
    switch (this.state.selectedId) {
      case OVERVIEW: {
        if (this.props.isLoading) return <ActivityIndicator size={'large'} color={design.primaryColor}/>;
        return <SubtournamentOverview overView={this.props.overView}/>;
      }
      case RULES: {
        if (!this.props.rules || this.props.isLoading) return <ActivityIndicator size={'large'} color={design.primaryColor}/>;
        return <TournamentRules
          onScoringClicked={(id) => {
            //put state in localstorage
            localStorage.setItem('TournamentDetails', JSON.stringify(this.state));
            this.props.navigation.navigate('TournamentScoring', {id: this.props.navigation.getParam('subId', '0')});
          }
          }
          navigation={this.props.navigation}
          rules={this.props.rules.subgames.find(t => t.id == this.props.navigation.getParam('subId', '0')).rules}/>;
      }
      default:
        return null;
    }
  };

  render() {
    const {navigation } = this.props;

    return (
      <div style={styles.container}>
        <div style={styles.purpleHeader}>
          <TouchableOpacity
            style={styles.exitButton}
            onPress={() => {
              navigation.goBack();

              if (window.location.href.endsWith('#')) {
                navigation.goBack();
              }
            }}
          >
            <Text style={{color: 'white', fontWeight: 'bold', fontSize: 15}}>x</Text>
          </TouchableOpacity>

          <div style={styles.headerContainer}>
            <Text style={styles.headerText}>{translations.tKey('str_details_overview')}</Text>
            <div style={styles.headerButtonsContainer}/>
          </div>
        </div>

        <div style={styles.mainContainer}>
          <div style={styles.tabsContainer}>
            {this.getButtonComponent(translations.tKey('str_details_overview'), OVERVIEW)}
            {this.getButtonComponent(translations.tKey('str_details_rules'), RULES)}
          </div>
          <div style={{...styles.mainContentDimensions, ...styles.mainContent}}>
            {this.getContentComponent()}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getTournamentRules: (id, shouldKeepFixtures) => dispatch(getTournamentRules(id, shouldKeepFixtures)),
    getSubTournamentDetails: id => dispatch(getSubTournamentDetails(id))
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    overView: state.enterTournament.details,
    rules: state.tournamentDetails.rules,
    isLoading: state.ui.isLoading
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterTournamentDetails);

const operator = getDomainOperator();

const design = operator.design;

const base = {

  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  purpleHeader: {
    position: 'relative',
    width: '100%',
    height: '150px',
    backgroundColor: design.primaryColor,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%'
  },
  image: {
    width: 50,
    height: 50,
    resizeMode: 'cover',
    borderRadius: 25,
    border: `2px solid ${design.tertiaryColor}`,
    marginBottom: '10px'
  },
  headerText: {
    color: 'white',
    fontSize: 17,
    fontWeight: 550
  },
  headerButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    margin: '10px 0',
    width: '100%'
  },
  headerButtonSizeLarge: {
    width: '40%'
  },
  headerButtonSizeMedium: {
    width: '48.5%'
  },
  headerButtonSizeSmall: {
    width: '32.5%'
  },
  headerPressedButton: {
    backgroundColor: 'white',
    borderRadius: '5px',
    padding: '7px 0',
    textAlign: 'center'
  },
  headerPressedButtonText: {
    color: design.primaryColor,

    fontWeight: 'bold'
  },
  headerNonPressedButtonText: {
    color: 'white'
  },
  headerNonPressedButton: {
    backgroundColor: '#552659',
    borderRadius: '5px',
    padding: '7px 0',
    textAlign: 'center'
  },
  mainContainer: {
    width: '90%',
    minHeight: '600px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px'
  },
  tabsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  tabDimentions: {
    width: 'calc(50% - 5px)',
    padding: '10px',
    borderRadius: '5px',
    textAlign: 'center'
  },
  selectedTab: {
    backgroundColor: '#963CFF'
  },
  selectedTabText: {
    color: 'white',
    fontWeight: 550
  },
  nonSelectedTab: {
    backgroundColor: '#EFEFEF',
    fontWeight: 550,
    fontSize: 14
  },
  nonSelectedTabText: {
    color: '#B6B6B6',
    fontSize: 14
  },
  playButton: {
    width: '100%',
    backgroundColor: design.secondaryColor,
    paddingTop: '10px',
    paddingBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px'
  },
  playButtonText: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  mainContentDimensions: {
    width: '100%'
  },
  mainContent: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'column'
  },
  exitButton: {
    backgroundColor: '#FF085D',
    width: 22,
    height: 22,
    position: 'absolute',
    paddingBottom: 5,
    top: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999
  }
};

const styles = createStyles(
  base,
  maxWidth(576, {
    playButtonText: {
      fontSize: 11
    }
  }),
  maxWidth(376, {
    selectedTabText: {
      fontSize: 10
    },
    nonSelectedTabText: {
      fontSize: 10
    },
    playButtonText: {
      fontSize: 12
    }
  })
);

