import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import { BASE_URL } from '../../Common/config/config';
import hotBadge from '../../assets/images/pink_badge.svg';
import clock from '../../assets/images/clock_black.svg';
import { connect } from 'react-redux';
import Timer from '../../Common/components/Timer';
import { createStyles, maxWidth } from 'react-native-media-queries';

import { numberWithCommas } from '../../Common/utils/utils';

import {
  getTournamentFixtures,
  clearFixtures
} from '../../TournamentDetails/actions/tournamentDetails';
import * as translations from '../../Common/utils/translations';
import IcPrizepool from "../../Common/components/Icons/IcPrizepool";
import { isMobile } from 'react-device-detect';
import { getDomainOperator } from '../../Common/utils/domainMapper';
import { getTimeLeftInSeconds } from '../../Common/utils/ZonedTimeService';

class TournamentCard extends React.Component {

  state = {
    openFixturesDialog: false
  };

  calculateProgressBarStyle = (subgame) => {

    var percentFilled = this.calculatePercent(subgame);

    const style = {};
    style.width = `${percentFilled}%`;
    style.backgroundColor = this.calculaterRGB(percentFilled / 100);
    style.height = '5px';
    style.borderRadius = '2px';
    return style;
  };

  calculateSeasonalProgressBarStyle = (tournament, subgame) => {

    var percentFilled = this.calculateSeasonalPercent(tournament, subgame);
    const style = {};
    style.width = `${percentFilled}%`;
    style.backgroundColor = this.calculaterRGB(percentFilled / 100);
    style.height = '5px';
    style.borderRadius = '2px';
    return style;
  };

  calculatePercent = (subgame) => {
    var percentFilled = null;
    if (!subgame.current_players) {
      percentFilled = 0;
    } else {
      percentFilled = (subgame.current_players / subgame.max_players) * 100;
    }
    return percentFilled;
  };

  calculateSeasonalPercent = (tournament, subgame) => {
    var percentFilled = null;
 
    percentFilled = (tournament.current_seasonal_palms_game_entries / subgame.max_players) * 100;
    return percentFilled;
  };

  calculateRemainingPlayersStyle = (subgame) => {

    const style = {};
    style.color = this.calculaterRGB(this.calculatePercent(subgame) / 100);
    return style;
  };

  calculaterRGB = (pct) => {

    const minR = 55;
    const maxR = 255;
    const minG = 0;
    const maxG = 8;
    const minB = 60;
    const maxB = 93;
    const targetR = minR + ((maxR - minR) * pct);
    const targetG = minG + ((maxG - minG) * pct);
    const targetB = minB + ((maxB - minB) * pct);
    return 'rgb(' + [targetR, targetG, targetB].join(',') + ')';
  };

  calculateRemainingPlayers = (subgame) => {
    var returnString = '';

    if (!subgame.current_players) {
      returnString += `${subgame.max_players}`;
    } else {
      returnString += `${subgame.max_players - subgame.current_players}`;
    }
    if (this.props.tournament.subgames.length <= 2) {
      returnString += translations.tKey('str_tcard_places_left');
    }
    return returnString;
  };

  calculateSeasonalRemainingPlayers = (tournament,subgame) => {
    var returnString = '';
    
      returnString += `${subgame.max_players - tournament.current_seasonal_palms_game_entries}`;
    
    if (this.props.tournament.subgames.length <= 2) {
      returnString += translations.tKey('str_tcard_places_left');
    }
    return returnString;
  };

  openFixturesDialog = () => {
    this.props.getTournamentFixtures(this.props.tournament.id);
    this.props.onFixturesOpen(this.props.tournament.id);
  };

  hasLoginToProvider = () => {
      let publicId;
      let params = new URLSearchParams(window.location.search);

      if (params.get('public_id')) {
        publicId = params.get('public_id');
        window.localStorage.setItem('publicId', publicId);
      } else {
        publicId = window.localStorage.getItem('publicId');
      }
      return publicId;
  }

  render() {
    const {navigation, tournament} = this.props;

    if (!tournament) return null;
    var subGameColumnStyle = null;
    //TODO: Handle this hacky solution from the API, remove the unnecessary subgames
    if(tournament.subgames.length > 1) {
      tournament.subgames = tournament.subgames.slice(0, 1);
    }
    if (tournament.subgames.length == 1) {
      subGameColumnStyle = styles.subGameColumnBig;
    } else if (tournament.subgames.length == 2) {
      subGameColumnStyle = styles.subGameColumnMedium;
    } else if (tournament.subgames.length == 3) {
      subGameColumnStyle = styles.subGameColumnSmall;
    }
    
    // tournament.team_budget = +tournament.team_budget;
    // tournament.subgames.forEach(t => t.entry_fee = +t.entry_fee)
    const isSeasonalGame = tournament?.is_seasonal_game;
    const prizePoolSeasonalGame = 20000;
    const timeLeft = getTimeLeftInSeconds(tournament.start_time);

    return (
      <View style={styles.card}>
        {tournament.is_hot && <Image style={styles.hot} source={hotBadge}/>}
        {tournament.is_hot && <Text style={styles.hotText}>{translations.tKey('str_tcard_hot')}</Text>}

        <View style={styles.flagNameRow}>
          <Image style={styles.image} source={BASE_URL + tournament.flag.url}/>
          <Text style={styles.tournamentName}>{tournament.name}</Text>
        </View>
        <View style={styles.secondRow}>
          <View style={styles.detailsContainer}>

            <View style={styles.purpleBoxesContainer}>
              <div onClick={() => {
                this.props.onInfoClicked(1);
              }}>
                <TouchableOpacity>
                  <Text style={styles.purpleBox}>{`${tournament.team_budget.toFixed(1)}M`}</Text>
                </TouchableOpacity>
              </div>
              {tournament.active_captains &&
              <div onClick={() => {
                this.props.onInfoClicked(2);
              }}>
                <TouchableOpacity>
                  <Text style={styles.purpleBox}>C</Text>
                </TouchableOpacity>
              </div>}
              {tournament.active_vice &&
              <div onClick={() => {
                this.props.onInfoClicked(3);
              }}>
                <TouchableOpacity>
                  <Text style={styles.purpleBox}>VC</Text>
                </TouchableOpacity>
              </div>}

            </View>
            {isSeasonalGame &&  <View style={styles.durationAndMatchesContainer}>
              <Text
                style={styles.durationText}>{`${translations.tKey('str_tcard_duration')} M | GW 8-31`}</Text>
              <div onClick={() => this.openFixturesDialog()}>
                {/* Number of Matches for the game: */}
                {/* <TouchableOpacity>
                  <Text style={styles.numberOfMatches}>{tournament.number_of_matches}</Text>
                </TouchableOpacity> */}
              </div>
            </View>}

            {!isSeasonalGame &&  <View style={styles.durationAndMatchesContainer}>
              <Text
                style={styles.durationText}>{`${translations.tKey('str_tcard_duration')} ${tournament.duration}`}</Text>
              <div onClick={() => this.openFixturesDialog()}>
                <TouchableOpacity>
                  <Text style={styles.numberOfMatches}>{tournament.number_of_matches}</Text>
                </TouchableOpacity>
              </div>
            </View>}
           
          </View>
          <div style={styles.detailsButton} onClick={() => {
            navigation.navigate('TournamentDetails', {id: tournament.id});
          }}>
            <TouchableOpacity>
              <Text style={styles.detailsText}>{translations.tKey('str_tcard_details')}</Text>
            </TouchableOpacity>
          </div>
          {tournament.status == 'upcoming' && <View style={styles.timer}>
            <Image style={styles.clockImage} source={clock}/>

            <Timer style={styles.timerText} time={timeLeft} onTimerEnded={() => {
              this.setState({timeEnded: true});
            }}/>
          </View>}
        </View>
        <View style={styles.subGameRow}>

          {tournament.subgames.map((t, index) => (
              <View style={[styles.subGameColumn, subGameColumnStyle]} key={index}>
                <Text style={styles.prizePoolText}>{t.total_prize_pool && t.total_prize_pool > 0 && !isSeasonalGame ?
                  <div style={{ display: "flex", justifyContent: 'center' }}>
                    <IcPrizepool color={design.textColorPrimary} height={16} width={16} style={{ alignSelf: 'flex-start',  marginRight: 3, marginTop: isMobile ? -1 : 2  }} />
                    <span>
                      {numberWithCommas(t.total_prize_pool.toFixed(2))}{translations.tKey('str_currency')}
                    </span>
                  </div>
                  : ' '}
                </Text>
             
                <Text style={styles.prizePoolText}>{isSeasonalGame ?
                  <div style={{ display: "flex", justifyContent: 'center' }}>
                    <IcPrizepool color={design.textColorPrimary} height={16} width={16} style={{ alignSelf: 'flex-start', marginRight: 3, marginTop: isMobile ? -1 : 2 }} />
                    <span>
                      {numberWithCommas(prizePoolSeasonalGame.toFixed(2))}{translations.tKey('str_currency')}
                    </span>
                  </div>
                  : ''}
                </Text>
                <div
                  style={tournament.status == 'upcoming' && !this.state.timeEnded ? styles.playButton : styles.viewButton}
                  onClick={() => {
                    if (tournament.status == 'upcoming' && !this.state.timeEnded
                      && (t.max_entries_from_single_users < 0 || (!t.my_entries ? 0 : t.my_entries) < t.max_entries_from_single_users)) {
                      navigation.navigate('EnterTournament', {id: tournament.id, subId: t.id});
                      localStorage.setItem('addNewTeamHome', true);
                    } else {
                      if (!this.hasLoginToProvider()) {
                        localStorage.setItem('DashboardPending', JSON.stringify({subId: t.id}));
                      } else {
                        navigation.navigate('Dashboard', {subId: t.id});
                      }
                    }
                  }}>
                  <TouchableOpacity style={styles.playButtonOpacity}
                                    disabled={(t.max_players > 0 && t.current_players == tournament.max_players)}
                  >
                    {tournament.status == 'upcoming' && !this.state.timeEnded
                    && (
                      (t.max_entries_from_single_users < 0 || (!t.my_entries ? 0 : t.my_entries) < t.max_entries_from_single_users)
                      // || (t.max_players < 0 || t.current_players < tournament.max_players)
                    )
                      ?
                      <Text style={styles.playButtonText}>{t.entry_fee > 0 ?
                        `${translations.tKey('str_action_play')} ${isSeasonalGame ? '' : `${t.entry_fee.toFixed(2)}${translations.tKey('str_currency')}`}`
                        : translations.tKey('str_action_play_free')}
                      </Text>
                      :
                      <Text style={styles.playButtonText}>{translations.tKey('str_tcard_action_view')}</Text>
                    }
                  </TouchableOpacity>
                </div>
                {t.max_players != -1 && !isSeasonalGame && <View style={styles.progressBar}>
                  <View style={this.calculateProgressBarStyle(t)}/>
                  <View style={styles.playersText}>
                    <Text
                      style={[this.calculateRemainingPlayersStyle(t), styles.smallPlayerText]}>{`${this.calculateRemainingPlayers(t)}`}</Text>
                    <Text style={[styles.grayText, styles.smallPlayerText]}>{` / `}</Text>
                    <Text
                      style={[styles.grayText, styles.smallPlayerText]}>{`${t.max_players} ${translations.tKey('str_tcard_places')}`}</Text>
                  </View>
                </View>}
                {t.max_players != -1 && isSeasonalGame && <View style={styles.progressBar}>
                  <View style={this.calculateSeasonalProgressBarStyle(this.props.tournament, t)}/>
                  <View style={styles.playersText}>
                    <Text
                      style={[this.calculateRemainingPlayersStyle(t), styles.smallPlayerText]}>{`${this.calculateSeasonalRemainingPlayers(this.props.tournament, t)}`}</Text>
                    <Text style={[styles.grayText, styles.smallPlayerText]}>{` / `}</Text>
                    <Text
                      style={[styles.grayText, styles.smallPlayerText]}>{`${t.max_players} ${translations.tKey('str_tcard_places')}`}</Text>
                  </View>
                </View>}
                {t.max_players == -1 && !isSeasonalGame &&
                <View style={styles.playersText}>
                  <Text style={[styles.grayText, styles.smallPlayerText]}>
                    {t.current_players ? t.current_players : 0}{' '}
                    {t.current_players === 1
                      ? translations.tKey('str_tcard_player')
                      : translations.tKey('str_tcard_players')
                    }
                  </Text>
                </View>}

                {t.max_players == -1 && isSeasonalGame &&
                <View style={styles.playersText}>
                  <Text style={[styles.grayText, styles.smallPlayerText]}>
                    {this.props.tournament.current_seasonal_palms_game_entries ? this.props.tournament.current_seasonal_palms_game_entries : 0}{' '}
                    {this.props.tournament.current_seasonal_palms_game_entries === 1
                      ? translations.tKey('str_tcard_player')
                      : translations.tKey('str_tcard_players')
                    }
                  </Text>
                </View>}
              </View>
            )
          )}
        </View>
      </View>
    );
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getTournamentFixtures: id => dispatch(getTournamentFixtures(id)),
    clearFixtures: () => dispatch(clearFixtures())
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    //homeInfo: state.homeTournaments.tournamentsInfo
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(TournamentCard);

const operator = getDomainOperator();

const design = operator.design;

const base = {
  card: {
    minHeight: '250px',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    transition: '0.3s',
    width: '100%',
    backgroundColor: 'white',
    marginTop: '5px',
    marginBottom: '5px',
    padding: '3%',
    justifyContent: 'space-around',
    borderRadius: '3px'
  },
  image: {
    width: 50,
    height: 50,
    resizeMode: 'cover',
    borderRadius: 25,
    border: `2px solid ${design.tertiaryColor}`
  },
  flagNameRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '82%'
  },
  prizePoolText: {
    fontSize: 16,
    width: '100%',
    fontWeight: 'bold'
  },
  tournamentName: {
    fontSize: 16,
    fontWeight: 550,
    marginLeft: '10px'

  },
  hot: {
    width: 70,
    height: 70,
    position: 'absolute',
    top: 0,
    right: 0
  },
  hotText: {
    transform: 'rotate(45deg)',
    color: 'white',
    position: 'absolute',
    top: 12,
    right: 9
  },
  detailsContainer: {
    width: '45%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginTop: '10px'
  },
  purpleBoxesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  purpleBox: {
    backgroundColor: design.primaryColor,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    padding: '2px',
    paddingLeft: '10px',
    paddingRight: '10px',
    fontSize: 8,
    marginStart: '3px',
    marginEnd: '3px'
  },
  durationAndMatchesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '5px'

  },
  durationText: {
    fontSize: 10
  },
  numberOfMatches: {
    backgroundColor: design.primaryColor,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: 8,
    width: '15px',
    height: '15px',
    paddingBottom: '2px',
    borderRadius: 7.5,
    marginLeft: '5px'
  },
  detailsButton: {
    border: `1px solid ${design.primaryColor}`,
    paddingTop: '5px',
    paddingBottom: '5px',
    width: '20%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  playButtonOpacity: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  detailsText: {
    fontSize: 14,
    fontWeight: 550
  },
  secondRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  timer: {
    backgroundColor: design.tertiaryColor,
    padding: '5px',
    paddingLeft: '7px',
    paddingRight: '7px',
    width: '27%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'row'
  },
  subGameRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '20px',
    minHeight: '20%'
  },
  subGameColumn: {
    display: 'flex',
    flex: '-1',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'

  },
  subGameColumnBig: {
    width: '100%'
  },
  subGameColumnMedium: {
    width: '49%'
  },
  subGameColumnSmall: {
    width: '32%'
  },
  playButton: {
    width: '100%',
    backgroundColor: design.secondaryColor,
    paddingTop: '10px',
    paddingBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px'
  },
  viewButton: {
    backgroundColor: design.tertiaryColor,
    width: '100%',
    paddingTop: '10px',
    paddingBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px'
  },
  progressBar: {
    width: '100%',
    height: '5px',
    borderRadius: '2px',
    backgroundColor: '#EBE5EB',
    marginTop: '8px'
  },
  playersText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  grayText: {
    color: '#CABBCB'
  },
  playButtonText: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  timerText: {
    fontSize: 16,
    fontWeight: 550
  },
  smallPlayerText: {
    fontSize: 10,
    fontWeight: 550
  },
  clockImage: {
    marginTop: '1px',
    width: 13,
    height: 13,
    resizeMode: 'cover'
  }
};

const styles = createStyles(
  base,
  maxWidth(576, {
    durationText: {
      fontSize: 10
    },
    detailsText: {
      fontSize: 12
    },
    timerText: {
      fontSize: 13
    },
    playButtonText: {
      fontSize: 12
    },
    prizePoolText: {
      fontSize: 13
    },
    smallPlayerText: {
      fontSize: 12
    }
  }),
  maxWidth(376, {
    durationText: {
      fontSize: 8
    },
    detailsText: {
      fontSize: 11
    },
    timerText: {
      fontSize: 12
    },
    playButtonText: {
      fontSize: 11
    },
    prizePoolText: {
      fontSize: 11
    },
    smallPlayerText: {
      fontSize: 10
    }
  })
);