import React, { Component } from 'react';
import { Dimensions } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import WebRoutesGenerator from '../NativeWebRouteWrapper/';

import WelcomeScreen from '../Welcome/WelcomeScreen';
import ChooseRound from '../ChooseRound/ChooseRoundScreen';
import ChooseBet from '../ChooseBet/ChooseBetScreen';
import FoundRooms from '../FoundRooms/FoundRooms';
import HomeTournaments from '../HomeTournaments/screens/HomeTournaments';
import SearchRoom from '../SearchRoom/SearchRoom';
import SelectPlayer from '../Team/screens/SelectPlayer';
import PlayerProfile from '../Team/screens/PlayerProfile';
import Rankings from '../Rankings/screens/Rankings';
// import Transfers from '../Transfers/screens/Transfers';
import MakeTransfer from '../Transfers/screens/MakeTransfer';
import Results from '../Results/screens/Results';
import LiveScore from '../Results/screens/LiveScore';
import Stats from '../Stats/screens/Stats';
import Points from '../Points/screens/Points';
import Rules from '../Rules/screens/Rules';
import Maintenance from '../Common/screens/Maintenance/Maintenance';
import TournamentDetails from '../TournamentDetails/screens/TournamentDetails';
import EnterTournament from '../EnterTournament/screens/EnterSubTournament';
import SelectPlayers from '../EnterTournament/screens/SelectPlayers';
import EnterTournamentScoring from '../EnterTournament/components/EnterTournamentScoring';
import EnterTournamentDetails from '../EnterTournament/components/EnterTournamentDetails';
import Dashboard from '../Dashboard/screens/Dashboard';
import PlayerInfoDialog from '../EnterTournament/components/PlayerInfoDialog';
import Support from '../Support/Support';
import ConfirmPopup from '../ConfirmPopup/screens/ConfirmPopup';
import { isSafariIOS } from '../Common/utils/utils';
import { getDomainOperator} from '../Common/utils/domainMapper';
import { hotjar } from 'react-hotjar'
import { gtag, install } from 'ga-gtag';

import * as logger from '../Common/utils/logger';


const routeMap = {
	Welcome: {
		component: WelcomeScreen,
		path: '/',
		exact: true
	},
	Support: {
		component: Support,
		path: '/support',
		exact: true
	},
	ChooseRound: {
		component: ChooseRound,
		path: '/chooseRound'
	},
	TournamentDetails: {
		component: TournamentDetails,
		path: '/tournamentDetails/:id?'
	},
	EnterTournamentDetails: {
		component: EnterTournamentDetails,
		path: '/enterDetails/:id?/:subId?/:tabId?'
	},
	PlayerInfoDialog: {
		component: PlayerInfoDialog,
		path: '/playerInfo/:id?/:isSeasonal'
	},
	Dashboard: {
		component: Dashboard,
		path: '/dashboard/:subId?'
	},
	EnterTournament: {
		component: EnterTournament,
		path: '/enterTournament/:id?/:subId?/:teamId?'
	},
	SelectPlayers: {
		component: SelectPlayers,
		path: '/selectPlayers/:id?/:subId?'
	},
	TournamentScoring: {
		component: EnterTournamentScoring,
		path: '/scoring/:id?'
	},
	ChooseBet: {
		component: ChooseBet,
		path: '/chooseBet'
	},
	SearchRoom: {
		component: SearchRoom,
		path: '/searchRoom/:bet?/:roomMaxPlayers?'
	},
	FoundRooms: {
		component: FoundRooms,
		path: '/foundRooms'
	},
	Home: {
		component: HomeTournaments,
		path: '/home'
	},
	// Team: {
	// 	component: Team,
	// 	path: '/team'
	// },
	SelectPlayer: {
		component: SelectPlayer,
		path: '/selectPlayer'
	},
	PlayerProfile: {
		component: PlayerProfile,
		path: '/playerProfile/:playerId?'
	},
	Rankings: {
		component: Rankings,
		path: '/rankings/:roomId?'
	},
	// Transfers: {
	// 	component: Transfers,
	// 	path: '/transfers'
	// },
	MakeTransfer: {
		component: MakeTransfer,
		path: '/maketransfer'
	},
	Results: {
		component: Results,
		path: '/results'
	},
	LiveScore: {
		component: LiveScore,
		path: '/livescore/:scoreId?'
	},
	Stats: {
		component: Stats,
		path: '/stats'
	},
	Points: {
		component: Points,
		path: '/points/:userId?'
	},
	Rules: {
		component: Rules,
		path: '/rules'
	},
	Maintenance: {
		component: Maintenance,
		path: '/maintenance'
	}
};


const isMobile = () => {
	return window.innerWidth <= 850;
}

const maxWidth = 500;

class App extends Component {

	constructor(props) {
		super(props);
		this.state = {
			operator: getDomainOperator(),
		}
	}

	changeFavicon(src) {
		var link = document.createElement('link'),
		oldLink = document.getElementById('dynamic-favicon');
		link.id = 'dynamic-favicon';
		link.rel = 'shortcut icon';
		link.href = src;
		if (oldLink) {
		 document.head.removeChild(oldLink);
		}
		document.head.appendChild(link);
	}

	changeTitle(title) {
		document.title = title;
	}

	changeBackground(background) {
		document.body.style.background = `url("${background}") center center / cover no-repeat fixed`;
	}

	addTracking(tracking) {
		if(tracking) {
			install('G-KNXH1H90LT', { 'send_page_view': true})
			gtag('js', new Date());
			gtag('config', 'G-KNXH1H90LT');
			hotjar.initialize('3212599', 6);
			hotjar.identify('USER_Name', {userProperty: localStorage.getItem('username')})
		}
	}

	componentDidMount() {
		let prevHeight = 0;

		window.setInterval(() => {
			if (prevHeight !== document.body.offsetHeight) {
				prevHeight = document.body.offsetHeight;

				logger.logTrace('Body offset height changed to: ', document.body.offsetHeight);
				console.log('Body offset height changed to: ', document.body.offsetHeight)
				window.parent.postMessage({
					command: 'iframe',
					height: document.body.offsetHeight
				}, '*');
			}
		}, 100);

		this.addTracking(this.state.operator.tracking);
	}

	componentWillUnmount() {
		window.clearInterval();
	}

	render() {
		this.changeFavicon(this.state.operator.favicon);
		this.changeTitle(this.state.operator.title);
		this.changeBackground(this.state.operator.background);
		
		const {width} = Dimensions.get('window');
		let w = width > maxWidth ? maxWidth : width;
		EStyleSheet.build({
			$rem: w / 86
		});
		return (
			<div>
				{!isMobile() && 
					<img src={this.state.operator.logo} alt='' style={{ position: 'fixed', bottom: '0', right: '0', width: '200px'}}/>
				}
				<div style={{ minHeight: '900px', width: '100%', backgroundColor: 'white', display: 'flex', flexDirection: 'column' }}>
					<div style={{ flex: 1, ...(isSafariIOS() && { marginBottom: 25 }) }}>
						<ConfirmPopup/>
						{WebRoutesGenerator({routeMap})}
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
							<img style={{ maxWidth: '45%'}} src={this.state.operator.footer} alt=''/>
						</div> 
					</div>
				</div>
			</div>

		);
	}
}

export default App;
