import { 
  SET_BUDGET, 
  DECREASE_BUDGET,
  INCREASE_BUDGET,
  CLOSE_ROUND,
  SET_HOME_INFO,
  OPEN_ROUND
 } from "../actions/actionTypes";

const initialState = {
    info: null,
    budget: 10000,
    isRoundClosed: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOME_INFO: {
      return {
        ...state,
        info: action.info
      };
    }
    case CLOSE_ROUND: {
      return {
        ...state,
        isRoundClosed: true
      };
    }
    case OPEN_ROUND: {
      return {
        ...state,
        isRoundClosed: false
      };
    }   
    case SET_BUDGET: {
      return {
        ...state,
        budget: action.budget
      }
    }
    case DECREASE_BUDGET: {
      return {
        ...state,
        budget: state.budget - action.sum
      }
    }
    case INCREASE_BUDGET: {
      return {
        ...state,
        budget: state.budget + action.sum
      }
    }

    default:
      return state;
  }
};

export default reducer;