import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import { isMobile } from "react-device-detect";
import { View, Text, TouchableOpacity, } from "react-native";
import { createStyles } from 'react-native-media-queries';
import * as translations from "../../Common/utils/translations"
import { getDomainOperator } from '../../Common/utils/domainMapper';

const operator = getDomainOperator();

const design = operator.design;

const base = {
    title: {
        backgroundColor: design.primaryColor,
        height: "64px",
        width: isMobile ? "80%" : "22%",
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        zIndex: 9999
    },
    titleText: {
        color: "white",
        fontSize: 20,
        fontWeight: 550
    },
    exitButton: {
        backgroundColor: "#FF085D",
        width: 22,
        height: 22,
        position: "absolute",
        paddingBottom: 5,
        top: 1,
        right: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",
        zIndex: 9999,
    },
    exitButtonText: {
        color: "white",
        fontWeight: 'bold',
        fontSize: 15
    },
    playButton: {
        width: "70%",
        backgroundColor: design.secondaryColor,
        paddingTop: "10px",
        paddingBottom: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "4px"
    },
    playButtonText: {
        fontSize: 14,
        fontWeight: "bold"
    },

};

class InformationDialog extends React.Component {

    render() {

        return (
          <Dialog
            onClose={() => {
                this.props.onClose()
            }}
            aria-labelledby="dialog-title"
            open={this.props.open}

            PaperProps={{
                style: {
                    width: isMobile ? "80%" : "22%"
                },
            }}

            style={{ maxHeight: 1700, ...this.props.styles }}>
              <View style={styles.title}>
                  <TouchableOpacity style={styles.exitButton}
                                    onPress={() => {
                                        this.props.onClose()
                                    }}>
                      <Text style={styles.exitButtonText}>x</Text></TouchableOpacity>
                  <Text style={styles.titleText}>{this.props.title ? this.props.title : ""}</Text>

              </View>

              <View style={{ marginTop: "70px", display: 'flex', alignItems: "center", justifyContent: 'center', width: "100%", padding: "20px" }}>
                  {this.props.showIcon && (
                    <View style={{ width: 68, height: 68, borderRadius: 32, border: `3px solid ${design.secondaryColor}`, display: 'flex', alignItems: "center", justifyContent: "center", marginBottom: 50 }}>
                        <Text style={{ color: design.secondaryColor, fontSize: 45 }}>!</Text>
                    </View>
                  )}
                  {this.props.body && <Text style={{fontSize: 24, textAlign: 'center', marginBottom: '40px'}}>{this.props.body}</Text>}
                  {this.props.children}


                  {this.props.showConfirmButton && (
                    <div style={styles.playButton}
                         onClick={() => {
                             this.props.onClose()
                         }}>
                        <TouchableOpacity  >
                            <Text style={styles.playButtonText}>{translations.tKey("str_info_got_it")}</Text>

                        </TouchableOpacity>
                    </div>
                  )}
              </View>
          </Dialog>
        )
    }
}

InformationDialog.propTypes = {
    showIcon: PropTypes.bool,
    showConfirmButton: PropTypes.bool,
};

InformationDialog.defaultProps = {
    showIcon: true,
    showConfirmButton: true
}


export default InformationDialog;

const styles = createStyles(base);