import React from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { createStyles } from 'react-native-media-queries';
import { numberWithCommas } from "../../Common/utils/utils";
import downArrow from "../../assets/images/down_arrow.svg"
import * as translations from "../../Common/utils/translations"

class TournamentPrizes extends React.Component {

    state = {
        prizes: null,
        sortOrders: [
            { name: "place", order: "desc" },
            { name: "ratio", order: "desc" },
            { name: "amount", order: "desc" }
        ]
    }

    componentDidMount() {
        this.setState({ prizes: this.props.prizes });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.prizes != this.props.prizes) {
            this.setState({ prizes: nextProps.prizes });
        }
    }


    getRowComponent = (name, value) => {
        return (
          <View style={styles.nameValueRow}>
              <View style={styles.nameRow}>
                  <Text style={styles.textNormal}>{name}</Text>
              </View>
              <View style={styles.valueRow}>
                  <Text style={styles.textNormal} >{value}</Text>
              </View>
          </View>
        );
    }

    sortBy = (sortCriteria) => {
        if (this.props.forDashboard) return;
        if (sortCriteria == "place") {
            this.setState({
                prizes: this.state.prizes.slice().sort((a, b) => {
                    if (a.place.from > b.place.from) return this.state.sortOrders.find(t => t.name == sortCriteria).order == "asc" ? 1 : -1;
                    if (a.place.from < b.place.from) return this.state.sortOrders.find(t => t.name == sortCriteria).order == "asc" ? -1 : 1;
                    return 0;
                }),
                sortOrders: this.state.sortOrders.map(sort => {
                    if (sort.name == sortCriteria) {
                        let newOrder = sort.order == "asc" ? "desc" : "asc";
                        return { ...sort, order: newOrder };
                    }
                    return { ...sort }
                })
            })
        } else {
            this.setState({
                prizes: this.state.prizes.slice().sort((a, b) => {
                    if (a[sortCriteria] > b[sortCriteria]) return this.state.sortOrders.find(t => t.name == sortCriteria).order == "asc" ? 1 : -1;
                    if (a[sortCriteria] < b[sortCriteria]) return this.state.sortOrders.find(t => t.name == sortCriteria).order == "asc" ? -1 : 1;
                    return 0;
                }),
                sortOrders: this.state.sortOrders.map(sort => {
                    if (sort.name == sortCriteria) {
                        let newOrder = sort.order == "asc" ? "desc" : "asc";
                        return { ...sort, order: newOrder };
                    }
                    return { ...sort }
                })
            })
        }
    }

    getPlaceTextComponent = (place) => {

        return <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={this.props.forDashboard ? styles.whiteText : null}>{place.from}</Text>
            {place.to &&
                <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={this.props.forDashboard ? styles.whiteText : null}>{" - "}</Text>
                    <Text style={this.props.forDashboard ? styles.whiteText : null}>{place.to}</Text>
                </View>
            }
        </View>
    }
    render() {
        const { prizes } = this.state;
        if (!prizes) return null;
        return (
            <View style={this.props.forDashboard ? styles.purpleContainer : styles.container}>
                <View style={styles.tableContainer}>
                    <View style={styles.tableHeader}>
                        <TouchableOpacity style={styles.tableCell} onPress={() => { this.sortBy("place") }}>
                            <Text style={[styles.tableHeaderText, this.props.forDashboard ? styles.whiteText : null]}>{translations.tKey("str_prizes_place")}</Text>
                            {!this.props.forDashboard && <Image style={styles.arrowImage} source={downArrow} />}
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.tableCellMedium} onPress={() => { this.sortBy("ratio") }}>
                            <Text style={[styles.tableHeaderText, this.props.forDashboard ? styles.whiteText : null]}>{translations.tKey("str_prizes_ratio")}</Text>
                            {!this.props.forDashboard && <Image style={styles.arrowImage} source={downArrow} />}
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.tableCellLast} onPress={() => { this.sortBy("amount") }}>
                            <Text style={[styles.tableHeaderText, this.props.forDashboard ? styles.whiteText : null]}>
                                {translations.tKey(this.props.forDashboard ? "str_prizes_prize" : "str_prizes_amount")}
                            </Text>
                            {!this.props.forDashboard && <Image style={styles.arrowImage} source={downArrow} />}
                        </TouchableOpacity>
                    </View>
                    {prizes.map((entry, index) => {
                        let amount = entry.amount;
                        amount = amount * 100;
                        amount = Math.round(amount);
                        amount = amount / 100;
                        return (
                          <View style={styles.tableRow} key={index}>
                              <View style={[styles.tableCell, styles.tableCellText, this.props.forDashboard ? styles.whiteText : null]}>{this.getPlaceTextComponent(entry.place)}</View>
                              <Text style={[styles.tableCellMedium, styles.tableCellText, this.props.forDashboard ? styles.whiteText : null]}>{`${entry.ratio ? entry.ratio.toFixed(2) + '%' : '-' }`}</Text>
                              <Text style={[styles.tableCellLast, styles.amountText, this.props.forDashboard ? styles.whiteText : null]}>
                                  {`${numberWithCommas(amount.toFixed(2))}${translations.tKey("str_currency")}`}
                              </Text>
                          </View>
                        )
                    })}
                </View>
            </View>
        );
    }
};

export default TournamentPrizes;

const base = {

    container: {
        width: '100%',
        display: "flex",
        alignItems: 'center'
    },
    purpleContainer: {
        width: '100%',
        display: 'flex',
        alignItems: "center",
        backgroundColor: "#44024A",
        paddingHorizontal: "10%",
        paddingVertical: 20
    },
    whiteText: {
        color: "white"
    },
    tableContainer: {
        marginTop: "20px",
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center',
        width: "92%"
    },
    tableHeader: {
        display: "flex",
        flexDirection: "row",
        width: "100%"
    },
    tableHeaderText: {
        fontSize: 14,
        fontWeight: "bold"
    },
    tableRow: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        marginVertical: "5px"
    },
    tableCellText: {
        fontSize: 14
    },
    amountText: {
        fontSize: 14,
        fontWeight: 'bold',
        color: "#02894E"
    },
    tableCell: {
        width: "33%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: 'center',
        flexDirection: "row"
    },
    tableCellLast: {
        width: "33%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: 'center',
        flexDirection: "row"
    },
    tableCellMedium: {
        width: "33%",
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        flexDirection: "row"
    },
    arrowImage: {
        width: "7px",
        height: "4.3px",
        resizeMode: "contain",
        marginLeft: "2px",
        marginTop: "2px"
    }
};

const styles = createStyles(base)

