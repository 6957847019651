import React from 'react';
import { StyleSheet, View } from 'react-native';
import Background from './Background';

const LoadingScreen = (props) => {
    return (
        <View style={styles.container}>
            <Background loading={true} style={[{height: '85%', width: '100%'}, props.style]}/> 
        </View>
    );
}

const styles = StyleSheet.create({
  container: {
    zIndex: 200,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    width: '100%',
    height: '900px',
    backgroundColor: 'black'
  }
});

export default LoadingScreen;