import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image, TouchableOpacity, Linking } from "react-native";

import Dialog from '@material-ui/core/Dialog';

import { createStyles } from 'react-native-media-queries';
import { formatPlayersName } from '../../Common/utils/utils';

import * as translations from "../../Common/utils/translations";
import PlayerView from './../../Common/components/PlayerView';
import { useDispatch } from 'react-redux';

import {
    changePlayer, preferenceSwapPlayer
  } from '../../Team/actions/team';
import { getDomainOperator } from '../../Common/utils/domainMapper';

const operator = getDomainOperator();

const design = operator.design;

const base = {
    title: {
        backgroundColor: design.primaryColor,
        position: 'absolute',
        width: '100%',
        paddingVertical: 10,
        alignItems: "center",
        justifyContent: "center",
        zIndex: 9999
    },
    titleText: {
        color: "white",
        fontSize: 20,
        fontWeight: 550
    },
    exitButton: {
        backgroundColor: "#FF085D",
        width: 22,
        height: 22,
        position: "absolute",
        paddingBottom: 5,
        top: 1,
        right: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",
        zIndex: 9999,
    },
    exitButtonText: {
        color: "white",
        fontWeight: 'bold',
        fontSize: 15
    },
    buttonStyle: {
        width: "90%",
        paddingHorizontal: 10,
        paddingVertical: 10,
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3,
        backgroundColor: "#EFEFEF",
        marginVertical: 10
    },
    buttonStyleDoSwap: {
        width: "90%",
        paddingHorizontal: 10,
        paddingVertical: 10,
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3,
        backgroundColor: design.secondaryColor,
        marginVertical: 10
    },
    buttonImageContainer: {
        border: "1px solid rgba(255, 255, 255, .2)",
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center',
        padding: 5,
        marginRight: 10
    },
    buttonText: {
        fontWeight: 550,
        fontSize: 14,
        color: design.textColorPrimary,
    },
    buttonImage: {
        width: "13px",
        height: "11px"
    },
    playerView: {
        maxHeight: 130,
        maxWidth: 80
    }
};


const GOALKEPER = 1;
const DEFENDER = 2;
const MIDFIELDER = 3;
const ATTACKER = 4;

const getPositionLabel = (positionId) => {
    const labelStyle = {
        fontWeight: "bold",
        fontSize: 12,
        paddingVertical: 4,
        paddingHorizontal: 5,
        marginRight: 5
    }
    var text = "";
    switch (positionId) {
        case GOALKEPER: {
            labelStyle.backgroundColor = "#EBFF00";
            text = translations.tKey("str_position_gk");
            break;
        }
        case DEFENDER: {
            labelStyle.backgroundColor = "#01FF87";
            text = translations.tKey("str_position_def");
            break;
        }
        case MIDFIELDER: {
            labelStyle.backgroundColor = "#00F0FF";
            text = translations.tKey("str_position_mid");
            break;
        }
        case ATTACKER: {
            labelStyle.backgroundColor = "#FF085D";
            labelStyle.color = "white";
            text = translations.tKey("str_position_fwd");
            break;
        }
        default: {
            break;
        }
    }
    return <Text style={labelStyle}>{text}</Text>
}

const getChangeButtonText = (text, onClick, style, disabled) => {
    return (<View onPress={onClick}
        disabled={disabled}
        style={[styles.buttonStyle, style]}>
        <Text style={styles.buttonText}>{text}</Text>
    </View>)
}

const getButton = (text, onClick, style, disabled) => {
    return (<TouchableOpacity onPress={onClick}
        disabled={disabled}
        style={[styles.buttonStyleDoSwap, style]}>
        <Text style={styles.buttonText}>{text}</Text>
    </TouchableOpacity>)
}

export const ChangePlayerDialog = (props) => {
    const dispatch = useDispatch();
    const [isSelectedForChange, selectPlayerForChange] = React.useState(false);
    const [selectedPlayer, changeSelectedPlayer] = React.useState(null);
    const { player, team, subTournament, mode } = props;
    
    if (!player) return null;
    let changeMode = null;
    let possibleChanges
    if(mode && mode == 'swap') {
        possibleChanges = team.reserves.filter(reservePlayer => {
            if(player.preferencePosition == 0) {
                return;
            }
            if(reservePlayer.id !== player.id && reservePlayer.preferencePosition !== 0) {
                return reservePlayer;
            }
        });
    } else {
        if(player.preferencePosition == null) {
            changeMode = 'titular';
            possibleChanges = team.reserves.filter(reservePlayer => {
                if(reservePlayer.positionId == player.positionId) {
                    return reservePlayer;
                }
            });
        } else {
            changeMode = 'reserve';
            possibleChanges = [];
            team.groups.forEach(group => {
                group.players.forEach(p => {
                    if(p.positionId == player.positionId) {
                        possibleChanges.push(p);
                    }
                })
            })
        }
    }
    

  

    const selectForChange = (player) => {
        changeSelectedPlayer(player);
        selectPlayerForChange(!isSelectedForChange);
    }

    const doSwap = (selectedPlayer) => {
        if(mode && mode == 'swap') {
            props.onClose()
            selectPlayerForChange(false);
            dispatch(preferenceSwapPlayer(player, selectedPlayer, team, subTournament));
        } else {
            if(changeMode == 'titular') {
                props.onClose()
                selectPlayerForChange(false);
                dispatch(changePlayer(selectedPlayer, player, team, subTournament));
            } else {
                props.onClose()
                selectPlayerForChange(false);
                dispatch(changePlayer(player, selectedPlayer, team, subTournament));
            }       
        }
    }

    return (
       
        <Dialog
            onClose={() => props.onClose()}
            aria-labelledby="dialog-title"
            open={props.open}

            PaperProps={{
                style: {
                    width: "90%",
                    maxWidth: '400px'
                },
            }}

            style={{ maxHeight: 1700 }}>
            <View style={styles.title}>
                <TouchableOpacity style={styles.exitButton}
                    onPress={() => props.onClose()}>
                    <Text style={styles.exitButtonText}>x</Text></TouchableOpacity>
                <View style={{ width: '90%', display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: "row" }}>
                    <Image style={{ width: 45, height: 50, marginRight: 20 }} source={player.iconUrl} />
                    <View style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', width: "80%" }}>
                        <View>
                            <Text style={{ fontWeight: 550, fontSize: 16, color: "white" }}>
                                {formatPlayersName(player, 'firstFormatted')}
                            </Text>
                            <Text style={{ fontSize: 16, fontWeight: 550, color: "white" }}>{`${player.palms_price.toFixed(1)}M`}</Text>
                        </View>

                        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            {getPositionLabel(player.groupId)}
                            <Text style={!player.teams[0].player_in_team ? { fontSize: 12, color: "white", opacity: 0.3 } : { fontSize: 12, color: "white" }}>{player.teams[0].name}</Text>
                            <Text style={!player.teams[1].player_in_team ? { fontSize: 12, color: "white", opacity: 0.3 } : { fontSize: 12, color: "white" }}>{` - ${player.teams[1].name}`}</Text>
                        </View>
                    </View>

                </View>
                <Text style={styles.titleText}>{props.title ? props.title : ""}</Text>
              
             
            </View>

            <View style={{ marginTop: "90px", display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                    <View style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                    {possibleChanges.map((player, i) => {
                      if (player !== null) {
                        return (
                            <PlayerView
                                key={player.playerId || player.id}
                                onPress={() => {selectForChange(player)}}
                                hidePosition={true}
                                isForSale={isSelectedForChange && (selectedPlayer.id == player.id)} 
                                {...player}
                                style={styles.playerView}
                                changePlayerDialog={true}
                            />  
                        );
                      }
                    })}
                    {(!possibleChanges.length) && <Text>{translations.tKey("str_highlight_change_position_no_option")}</Text>}
                    </View>
                {!isSelectedForChange && getChangeButtonText(translations.tKey("str_player_options_possible_changes"), () => {
                    player.merchandise_link && Linking.openURL(player.merchandise_link);
                }, null, null, { opacity: player.merchandise_link ? 1 : 0.5 }, !player.merchandise_link)}

                {isSelectedForChange && getButton(translations.tKey("str_player_options_do_swap"), () => {
                    doSwap(selectedPlayer);
                }, null, null, { opacity: player.merchandise_link ? 1 : 0.5 }, !player.merchandise_link)}


            </View>
        </Dialog>
    )
}


ChangePlayerDialog.propTypes = {
    player: PropTypes.shape({
       firstName: PropTypes.string,
        lastName: PropTypes.string
    }),
}
  

const styles = createStyles(base);