import React from 'react';
import { View, Text, Image, TouchableOpacity, ActivityIndicator } from 'react-native';
import { createStyles } from 'react-native-media-queries';
import search from '../../assets/images/search.svg';
import downArrow from '../../assets/images/down_arrow.svg';
import Pagination from '../../Common/components/Pagination';
import * as translations from '../../Common/utils/translations';
import TrottledSearchField from '../../Common/components/TrottledSearchField';
import { Icon } from '../../Common/components/Icon';
import { isMobile } from 'react-device-detect';
import { getDomainOperator } from '../../Common/utils/domainMapper';

class TournamentEntries extends React.Component {

  state = {
    name: '',
    data: null,
    sortCriterias: [
      {
        name: 'position',
        sort: 'desc'
      },
      {
        name: 'user_name',
        sort: 'desc'
      },
      {
        name: 'pay_outs',
        sort: 'desc'
      },
      {
        name: 'points',
        sort: 'desc'
      },
      {
        name: 'total_championship_points',
        sort: 'desc'
      }
    ]
  };

  componentDidMount() {
    
    this.setState({data: this.props.data});
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.currentSort) {
      this.setState({ data: nextProps.data });
      return;
    }
    let sortCriteria = this.state.currentSort.name;
    if (this.props.isSeasonal) {
      if (sortCriteria) {
        if (sortCriteria == "points_pp"){
          sortCriteria = "current_points";
        }
      }
    }
    if (nextProps.data != this.props.data) {
      const data = nextProps.data.data.slice().sort((a, b) => {
        if (sortCriteria == 'user_name' ? (a[sortCriteria] > b[sortCriteria]) : (+a[sortCriteria] > +b[sortCriteria])) {
          return this.state.currentSort.sort == 'asc' ? 1 : -1;
        }
        if (sortCriteria == 'user_name' ? (a[sortCriteria] < b[sortCriteria]) : (+a[sortCriteria] < +b[sortCriteria])) {
          return this.state.currentSort.sort == 'asc' ? -1 : 1;
        }
        return 0;
  
      });
      this.setState({
        data: {
          ...nextProps.data,
          data
        }});
    }
  }

  getRowComponent = (name, value) => {
    return <View style={styles.nameValueRow}>
      <View style={styles.nameRow}>
        <Text style={styles.textNormal}>{name}</Text>
      </View>
      <View style={styles.valueRow}>
        <Text style={styles.textNormal}>{value}</Text>
      </View>
    </View>;
  };

  sortBy = (sortCriteria, switchSort) => {
    const currentSort =  !this.state.currentSort
    || (this.state.currentSort.name != sortCriteria || this.state.currentSort.sort != this.state.sortCriterias.find(t => t.name == sortCriteria).sort)
    ? { ...this.state.sortCriterias.find(t => t.name == sortCriteria) }
    : this.state.currentSort;
    if (this.props.isSeasonal) {
      if (currentSort) {
        if (currentSort.name == "points_pp"){
          currentSort.name = "current_points";
        }
      }
    }
    this.props.onNextRequest(1, this.state.name, currentSort ? currentSort : {});
  
    this.setState({
      currentSort: !this.state.currentSort
        || (this.state.currentSort.name != sortCriteria || this.state.currentSort.sort != this.state.sortCriterias.find(t => t.name == sortCriteria).sort)
        ? { ...this.state.sortCriterias.find(t => t.name == sortCriteria) }
        : this.state.currentSort,
      sortCriterias: this.state.sortCriterias.map(t => {
        if (t.name == sortCriteria && switchSort) {
          if (t.sort == 'asc') {
            t.sort = 'desc';
          } else {
            t.sort = 'asc';
          }
        }
        return {...t};
      })
    });
  };

  render() {
    const {data} = this.state;
    const {isLoading} = this.props;

    return (
      <View style={styles.container}>
        <View style={styles.allItemsTextContainer}>
          <Text style={styles.allItemsText}>{translations.tKey('str_dashboard_leaderboard')}</Text>
          <Text style={styles.allItemsText}>{data && data.total_championship_players}</Text>
        </View>
        <View style={styles.searchContainer}>
          <Image style={styles.searchImage} source={search}/>
          <TrottledSearchField
            className={styles.textInput}
            placeholderTextColor={design.textColorPrimary}
            style={styles.textInput}
            interval={isMobile ? 800 : null}
            placeholder={translations.tKey('str_dashboard_search_user')}
            onChange={(text) => {
              this.setState({name: text});
              let currentSort = this.state.currentSort;
              if (currentSort) {
                if (currentSort.name == "points_pp"){
                  currentSort.name = "current_points";
                }
              }
              this.props.onNextRequest(1, text, currentSort ? currentSort : {});

            }}
          />
        </View>
        {isLoading ? <ActivityIndicator size={'large'} color={design.primaryColor} style={{marginVertical: 20}}/> :
          <View style={styles.tableContainer}>
            <View style={styles.tableHeader}>
              <div style={{...styles.tableCellSmall}} onClick={() => {
                this.sortBy('position', true);
              }}>
                <TouchableOpacity style={{...styles.headerButton, ...styles.spaceBetween}}>
                  <Icon icon="number" size={14}/>

                  <Image style={styles.arrowImage} source={downArrow}/>
                </TouchableOpacity>
              </div>
              <div style={{...styles.tableCellBig}} onClick={() => {
                this.sortBy('user_name', true);
              }}>
                <TouchableOpacity style={{...styles.headerButton, ...styles.spaceBetween}}>
                  <Icon icon="user" size={16}/>
                  <Image style={styles.arrowImage} source={downArrow}/>
                </TouchableOpacity>
              </div>
              {/* <div style={{...styles.tableCellMediumBig}} onClick={() => {
                this.sortBy('pay_outs', true);
              }}>
                <TouchableOpacity style={{...styles.headerButton, ...styles.spaceBetween}}>
                  <Icon icon="prizePool" size={16}/>

                  <Image style={styles.arrowImage} source={downArrow}/>
                </TouchableOpacity>
              </div> */}

              <div style={{...styles.tableCellSmall}} onClick={() => {
                this.sortBy('points', true);
              }}>
                <TouchableOpacity style={{...styles.headerButton, ...styles.spaceBetween}}>
                  GT*
                  <Image style={styles.arrowImage} source={downArrow}/>
                </TouchableOpacity>
              </div>

              <div style={{...styles.tableCellSmall}} onClick={() => {
                this.sortBy('total_championship_points', true);
              }}>
                <TouchableOpacity style={{...styles.headerButton, ...styles.spaceBetween}}>
                  ST*
                  <Image style={styles.arrowImage} source={downArrow}/>
                </TouchableOpacity>
              </div>
            </View>
            {data && data.data.map((entry, index) => {
              // let amount = entry.pay_outs;
              // amount = amount * 100;
              // amount = Math.round(amount);
              // amount = amount / 100;
              return (
                <div style={{width: '100%'}} onClick={() => this.props.onTeamClicked(entry)} key={index}>
                  <TouchableOpacity
                    style={[styles.tableRow, this.props.selected && (entry.user_team_id == this.props.selected.user_team_id || entry.user_team_id == this.props.selected.teamId) ? styles.selectedRow : null]}>
                    <Text style={[styles.tableCellSmall, styles.tableCellText]}>{`${entry.position}.`}</Text>
                    <Text style={[styles.tableCellBig, styles.tableCellText]}>{entry.user_name}</Text>
                    {/* {!this.props.data.is_seasonal_game &&  <Text
                      style={[styles.tableCellMediumBig, styles.tableCellText]}>{`${amount.toFixed(2)}${translations.tKey('str_currency')}`}
                    </Text>} */}
                    <Text style={[styles.tableCellSmall, styles.tableCellText, styles.bold, styles.start]}>
                      {entry.points}
                    </Text>
                    <Text style={[styles.tableCellSmall, styles.tableCellText, styles.start]}>
                      {entry.total_championship_points}
                    </Text>
                  </TouchableOpacity>
                </div>
              );
            })}

          </View>}
        {!isLoading && (
          <View style={styles.paginationContainer}>
            <Pagination data={data} changePageTo={(page) => {
              let currentSort = this.state.currentSort;
              if (currentSort) {
                if (currentSort.name == "points_pp"){
                  currentSort.name = "current_points";
                }
              }
              this.props.onNextRequest(page, this.state.name, currentSort ? currentSort : {});
            }}/>
          </View>
        )}
        <View style={styles.alignStart}>
          *GT - GAMEWEEK TOTAL
        </View>
        <View style={styles.alignStart}>
          *ST - SEASONAL TOTAL
        </View>
      </View>
    );
  }
}

export default TournamentEntries;

const operator = getDomainOperator();

const design = operator.design;

const base = {
  alignStart: {
    alignSelf:'flex-start',
    marginStart:'16px'
  },
  bold: {
    fontWeight: 900
  },
  start: {
    display: 'flex',
    justifyContent: 'start'
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  spaceBetween: {
    justifyContent: 'space-between'
  },
  paginationContainer: {
    marginVertical: 20
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  tableContainer: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    width: '92%',
    marginBottom: 15
  },
  tableHeaderText: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  headerButton: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingVertical: '5px',
    paddingHorizontal: '4%'
  },
  selectedRow: {
    backgroundColor: design.secondaryColor
  },
  tableCellText: {
    fontSize: 14
  },
  tableCellSmall: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row'
  },
  tableCellMedium: {
    flex: 2,
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row'
  },
  tableCellMediumBig: {
    flex: 3,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row'
  },
  tableCellBig: {
    flex: 5,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row'
  },
  searchImage: {
    width: '14.5px',
    height: '14.5px',
    resizeMode: 'contain',
    marginLeft: '10px',
    marginRight: '10px'
  },
  allItemsTextContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    paddingVertical: '10px',
    paddingHorizontal: '10px',
    backgroundColor: '#EDEDED',
    borderRadius: 3
  },
  allItemsText: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  searchContainer: {
    width: '92%',
    paddingVertical: '10px',
    paddingHorizontal: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    border: '2px solid black'
  },
  arrowImage: {
    width: '7px',
    height: '4.3px',
    resizeMode: 'contain',
    marginRight: '15px',
    marginTop: '2px'
  },
  textInput: {
    width: '90%',
    outline: 'none',
    fontSize: 16,
    fontWeight: 550,
    color: design.textColorPrimary,
  }
};

const styles = createStyles(base);

