import { Operator } from "./Operator";
import loading from '../../assets/animated-loading.gif'
import fieldWithBanners from '../../assets/images/fieldWithBanners.svg';
import background from '../../assets/images/background.png';
import { GetArabicRules, GetEnglishRules, GetNoRewardsGetStarted } from "./domainMapper";
import React from 'react';

export class OperatorFactory{
    baseURL = 'https://api.ffeleven.com/numb/getWinner/';

    login = 'demo';
    baseLogin = '.ffstars.com';
    fullLogin = 'demo.ffstars.com';
    logo = './logo.png';
    loading = loading;
    winners = true;
    winnersURL = this.baseURL + 'DEMO';
    rules = <div><GetArabicRules/><GetEnglishRules/></div>;
    getStarted = <GetNoRewardsGetStarted/>;
    title = 'FFEleven';
    language = 'en';
    footer = './footer_logo.png';
    favicon = './logo.png';
    tracking = true;
    design = {
        primaryColor: '#37003C',
        secondaryColor: '#00FF8E',
        tertiaryColor: '#DFDFDF',
        textColorHighlight: '#00FF8E',
        textColorDisabled: '#EFEFEF',
        textColorPrimary: '#000000',
        textColorSecondary: '#FFFFFF',
        textFontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
        textSecondaryFontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
    };
    unsubscribe = false;
    background = background;
    whiteLabel = null;
    fieldWithBanners = fieldWithBanners;
    subscription = null;
    rewards = null;

    constructor(){
    };
    
    withTracking(tracking) {
        this.tracking = tracking;
        return this;
    };

    withFavicon(favicon) {
        this.favicon = favicon;
        return this;
    };

    withTitle(title) {
        this.title = title;
        return this;
    };

    withFooter(footer) {
        this.footer = footer;
        return this;
    };

    withLogo(logo) {
        this.logo = logo;
        return this;
    };

    withLogin(login) {
        this.login = login;
        this.fullLogin = this.login + this.baseLogin;
        return this;
    };

    withBaseLogin(baseLogin) {
        this.baseLogin = baseLogin;
        this.fullLogin = this.login + this.baseLogin;
        return this;
    }

    withLoading(loading) {
        this.loading = loading;
        return this;
    };

    withLanguage(language) {
        this.language = language;
        return this;
    };

    withDesign(design) {
        this.design = design;
        return this;
    };

    withWinners(winners) {
        this.winners = winners;
        return this;
    }

    withWinnersURL(winnersURL) {
        this.winnersURL = this.baseURL + winnersURL;
        return this;
    };

    withRules(rules) {
        this.rules = rules;
        return this;
    };

    withGetStarted(getStarted) {
        this.getStarted = getStarted;
        return this;
    };

    withUnsubscribe(unsubscribe) {
        this.unsubscribe = unsubscribe;
        return this;
    }

    withBackground(background) {
        this.background = background;
        return this;
    }

    withFieldWithBanners(fieldWithBanners) {
        this.fieldWithBanners = fieldWithBanners;
        return this;
    }

    withWhiteLabel(whiteLabel) {
        this.whiteLabel = whiteLabel;
        const logo = localStorage.getItem('logo');
        const spDesign = {
            ...this.design,
            // primaryColor: localStorage.getItem('primaryColor'),
            // secondaryColor: localStorage.getItem('secondaryColor'),
            // textColorDisabled: '#878787'
        }
        
        return this
            .withLoading(logo)
            .withLogo(null)
            .withFavicon(logo)
            .withTitle(localStorage.getItem('name'))
            .withLanguage(localStorage.getItem('lang')? localStorage.getItem('lang') : 'en')
            .withDesign(spDesign);
    }

    withSubscription(subscription) {
        this.subscription = subscription;
        return this;
    }

    withRewards(rewards) {
        this.rewards = rewards;
        return this;
    }

    build() {
        return new Operator(
            this.title,
            this.language,
            this.footer,
            this.favicon,
            this.tracking,
            this.design,
            this.login,
            this.baseLogin,
            this.fullLogin,
            this.logo,
            this.loading,
            this.winners,
            this.winnersURL,
            this.rules,
            this.getStarted,
            this.unsubscribe,
            this.background,
            this.fieldWithBanners,
            this.whiteLabel,
            this.subscription,
            this.rewards,
        )
    }
}
