import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image, TouchableOpacity, Linking } from "react-native";

import Dialog from '@material-ui/core/Dialog';

import buyShirt from "../../assets/images/buy_shirt.svg";
import removePlayer from "../../assets/images/remove_player.svg";
import transferPlayer from "../../assets/images/transfer_player.svg";

import { createStyles } from 'react-native-media-queries';
import { formatPlayersName } from '../../Common/utils/utils';

import * as translations from "../../Common/utils/translations";
import { getDomainOperator } from '../../Common/utils/domainMapper';

const operator = getDomainOperator();

const design = operator.design;

const base = {
    title: {
        backgroundColor: design.primaryColor,
        position: 'absolute',
        width: '100%',
        paddingVertical: 10,
        alignItems: "center",
        justifyContent: "center",
        zIndex: 9999
    },
    titleText: {
        color: "white",
        fontSize: 20,
        fontWeight: 550
    },
    exitButton: {
        backgroundColor: "#FF085D",
        width: 22,
        height: 22,
        position: "absolute",
        paddingBottom: 5,
        top: 1,
        right: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",
        zIndex: 9999,
    },
    exitButtonText: {
        color: "white",
        fontWeight: 'bold',
        fontSize: 15
    },
    buttonStyle: {
        width: "90%",
        paddingHorizontal: 10,
        paddingVertical: 10,
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRadius: 3,
        backgroundColor: design.primaryColor,
        marginVertical: 10
    },
    buttonImageContainer: {
        border: "1px solid rgba(255, 255, 255, .2)",
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center',
        padding: 5,
        marginRight: 10
    },
    buttonText: {
        fontWeight: 550,
        fontSize: 14,
        color: "white"
    },
    buttonImage: {
        width: "13px",
        height: "11px"
    }
};


const GOALKEPER = 1;
const DEFENDER = 2;
const MIDFIELDER = 3;
const ATTACKER = 4;

const getPositionLabel = (positionId) => {
    const labelStyle = {
        fontWeight: "bold",
        fontSize: 12,
        paddingVertical: 4,
        paddingHorizontal: 5,
        marginRight: 5
    }
    var text = "";
    switch (positionId) {
        case GOALKEPER: {
            labelStyle.backgroundColor = "#EBFF00";
            text = translations.tKey("str_position_gk");
            break;
        }
        case DEFENDER: {
            labelStyle.backgroundColor = "#01FF87";
            text = translations.tKey("str_position_def");
            break;
        }
        case MIDFIELDER: {
            labelStyle.backgroundColor = "#00F0FF";
            text = translations.tKey("str_position_mid");
            break;
        }
        case ATTACKER: {
            labelStyle.backgroundColor = "#FF085D";
            labelStyle.color = "white";
            text = translations.tKey("str_position_fwd");
            break;
        }
        default:  {
            break;
        }
    }
    return <Text style={labelStyle}>{text}</Text>
}

const getButton = (icon, text, onClick, iconText, iconStyle, style, disabled) => {
    return (<TouchableOpacity onPress={onClick}
        disabled={disabled}
        style={[styles.buttonStyle, style]}>
        <View style={styles.buttonImageContainer}>
            {icon ? <Image style={[styles.buttonImage, iconStyle]} source={icon} />
                : <Text style={{ color: "white", fontSize: 12, fontWeight: 'bold' }}>{iconText}</Text>}
        </View>
        <Text style={styles.buttonText}>{text}</Text>
    </TouchableOpacity>)
}

export const PlayerOptionsDialog = (props) => {
    const { player, mode, isSeasonal } = props;
    if (!player) return null;

    return (

        <Dialog
            onClose={() => props.onClose()}
            aria-labelledby="dialog-title"
            open={props.open}

            PaperProps={{
                style: {
                    width: "90%",
                    maxWidth: '400px'
                },
            }}

            style={mode == 'EnterSubTournament' ? { maxHeight: 600 } : {maxHeight: 2000}}>
            <View style={styles.title}>
                <TouchableOpacity style={styles.exitButton}
                    onPress={() => props.onClose()}>
                    <Text style={styles.exitButtonText}>x</Text></TouchableOpacity>
                <View style={{ width: '90%', display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: "row" }}>
                    <Image style={{ width: 45, height: 50, marginRight: 20 }} source={player.iconUrl} />
                    <View style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', width: "80%" }}>
                        <View>
                            <Text style={{ fontWeight: 550, fontSize: 16, color: "white" }}>
                                {formatPlayersName(player, 'firstFormatted')}
                            </Text>
                            {isSeasonal ? 
                            <Text style={{ fontSize: 16, fontWeight: 550, color: "white" }}>{`${player.price.toFixed(1)}M`}</Text>
                            : 
                            <Text style={{ fontSize: 16, fontWeight: 550, color: "white" }}>{`${player.gw_price.toFixed(1)}M`}</Text>
                            }
                        </View>

                        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            {getPositionLabel(player.groupId)}
                            <Text style={!player.teams[0].player_in_team ? { fontSize: 12, color: "white", opacity: 0.3 } : { fontSize: 12, color: "white" }}>{player.teams[0].name}</Text>
                            <Text style={!player.teams[1].player_in_team ? { fontSize: 12, color: "white", opacity: 0.3 } : { fontSize: 12, color: "white" }}>{` - ${player.teams[1].name}`}</Text>
                        </View>
                    </View>

                </View>
                <Text style={styles.titleText}>{props.title ? props.title : ""}</Text>

            </View>

            <View style={{ marginTop: "90px", display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                {!props.hideTshirt && getButton(buyShirt, translations.tKey("str_player_options_buy_shirt"), () => {
                    player.merchandise_link && Linking.openURL(player.merchandise_link);
                }, null, null, { opacity: player.merchandise_link ? 1 : 0.5 }, !player.merchandise_link)}
                {!props.hideTransfer && getButton(transferPlayer, translations.tKey("str_player_options_transfer_player"), () => {                     
                    props.onTransferPlayer(player);
                })}
                {!props.hideSetAsCaptain && getButton(null, translations.tKey("str_player_options_set_as_captain"), (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (props.capitanActive) {
                        props.setAsCapitan(player);
                        props.onClose();
                    }
                }, "C", null, { opacity: props.capitanActive ? 1 : 0.5 }, !props.capitanActive)}
                {!props.hideSetAsVise && getButton(null, translations.tKey("str_player_options_set_as_vice"), (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (props.viceActive) {
                        props.setAsVice(player);
                        props.onClose();
                    }
                }, "VC", null, { opacity: props.viceActive ? 1 : 0.5 }, !props.capitanActive)}
                {!props.hideDelete && getButton(removePlayer, translations.tKey("str_player_options_remove"), (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    props.removePlayer(player);
                    props.onClose();
                }, null, { width: 9, height: 11.5 })}
                {!props.hideInfo && getButton(null, translations.tKey("str_player_options_player_info"), (e) => {
                     e.stopPropagation();
                     e.preventDefault();
                    props.playerInfo(player)
                }, "i")}
                {props.showChange && getButton(transferPlayer, translations.tKey("str_highlight_substitution"), (e) => {
                     e.stopPropagation();
                     e.preventDefault();
                    props.changePlayer(player, null);
                    props.onClose();
                })}
                {props.showChangePosition && getButton(transferPlayer, translations.tKey("str_highlight_change_position"), (e) => {
                     e.stopPropagation();
                     e.preventDefault();
                    props.changePlayerPosition(player, 'swap');
                    props.onClose();
                })}
            </View>
        </Dialog>
    )
}

export default PlayerOptionsDialog;

PlayerOptionsDialog.propTypes = {
    player: PropTypes.shape({
       firstName: PropTypes.string,
        lastName: PropTypes.string
    })
}

const styles = createStyles(base);