import React from 'react';
import { View, Text } from 'react-native';
import { createStyles } from 'react-native-media-queries';
import * as translations from '../../Common/utils/translations';
import moment from 'moment';

class SubtournamentOverview extends React.Component {

  getRowComponent = (name, value) => {
    return <View style={styles.nameValueRow}>
      <View style={styles.nameRow}>
        <Text style={styles.textNormal}>{name}</Text>
      </View>
      <View style={styles.valueRow}>
        <Text style={styles.textNormal}>{value}</Text>
      </View>
    </View>;
  };

  render() {
    const {overView} = this.props;
    if (!overView) return null;
    const teamInfo = overView[0];
    const tournamentInfo = overView[1];
    let maxlimit = 40;
    let seasonal_start = new Date(2020, 9, 2, 17, 45);
    let seasonal_end = new Date(2021, 4, 31);

    return (
      <View style={styles.container}>
        {teamInfo.team_name &&
        <View style={styles.container}>
          <View style={styles.grayHeader}>
            <Text style={styles.grayHeaderText}>{translations.tKey('str_sub_details_team_info')}</Text>
          </View>
          {this.getRowComponent(translations.tKey('str_sub_details_team_owner'), teamInfo.team_owner)}
          {this.getRowComponent(translations.tKey('str_sub_details_team_name'), teamInfo.team_name)}
          {this.getRowComponent(translations.tKey('str_sub_details_team_value'), `${teamInfo.team_value}M`)}
          {this.getRowComponent(translations.tKey('str_sub_details_finished_players'), teamInfo.finished_players)}
          {this.getRowComponent(translations.tKey('str_sub_details_active_players'), teamInfo.active_players)}
          {this.getRowComponent(translations.tKey('str_sub_details_remaining_players'), teamInfo.remaining_players)}
          {this.getRowComponent(translations.tKey('str_sub_details_current_points'), teamInfo.current_points)}
          {this.getRowComponent(translations.tKey('str_sub_details_current_position'), teamInfo.current_position)}
          {this.getRowComponent(translations.tKey('str_sub_details_created_at'), teamInfo.created_at)}
        </View>}
        <View style={[styles.grayHeader, {marginTop: 20}]}>
          <Text style={styles.grayHeaderText}>{translations.tKey('str_sub_details_tournament_info')}</Text>
        </View>
        {this.getRowComponent(translations.tKey('str_sub_details_name'), <Text>{ ((tournamentInfo.name).length > maxlimit) ? 
          (((tournamentInfo.name).substring(0,maxlimit-3)) + '...') : 
          tournamentInfo.name }</Text>)}
        {this.getRowComponent(translations.tKey('str_sub_details_id'), tournamentInfo.tournament_id)}
        {this.getRowComponent(translations.tKey('str_sub_details_creator'), tournamentInfo.creator)}
        {this.getRowComponent(translations.tKey('str_sub_details_gameweek'), tournamentInfo.game_week)}
        {tournamentInfo.is_seasonal_game ? this.getRowComponent(translations.tKey('str_sub_details_starts_at'), moment(seasonal_start).format('D MMM YYYY HH:mm')) : this.getRowComponent(translations.tKey('str_sub_details_starts_at'), tournamentInfo.starts_at)}
        {tournamentInfo.is_seasonal_game ? this.getRowComponent(translations.tKey('str_sub_details_ends_at'), moment(seasonal_end).format('D MMM YYYY HH:mm')) : this.getRowComponent(translations.tKey('str_sub_details_ends_at'), tournamentInfo.ends_at)}
        {/* {this.getRowComponent(translations.tKey('str_sub_details_starts_at'), tournamentInfo.starts_at)}
        {this.getRowComponent(translations.tKey('str_sub_details_ends_at'), tournamentInfo.ends_at)} */}
        {this.getRowComponent(translations.tKey('str_overview_buy_in'), `${tournamentInfo.buy_in}${translations.tKey('str_currency')}`)}

      </View>
    );
  }
};


export default SubtournamentOverview;

const base = {

  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  nameValueRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  textNormal: {
    fontSize: 14
  },
  nameRow: {
    paddingVertical: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  valuesRow: {
    paddingVertical: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  grayHeader: {
    backgroundColor: '#EFEFEF',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    paddingVertical: '5px'
  },
  grayHeaderText: {
    fontWeight: 'bold',
    fontSize: 18
  }
};

const styles = createStyles(base);

