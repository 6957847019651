import React, { Component } from 'react';
import { View, Text, Image, TouchableOpacity, ActivityIndicator } from 'react-native';
import * as translations from '../../Common/utils/translations';
import { createStyles } from 'react-native-media-queries';
import { connect } from 'react-redux';
import { getMatchHighlights } from '../actions/highlights';
import clock from '../../assets/images/clock_black.svg';
import { BASE_URL } from '../../Common/config/config';
import matchStarted from '../../assets/images/match_started.svg';
import matchEnded from '../../assets/images/match_ended.svg';
import substitutionIn from '../../assets/images/substitution_in.svg';
import substitutionOut from '../../assets/images/substitution_out.svg';
import yellowCard from '../../assets/images/yellow_card.svg';
import secondYellowCard from '../../assets/images/secondYellowCard.svg';
import redCard from '../../assets/images/red_card.png';
import { getDomainOperator } from '../../Common/utils/domainMapper';

const GOAL = 1;
const OWN_GOAL = 2;
const PENALTY_SCORE = 3;
const PENALTY_SAVE = 10;
const PENALTY_MISS = 4;
const SAVE = 8;
const SUBSTITUTION = 7;
const YELLOW_CARD = 5;
const DOUBLE_YELLOW_CARD = 9;
const RED_CARD = 6;


class TournamentHighlights extends Component {

  state = {
    isExpanded: false
  };

  getStatString = id => {
    switch (id) {
      case GOAL: {
        return translations.tKey('str_highlight_goal');
      }
      case OWN_GOAL: {
        return translations.tKey('str_highlight_own_goal');
      }
      case PENALTY_SCORE: {
        return translations.tKey('str_highlight_penalty_score');
      }
      case PENALTY_SAVE: {
        return translations.tKey('str_highlight_penalty_save');
      }
      case PENALTY_MISS: {
        return translations.tKey('str_highlight_penalty_miss');
      }
      case SAVE: {
        return translations.tKey('str_highlight_save');
      }
      case SUBSTITUTION: {
        return translations.tKey('str_highlight_substitution');
      }
      case YELLOW_CARD: {
        return translations.tKey('str_highlight_yellow_card');
      }
      case DOUBLE_YELLOW_CARD: {
        return translations.tKey('str_highlight_double_yellow_card');
      }
      case RED_CARD: {
        return translations.tKey('str_highlight_red_card');
      }
      default: {
        return;
      }
    }
  };

  getStatIcon = id => {
    switch (id) {
      case GOAL: {
        return <Text style={{color: '#00173E'}}>⚽</Text>;
      }
      case OWN_GOAL: {
        return <Text style={{color: '#00173E'}}>⚽</Text>;
      }
      case PENALTY_SCORE: {
        return <Text style={{color: '#00173E'}}>⚽</Text>;
      }
      case PENALTY_SAVE: {
        return <Text style={{color: '#00173E'}}>🧤</Text>;
      }
      case PENALTY_MISS: {
        return <Text style={{color: '#00173E'}}>❌</Text>;
      }
      case SAVE: {
        return <Text style={{color: '#00173E'}}>🧤</Text>;
      }
      case YELLOW_CARD: {
        return <Image style={{width: 12, height: 18, resizeMode: 'cover'}} source={yellowCard}/>;
      }
      case DOUBLE_YELLOW_CARD: {
        return <Image style={{width: 12, height: 18, resizeMode: 'cover'}} source={secondYellowCard}/>;
      }
      case RED_CARD: {
        return <Image style={{width: 12, height: 18, resizeMode: 'cover'}} source={redCard}/>;
      }
      default: {
        return;
      }
    }
  };

  getHostContainer = (item) => {
    if (!item.isHost) return <View style={{width: '45%'}}></View>;
    return <View style={styles.hostColumn}>
      <View style={styles.hostFirstRow}>
        <Text style={styles.statNameHost}>{this.getStatString(item.statId)}</Text>
        {this.getStatIcon(item.statId)}
      </View>
      <View style={styles.hostSecondRow}>
        <Text
          style={{color: item.points > 0 ? '#02894E' : '#FF085D'}}>{`(${item.points > 0 ? '+' : ''}${item.points.toFixed(1)}) `}</Text>
        <Text style={{color: '#929292'}}>{item.playerName}</Text>
      </View>
    </View>;
  };

  getGuestContainer = (item) => {
    if (item.isHost) return <View style={{width: '45%'}}></View>;
    return <View style={styles.hostColumn}>
      <View style={styles.guestFirstRow}>
        {this.getStatIcon(item.statId)}
        <Text style={styles.statNameGuest}>{this.getStatString(item.statId)}</Text>

      </View>
      <View style={styles.guestSecondRow}>
        <Text
          style={{color: item.points > 0 ? '#02894E' : '#FF085D'}}>{`(${item.points > 0 ? '+' : ''}${item.points.toFixed(1)}) `}</Text>
        <Text style={{color: '#929292'}}>{item.playerName}</Text>
      </View>
    </View>;

  };

  getAssistContainer = (item, index) => {
    var opacityStyle = {};
    if (!this.state.isExpanded) {
      if (index == 1) {
        opacityStyle = {opacity: 0.5};
      }
      if (index == 0) {
        opacityStyle = {opacity: 0.3};
      }
    }
    return <View style={[styles.contentRow, opacityStyle]}>
      {item.isHost ? <View style={styles.hostColumn}>
        <View style={styles.hostFirstRow}>
          <Text style={styles.statNameHost}>{translations.tKey('str_tournament_highlights_assistance')}</Text>
          <Text style={{color: '#00173E'}}>🏃‍♂️</Text>
        </View>
        <View style={styles.hostSecondRow}>
          <Text
            style={{color: item.points > 0 ? '#02894E' : '#FF085D'}}>{`(${item.points > 0 ? '+' : ''}${item.assist_points.toFixed(1)}) `}</Text>
          <Text style={{color: '#929292'}}>{item.assistantName}</Text>
        </View>
      </View> : <View style={{width: '45%'}}/>}

      <View style={styles.minuteText}><Text style={{fontSize: 14, fontWeight: 550}}>{`${item.minute}'`}</Text></View>

      {!item.isHost ? <View style={styles.hostColumn}>
        <View style={styles.guestFirstRow}>
          <Text style={{color: '#00173E'}}>🏃‍♂️</Text>
          <Text style={styles.statNameGuest}>{translations.tKey('str_tournament_highlights_assistance')}</Text>

        </View>
        <View style={styles.guestSecondRow}>
          <Text
            style={{color: item.points > 0 ? '#02894E' : '#FF085D'}}>{`(${item.points > 0 ? '+' : ''}${item.assist_points.toFixed(1)}) `}</Text>
          <Text style={{color: '#929292'}}>{item.assistantName}</Text>
        </View>
      </View> : <View style={{width: '45%'}}/>}


    </View>;
  };

  getSubstitutionContainer = (item, isIn, index) => {
    var opacityStyle = {};
    if (!this.state.isExpanded) {
      if (index == 1) {
        opacityStyle = {opacity: 0.5};
      }
      if (index == 0) {
        opacityStyle = {opacity: 0.3};
      }
    }
    return <View style={[styles.contentRow, opacityStyle]}>
      {item.isHost ? <View style={styles.hostColumn}>
        <View style={styles.hostFirstRow}>
          <Text style={styles.statNameHost}>{this.getStatString(item.statId)}</Text>
          <Image style={{width: 12, height: 18, resizeMode: 'cover'}} source={isIn ? substitutionIn : substitutionOut}/>
        </View>
        <View style={styles.hostSecondRow}>
          <Text style={{color: '#929292'}}>{isIn ? item.assistantName : item.playerName}</Text>
        </View>
      </View> : <View style={{width: '45%'}}/>}

      <View style={styles.minuteText}>{isIn ? <Text style={{fontSize: 14, fontWeight: 550}}>{`${item.minute}'`}</Text> :
        <Text>{''}</Text>} </View>

      {!item.isHost ? <View style={styles.hostColumn}>
        <View style={styles.guestFirstRow}>
          <Image style={{width: 12, height: 18, resizeMode: 'cover'}} source={isIn ? substitutionIn : substitutionOut}/>
          <Text style={styles.statNameGuest}>{this.getStatString(item.statId)}</Text>

        </View>
        <View style={styles.guestSecondRow}>

          <Text style={{color: '#929292'}}>{isIn ? item.assistantName : item.playerName}</Text>
        </View>
      </View> : <View style={{width: '45%'}}/>}


    </View>;
  };

  getContentRow = (item, index) => {
    var opacityStyle = {};
    if (!this.state.isExpanded) {
      if (index == 1) {
        opacityStyle = {opacity: 0.5};
      }
      if (index == 0) {
        opacityStyle = {opacity: 0.3};
      }
    }
    return <View style={[styles.contentRow, opacityStyle]}>

      {this.getHostContainer(item)}
      <View style={styles.minuteText}><Text style={{fontSize: 14, fontWeight: 550}}>{`${item.minute}'`}</Text></View>
      {this.getGuestContainer(item)}

    </View>;
  };

  componentDidMount() {
    this.props.getMatchHighlights(this.props.match_id);
  }

  render() {
    const {highlights} = this.props;
    if (!highlights) {
      return <ActivityIndicator size={'large'} color={design.primaryColor}/>;
    }
    return (
      <View style={styles.container}>
        <View style={styles.startTimeContainer}>
          <View style={styles.startTimeRow}>
            <Image style={styles.clock} source={clock}/>
            <Text style={styles.startTimeText}>{`${highlights.start_date} | ${highlights.start_time}`}</Text>
          </View>
          <Text style={styles.startTimeText}>{highlights.round.name}</Text>
        </View>
        <View style={styles.logoResultRow}>
          <View style={styles.teamLogoNameColumn}>
            <Image style={styles.teamLogo} source={BASE_URL + highlights.score.hostLogo}/>
            <Text style={styles.teamName}>{highlights.score.hostName}</Text>
          </View>
          <View style={styles.resultColumn}>
            <Text style={styles.resultText}>{`${highlights.score.hostScore} - ${highlights.score.guestScore}`}</Text>
            <Text style={styles.currentTimeText}>{highlights.current_time}</Text>
          </View>
          <View style={styles.teamLogoNameColumn}>
            <Image style={styles.teamLogo} source={BASE_URL + highlights.score.guestLogo}/>
            <Text style={styles.teamName}>{highlights.score.guestName}</Text>
          </View>
        </View>
        <View style={[styles.contentColumn, {flexDirection: 'column-reverse'}]}>
          {this.state.isExpanded && <View style={styles.matchStarted}>
            <Image style={{width: 10, height: 13.5, position: 'absolute', left: 20}} source={matchStarted}/>
            <Text style={styles.labelText}>{translations.tKey('str_tournament_highlights_match_started')}</Text>
          </View>}
          {!this.state.isExpanded && <TouchableOpacity onPress={() => this.setState({isExpanded: true})}><Text
            style={{textDecoration: 'underline'}}>{translations.tKey('str_tournament_highlights_show_more')}</Text></TouchableOpacity>}
          {this.state.isExpanded && highlights.liveScore.firstHalfStats.map(item => {

            if (item.statId == SUBSTITUTION) {
              return <View style={styles.contentColumn}>
                {this.getSubstitutionContainer(item, true)}
                {this.getSubstitutionContainer(item, false)}
              </View>;
            } else if (item.statId == GOAL && item.assistantName) {
              return <View style={styles.contentColumn}>
                {this.getContentRow(item)}
                {this.getAssistContainer(item)}
              </View>;
            }
            return this.getContentRow(item);
          })}

          {this.state.isExpanded && <View style={styles.halfTime}>
            <Text style={styles.labelText}>{translations.tKey('str_tournament_highlights_first_halftime')}</Text>
          </View>}
          {this.state.isExpanded && <View style={styles.halfTime}>
            <Text style={styles.labelText}>{translations.tKey('str_tournament_highlights_second_halftime')}</Text>
          </View>}
          {highlights.liveScore.secondHalfStats.map((item, index) => {
            if (!this.state.isExpanded && index > 2) return null;
            if (item.statId == SUBSTITUTION) {
              return <View style={styles.contentColumn}>
                {this.getSubstitutionContainer(item, true, index)}
                {this.getSubstitutionContainer(item, false, index)}
              </View>;
            } else if (item.statId == GOAL && item.assistantName) {
              return <View style={styles.contentColumn}>
                {this.getContentRow(item, index)}
                {this.getAssistContainer(item, index)}
              </View>;
            }
            return this.getContentRow(item, index);
          })}

          {this.state.isExpanded && <View style={styles.matchEnded}>
            <Image style={{width: 10, height: 13.5, position: 'absolute', left: 20}} source={matchEnded}/>
            <Text style={[styles.labelText, {color: 'white'}]}>{translations.tKey('str_tournament_highlights_match_ended')}</Text>
          </View>}
          {this.state.isExpanded && <TouchableOpacity style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginVertical: 20
          }}
            onPress={() => this.props.onPointsPressed()}>
            <Text style={{fontSize: 10, color: design.textColor, opacity: 0.5}}>⚽</Text>
            <Text style={{fontSize: 12, color: design.textColorSecondary, marginLeft: 5}}>{translations.tKey('str_tournament_highlights_scoring')}</Text>
          </TouchableOpacity>}
        </View>
      </View>
    );
  }
}


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getMatchHighlights: id => dispatch(getMatchHighlights(id))
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    highlights: state.highlights.highlights,
    isLoading: state.ui.isLoading
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TournamentHighlights);

const operator = getDomainOperator();

const design = operator.design;

const base = {
  container: {
    width: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  startTimeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  startTimeRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  clock: {
    width: 10,
    height: 10,
    resizeMode: 'cover',
    marginRight: 5
  },
  startTimeText: {
    color: '#929292',
    fontSize: 12
  },
  logoResultRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  teamLogoNameColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'

  },
  teamLogo: {
    width: 75,
    height: 75,
    resizeMode: 'stretch',
    marginHorizontal: 20
  },
  resultColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'

  },
  teamName: {
    fontSize: 14,
    fontWeight: 550
  },
  resultText: {
    fontSize: 20,
    fontWeight: 'bold'
  },
  currentTimeText: {
    fontSize: 14,
    color: '#929292'
  },
  contentRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginVertical: 5
  },
  contentColumn: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  hostColumn: {
    width: '45%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  hostFirstRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: 10
  },
  hostSecondRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: 30
  },
  guestFirstRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: 10
  },
  guestSecondRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: 30
  },
  statNameHost: {
    fontSize: 14,
    fontWeight: 550,
    marginRight: 10
  },
  statNameGuest: {
    fontSize: 14,
    fontWeight: 550,
    marginLeft: 10
  },
  minuteText: {
    width: '10%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  matchStarted: {
    width: '80%',
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginVertical: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: design.secondaryColor
  },
  halfTime: {
    width: '80%',
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginVertical: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#EFEFEF'
  },
  matchEnded: {
    width: '80%',
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginVertical: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FF085D'
  },
  labelText: {
    fontSize: 14,
    fontWeight: 550
  }
};

const styles = createStyles(base);